import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CandidateHeader from "../component/candidateComp/CandidateHeader";
import CandidatePagination from "../component/candidateComp/CandidatePagination";
import CandidateTable from "../component/candidateComp/CandidateTable";
import { setAdminCandidatesList, setAdminCandidatesPagination, setAdminCandidatesSearchData } from "../redux/candidateSlice";
import { adminCandidateListApi } from "../service/candidateService";
import "../style/candidate.css";
import { setAdminRequirmentsPagination } from "../redux/adminRequirmentsSlice";

const Candidate = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [candiDateIdSend, setCandiDateIdSend]: any = useState([]);
  const [adminCandidateData, setAdminCandidateData] = useState({
    id: [],
    fullName: [],
    referredBy: [],
    isPaid: [],
    title: [],
    email: [],
    phone: [],
    linkedInProfile: [],
    city: [],
    state: [],
    searchAny: "",
    page: null,
    perPage: null,
  });

  const [candidateHiringStatus, setcandidateHiringStatus] = useState({
    candidateIds: [],
    hiringStatus: "",
    note: ""
  })

  const candidatesData = useSelector((state: any) => state?.candidate);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAdminRequirmentsPagination(1))
    if (adminCandidateData?.searchAny === "") {
      setIsLoading(true)
      adminCandidateListApi({ ...adminCandidateData, page: candidatesData?.page, perPage: candidatesData?.perPage })
        .then((res: any) => {
          setIsLoading(false)
          dispatch(setAdminCandidatesSearchData(res));
        })
        .catch((err: any) => {
          setIsLoading(false)
        });
    } else {
      const candidateSearchData = setTimeout(() => {
        setIsLoading(true)
        adminCandidateListApi({ ...adminCandidateData, page: candidatesData?.page, perPage: candidatesData?.perPage })
          .then((res: any) => {
            dispatch(setAdminCandidatesList(res));
            setIsLoading(false)

          })
          .catch((err: any) => {
            setIsLoading(false)
          });
      }, 1000);



      return () => clearTimeout(candidateSearchData);
    }
  }, [adminCandidateData, candidatesData?.page, candidatesData?.candidatesRefresh]);


  return (
    <div className="candidate-container">
      <CandidateHeader
        adminCandidateData={adminCandidateData}
        setAdminCandidateData={setAdminCandidateData}
        setcandidateHiringStatus={setcandidateHiringStatus}
        candidateHiringStatus={candidateHiringStatus}
        setCandiDateIdSend={setCandiDateIdSend}
        candiDateIdSend={candiDateIdSend}
        setIsLoading={setIsLoading}
      />
      <CandidateTable candiDateIdSend={candiDateIdSend} setCandiDateIdSend={setCandiDateIdSend} candidateHiringStatus={candidateHiringStatus} setcandidateHiringStatus={setcandidateHiringStatus} isLoading={isLoading} />
      <CandidatePagination />
    </div>
  );
};

export default Candidate;
