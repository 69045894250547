import React,{useState} from "react";
import "../../style/candidate.css";
import "../../style/userTable.css";
import { useTable } from "react-table";
import { useDispatch } from "react-redux";
import { message, Popconfirm, Tooltip } from "antd";
import { DeleteUserData } from "../../service/userService";
import { userRefresh } from "../../redux/userSlice";
import EditUser from "./EditUser";
import { Spin } from "antd";
import { BiErrorAlt } from "react-icons/bi";
import { FRONTEND_URL } from "../../service/http";
import { TbCopy } from "react-icons/tb";
const UserTable = ({ data, isAdminUserSpinner }: any) => {

  const dispatch = useDispatch();

  const columns: any = React.useMemo(
    () => [
      {
        Header: "User ID",
        accessor: "id", // accessor is the "key" in the data
      },
      {
        Header: "Full Name",
        accessor: "fullName",
      },
      {
        Header: "Email",
        accessor: "email", // accessor is the "key" in the data
      },
      {
        Header: "Referral Code",
        accessor: "inviteCode",
      },
      {
        Header: "",
        accessor: "action", // accessor is the "key" in the data
      },
    ],
    []
  );

  const confirm = () => {
    message.success("User Deleted");
  };

  const HandleDelete = (id: any) => {
    DeleteUserData(id).then((res: any) => {
      if (res.success) {
        confirm();
        dispatch(userRefresh());
      }
    });
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });


  return (
    <div className="requirments-table-container">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        {/* Apply the table body props */}
        {isAdminUserSpinner ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spin tip="Loading" size="large" />
          </div>
        ) : data?.length > 0 ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    if (cell.column.id === "inviteCode") {
                      if(cell?.value !== ""){
                          return (<td>
                           <span className="admin-user-referral"><p>{cell.value}</p>
                           <Tooltip  color={"rgb(38, 95, 157"} title={`${FRONTEND_URL}login?refferalCode=${cell?.value}`}>
                       
                           <TbCopy style={{color:"rgb(38, 95, 157)",fontSize:"22px", cursor:"pointer"}} onClick={async() => {
                            const isCopied: any = await navigator?.clipboard?.writeText(`${FRONTEND_URL}login?refferalCode=${cell?.value}`);
                            message.success("Copied",1);
                            return isCopied
                            }} />
                           </Tooltip>
                           </span>
                      </td>)
                      }else {
                        return (
                          <td>--</td>
                        )
                      }
                    }
                    if (cell.column.id === "action") {
                      return (
                        <td {...cell.getCellProps()}>
                          <div className="action-div">
                            <EditUser editData={cell?.row?.original} />
                            <Popconfirm
                              title="Delete User"
                              description="Are you sure to delete this user?"
                              onConfirm={() =>
                                HandleDelete(cell.row.original.id)
                              }
                              onCancel={() => { }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <span style={{ color: "#B70000" }}>Delete</span>
                            </Popconfirm>
                          </div>
                        </td>
                      );
                    } else {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <div className="candidate-nodata-box">
              <p>
                <BiErrorAlt className="nodata-icon" />
              </p>
              <p className="nodata-text">No Data Found</p>
            </div>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default UserTable;
