import {  createSlice } from '@reduxjs/toolkit';


export interface AdminReferralsState {
    referralsList:any,
    referralsSearchData:any,
    referralsRefresh:boolean,
    page:number,
    perPage:number,
    referrerDetails:any


}

const initialState: AdminReferralsState = {
    referralsList:[],
    referralsSearchData:{},
    referralsRefresh:false,
    page:1,
    perPage:10,
    referrerDetails:{}

};


export const adminReferralsSlice = createSlice({
  name: 'adminReferrals',
  initialState,
  reducers: {
    setAdminReferralsList: (state:any, action:any) => {
        state.referralsList=action.payload.data
      },
      setAdminReferralsDetails:(state:any, action:any)=>{
        state.referrerDetails = action.payload
        
      },

      setAdminReferralsRefresh(state:any){
        state.referralsRefresh = ! state.referralsRefresh
      },
    }
});


export const {setAdminReferralsList,setAdminReferralsRefresh,setAdminReferralsDetails} = adminReferralsSlice.actions;


export default adminReferralsSlice.reducer;