import React from "react";
import "../../style/referrerExportBtn.css";
import { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { message } from 'antd';
import { adminExportApi } from "../../service/adminExport";
import exportFromJSON from "export-from-json";
import * as XLSX from "xlsx";
import { MdOutlineCancel } from "react-icons/md";


const ReferrerExportBtn = () => {
  const [referrerExportModal, setReferrerExportModal] = useState(false);
  const [exportAllChecked, setExportAllChecked] = useState(false);
  const [customLimitValue, setCustomLimitValue] = useState("");
  const [allExportData, setAllExportData] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);

  const showReferrerExportModal = () => {
    setReferrerExportModal(true);
  };

  const exportDataFun = () => {
    setSubmitLoader(true)
    if (exportAllChecked || customLimitValue?.length > 0) {
      adminExportApi(customLimitValue)
        .then((ele: any) => {
          if (ele?.success) {
            setAllExportData(ele?.data);
          }
          setSubmitLoader(false)
        })
        .catch((err: any) => {
            setSubmitLoader(false)
        });
    } else {
      message.info("Please Select Any Export Option");
      setSubmitLoader(false)
    }
  };

  useEffect(() => {
    if (allExportData.length > 0) {
      exportToExcel();
    }
  }, [allExportData]);

  const exportToExcel = () => {
    const date = new Date();
    const dateName = `Referrer_Data_${date.getDate()}-${date.getMonth()}-${date.getFullYear()}/time_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
    const data: any = allExportData;
    const fileName = dateName;

    if (true) {
        const exportType:any = "csv";
        exportFromJSON({ data, fileName, exportType });
      } else {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, `${dateName}.xlsx`);
      }
  };

  return (
    <>
      <div className="referrer-left-header-Export">
        <button onClick={showReferrerExportModal}>Export</button>
      </div>

      <Modal
        className="referrer-export-show-modal"
        open={referrerExportModal}
        width={400}
        onOk={() => setReferrerExportModal(false)}
        onCancel={() => setReferrerExportModal(false)}
        footer={null}
      >
        <div
          className="referrer-export-all-section"
          style={{ height: "200px" }}
        >
          <div className="export-header">
        <p className="exportPopCancel"><MdOutlineCancel onClick={()=>setReferrerExportModal(false)}/></p>
            <span>Export</span>
          </div>
          <div className="referrer-ExportAll">
            <input
              type="checkbox"
              name="Export"
              id="Export"
              disabled={customLimitValue?.length > 0 ? true : false}
            // disabled={true}
              onChange={(e: any) => {
                setExportAllChecked(e.target.checked);
                e.target.checked && setCustomLimitValue("");
              }}
            />
            <label htmlFor="Export" className={customLimitValue?.length > 0 ? "exportAllText" : ""}>Export All</label>
          </div>
          <span className="or" >Or
          <span className="lr"></span>
          </span>
          <div className="referrer-custom">
            <input
              required
              onChange={(e: any) => setCustomLimitValue(e.target.value.replace(/\D/g, ""))}
              value={exportAllChecked ? "" : customLimitValue}
              type="text"
              disabled={exportAllChecked}
              title={`${
                exportAllChecked
                  ? "Custom limit will not available if Export All is selected"
                  : ""
              }`}
              className={exportAllChecked ? "customInput" : ""}
            />
            <label placeholder="custom limit" className="customLable"></label>
          </div>
          <div className="referrer-pop-submit">
            <Button loading={submitLoader} onClick={exportDataFun}>Submit</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReferrerExportBtn;
