import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import UserHeader from '../component/userComp/UserHeader'
import UserPagination from '../component/userComp/UserPagination'
import UserTable from '../component/userComp/UserTable'

const UserAdmin = () => {
  const [isAdminUserSpinner ,setIsAdminUserSpinner]:any = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const userTableData: any = useSelector((state: any) => state.user)
  const refresh:any =  userTableData?.userRefresh
  const data: any = userTableData?.userTable?.data || [];
  const paginationData:any = userTableData?.userTable?.pagination



  return (
    <div className='requirments-container'>
        <UserHeader setPage={setPage} setPerPage={setPerPage} page={page} refresh={refresh} perPage={perPage} setIsAdminUserSpinner={setIsAdminUserSpinner}/>
        <UserTable data={data} isAdminUserSpinner={isAdminUserSpinner} />
        <UserPagination setIsAdminUserSpinner={setIsAdminUserSpinner} data={data} paginationData={paginationData} page={page} setPage={setPage} />
    </div>
  )
}

export default UserAdmin