import { useSelector } from "react-redux";
import { BrowserRouter, useRoutes } from "react-router-dom";
import "./App.css";
import Header from "./component/headerComp/Header";
import Profile from "./page/Profile";
import routes from "./routes/routes";

function App() {
  const userData: any = useSelector((state: any) => state.login.user);
  const isUser: any = userData?.id ? true : false;
  const isAdmin: any =
    userData?.role === String(0) || userData?.role === String(2) ? true : false;
  const routing: any = useRoutes(routes(isUser, isAdmin));
  return <>{routing}</>;
}

const AppWrapper = () => {
  const userData: any = useSelector((state: any) => state.login.user);
  const isUser: any = userData?.id ? true : false;


  return (
    <BrowserRouter>
      {isUser ? <Header /> : null}
      <App />
    </BrowserRouter>
  );
};

export default AppWrapper;
