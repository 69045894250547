import React, { useEffect, useState } from "react";
import Avatar, { ConfigProvider } from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import demoImg from "../../assets/demoImg.jpg";
import "../../style/ProfileSection.css";
import { MdOutlineCancel } from "react-icons/md";
import { getBankingDetailsAPI } from "../../service/profileService";
import { totalEarnings } from "../../service/userDashboardService";
import { totalReferralsData } from "../../service/userDashboardService";
import { log } from "console";
import { Link } from "react-router-dom";
import { BsLinkedin } from "react-icons/bs";

const ProfileSection = ({
  userSince,
  showUserEarningHeader,
  showUserReferralsCountHeader,
}: any) => {
  const user: any = useSelector((state: any) => state.login.user);
  const avatar = user.avatar;
  const [showBankDetails, setShowBankDetails]: any = useState({});

  useEffect(() => {
    getBankingDetailsAPI().then((res: any) => {
      setShowBankDetails(res?.data);
    });
  }, []);

  return (
    <div className="main-profilesection-div">
      <div>
        <p className="logo-user-name  animate__animated animate__slideInRight">
          {" "}
          <Avatar
            round
            size="60px"
            src={avatar || demoImg}
            className="avtar-in-profile-details"
          ></Avatar>{" "}
          <div
            className=" animate__animated animate__slideInRight"
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontSize: 18,
                fontFamily: "Helvetica",
                fontWeight: "700",
              }}
            >
              {user?.fullName}
            </span>
            <span
              style={{
                fontSize: 15,
                fontFamily: "Helvetica",
                fontWeight: "500",
              }}
            >
              {" "}
              {user.jobTitle}, {user.country}
            </span>
            <span
              style={{
                fontSize: 15,
                color: "#B7B7B7",
                fontFamily: "Helvetica",
                fontWeight: "500",
              }}
            >
              {" "}
              Member Since {userSince}
            </span>
          </div>
        </p>
      </div>
      <div className="total-earn-and-referrals-div  animate__animated animate__slideInRight">
        <p className="total-earn-and-referrals-div-p-tag">
          <span>
            {" "}
            <b className="total-referrals-num">
              {" "}
              {showUserReferralsCountHeader?.totalReferralsCount}{" "}
            </b>
            <br />
            Total Referrals
          </span>
          <span>
            <b className="total-earnings-num">
              {" "}
              ${showUserEarningHeader?.totalReferralAmount}{" "}
            </b>
            <br />
            Earnings
          </span>
        </p>
      </div>
      <hr />

      <div className="user-details  animate__animated animate__slideInRight">
        <p>
          <b> Email </b>: {user?.email}
        </p>
        <p>
          <b>phone</b>: {user?.phone}
        </p>
        <p style={{ wordBreak: "break-word" }}>
          <b>linkedInProfile</b>:{" "}
          <Link to={user?.linkedInProfile} target="_blank">
            <BsLinkedin fontSize={"25px"} />
          </Link>
        </p>
        <p>
          <b>Email</b>: {user?.fullName}
        </p>
      </div>
      <div className="user-bank-details  animate__animated animate__slideInRight">
        <div>
          <span>
            <b> Bank Details</b>
          </span>
        </div>
        <div>
          <p>
            <b> Ac Name </b> - {showBankDetails?.accountName}
          </p>
          <p>
            <b> FullAddress </b>- {showBankDetails?.fullAddress}
          </p>
          <p>
            <b> RoutingNumber </b>- {showBankDetails?.routingNumber}
          </p>
          <p>
            <b> BankName </b> - {showBankDetails?.bankName}
          </p>
          <p>
            <b> BankFullAddress </b>- {showBankDetails?.bankFullAddress}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;
