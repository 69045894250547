import React, { useState } from 'react'
import '../../style/changeStatusHired.css'
import { Button, Modal } from 'antd';
import { adminCandidateStatusUpdate } from '../../service/candidateService';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { setAdminCandidatesRefresh } from '../../redux/candidateSlice';


const ChangeStatusHired = ({
    setIsLoading,
    setcandidateHiringStatus,
    candidateHiringStatus,
    changeHiredModalOpen,
    setChangeHiredModalOpen }: any) => {

    const [submitLoader, setSubmitLoader] = useState(false);
    const noteRef: any = React.useRef(null);

    const dispatch = useDispatch()
    const handleStatusUpdate = (e: any) => {
      setSubmitLoader(true)
        noteRef.current.reportValidity();
        e.preventDefault();
        if (noteRef.current.reportValidity()) {
            setIsLoading(true)
            adminCandidateStatusUpdate(candidateHiringStatus).then((response: any) => {
                if (response.success) {
                    setChangeHiredModalOpen(false);
                    setIsLoading(false)
                    setSubmitLoader(false)


                    setcandidateHiringStatus({
                        candidateIds: [],
                        hiringStatus: "",
                        note: ""
                    })
                    dispatch(setAdminCandidatesRefresh())
                    setSubmitLoader(false)
                    setIsLoading(false)

                }else {
                    setIsLoading(false)
                    setSubmitLoader(false)


                }
            }).catch((err: any) => {
                setSubmitLoader(false)
                setIsLoading(false)


            })
        }
    }

    const handlePopCancel = () => {
        setChangeHiredModalOpen(false)
        dispatch(setAdminCandidatesRefresh())
        setcandidateHiringStatus({
            candidateIds: [],
            hiringStatus: "",
            note: ""
        })
    }
    return (
        <div className='candidate-change-status-label' >
            <Modal
                className='hired-rejected-closed-modal'
                width={850}
                open={changeHiredModalOpen}
                onOk={() => setChangeHiredModalOpen(false)}
                onCancel={handlePopCancel}
                footer={null}
            >
                <div className='hired-status-main-section' style={{ height: "max-content" }} >
                    <div className='change-status-header-section' >
                        <p>Add Status Note</p>
                    </div>
                    <div className='add-status-note-details' >
                        <div className='type-your-test-here' >
                            <textarea name=""
                                ref={noteRef}
                                onChange={(e: any) => setcandidateHiringStatus({ ...candidateHiringStatus, note: e.target.value })}
                                value={candidateHiringStatus?.note}
                                placeholder='Type your test here'
                                required
                            >
                            </textarea>
                        </div>
                    </div>
                    <div className='status-note-btn' >
                        <Button loading={submitLoader} onClick={handleStatusUpdate}> Submit </Button>
                    </div>
                </div>
            </Modal>

        </div>
    )
}


export default ChangeStatusHired
