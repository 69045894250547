import { Navigate } from "react-router";
import MobileSign from "../component/loginComp/MobileSign";
import AddRefMobile from "../component/userDashboard/AddRefMobile";
import ReferOnReq from "../component/userDashboard/ReferOnReq";
import ReferOnRequirement from "../component/userDashboard/ReferOnRequirement";
import Candidate from "../page/Candidate";
import Login from "../page/Login";
import Profile from "../page/Profile";
import Referrer from "../page/Referrer";
import Requirments from "../page/Requirments";
import TC from "../page/T&C";
import UserAdmin from "../page/UserAdmin";
import UserDashboard from "../page/UserDashboard";
import ProfileSection from "../component/headerComp/ProfileSection";
import MobileForgot from "../component/loginComp/MobileForgot";

const routes: any = (isUser: any, isAdmin: any) => [
  {
    path: "/",
    element: isUser && isAdmin ? <Candidate /> : isUser ? <UserDashboard /> : <Navigate to={"/login"} />,
    children: [
      { path: '/candidate', element: <Candidate /> }
    ]
  },
  {
    path: "/tc",
    element: <TC />,
  },
  {
    path: "/user-dashboard",
    element: isUser ? <UserDashboard /> : <Navigate to={"/login"} />,
  },
  {
    path: "/user",
    element: isUser && isAdmin ? <UserAdmin /> : <Navigate to={"/login"} />,
  },
  {
    path: "/requirments",
    element: isUser && isAdmin ? <Requirments /> : <Navigate to={"/login"} />,
  },
  {
    path: "/sign",
    element: !isUser ? <MobileSign /> : <Navigate to={"/"} />,
  },
  {
    path: "/mob-profile",
    element: isUser ? <ProfileSection /> : <Navigate to={"/login"} />,
  },
  {
    path: "/refer-req",
    element: isUser ? <ReferOnRequirement /> : <Navigate to={"/login"} />,
  },
  {
    path: "/forgot-password",
    element: <MobileForgot />,
  },
  {
    path: "/referrals",
    element: isUser && isAdmin ? <Referrer /> : <Navigate to={"/login"} />,
  },
  {
    path: "/profile",
    element: isUser ? <Profile /> : <Navigate to={"/login"} />,
  },
  {
    path: "/add-ref-mob",
    element: isUser ? <AddRefMobile /> : <Navigate to={"/login"} />,
  },
  {
    path: "/ref-req",
    element: isUser ? <ReferOnReq/> : <Navigate to={"/login"} />,
  },
  {
    path: "/",
    element: !isUser ? <Login /> : <Navigate to={"/"} />,
    children: [
      { path: '/login', element: <Login /> }
    ]
  },
];

export default routes;
