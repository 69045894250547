import "../../style/changePasswordBtn.css";
import React, { useRef, useState } from "react";
import { Modal } from "antd";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { changePasswordAdmin } from "../../service/adminChangePassword";
import { message, Button } from "antd";
import { MdOutlineCancel } from "react-icons/md";

const ChangePasswordBtn = ({ logOut }: any) => {
  const reff: any = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [changePassword, setChangePassword] = useState(false);
  const [changePasswordLoader, setChangePasswordLoader] = useState(false);
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  // const [messageApi, contextHolder] = message.useMessage();
  const [changeAdminPassword, setChangeAdminPassword] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const key = "updatable";


  const newPasswordFun = (e: any) => {
    e.preventDefault()
    reff?.current?.reportValidity();
    // if (logoutState === changeAdminPassword.newPassword) {
    //   changePasswordAdmin(changeAdminPassword).then((res: any) => {
    //     if (res.success === true) {
    //       toast.success("Please Login Again...");
    //       setTimeout(() => {
    //         messageApi.open({
    //           key,
    //           type: 'success',
    //           content: 'Loaded!',
    //           duration: 2,
    //         });
    //         logOut();
    //       }, 3000);

    //     } else {
    //       toast.error("Please Login Again...");
    //     }

    //   }).catch((error: any) => {
    //     toast.error("Please Check Password...");
    //   })
    // }


    if (reff?.current?.reportValidity()) {
      setChangePasswordLoader(true)
      if (changeAdminPassword?.oldPassword === changeAdminPassword?.newPassword) {
        setChangePasswordLoader(false)
        message.error("Old And New Password Is Same");
      } else {
        if (confirmPasswordValue === changeAdminPassword.newPassword) {
          changePasswordAdmin(changeAdminPassword).then((res: any) => {

            if (res.success) {
              setChangePasswordLoader(false)
              messageApi.success("Your Password Updated Please Login Again");
              setChangePassword(false);
              setTimeout(() => {
                logOut();
              }, 3000);
            } else {
              setChangePasswordLoader(false)
            }
          }).catch((error: any) => {
            setChangePasswordLoader(false)
          });
        } else {
          setChangePasswordLoader(false)
          messageApi.error("New Password And Confirm Password Not Match");
        }
      }
    };
  }

  const showPasswordModal = () => {
    setChangePassword(true);
  };


  return (
    <>
      {/* <ToastContainer position="top-center"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      {contextHolder}
      <div className="profile-password-click">
        <button onClick={showPasswordModal}> Change Password </button>
      </div>

      <Modal
        className="password-change-modal"
        open={changePassword}
        onOk={() => setChangePassword(false)}
        onCancel={() => setChangePassword(false)}
        footer={null}
        afterClose={() => {
          setChangeAdminPassword({
            oldPassword: "",
            newPassword: "",
          });
          setConfirmPasswordValue("");
        }}
      >
        <MdOutlineCancel className="pop-up-closed" onClick={() => setChangePassword(false)} />

        <div className="change-pass-all-details" style={{ height: "460px" }}>
          <div className="change-pass-header">
            <p> Change Password </p>
          </div>
          <div className="all-pass-input-section" style={{ padding: "20px" }}>
            <div className="pass-current-new-confirm">
              <Box
                className="pass-check-section"
                component="form"
                sx={{ "& > :not(style)": { m: 0, width: "350px" } }}
                noValidate
                autoComplete="off"
                ref={reff}
              >
                <TextField
                  required
                  type={"password"}
                  id="outlined-basic"
                  label="Current Password"
                  variant="outlined"
                  value={changeAdminPassword?.oldPassword}
                  placeholder="*********"
                  onChange={(e: any) =>
                    setChangeAdminPassword({
                      ...changeAdminPassword,
                      oldPassword: e.target.value,
                    })
                  }
                />


                <TextField
                  required
                  type={"password"}
                  id="outlined-basic"

                  label="New Password"
                  variant="outlined"
                  value={changeAdminPassword?.newPassword}
                  style={{ marginTop: "20px" }}
                  placeholder="*********"
                  onChange={(e: any) =>
                    setChangeAdminPassword({
                      ...changeAdminPassword,
                      newPassword: e.target.value,
                    })
                  }
                />

                <TextField
                  required
                  type={"password"}

                  id="outlined-basic"
                  label="Confirm Password"
                  variant="outlined"
                  style={{ marginTop: "20px" }}
                  value={confirmPasswordValue}
                  placeholder="*********"
                  onChange={(e: any) => setConfirmPasswordValue(e.target.value)}
                />

              </Box>
            </div>
          </div>
          <div className="pass-update-btn">
            <Button loading={changePasswordLoader} onClick={(e: any) => newPasswordFun(e)}> Update Password </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChangePasswordBtn;
