import { FiSearch } from "react-icons/fi";
import "../../style/candidate.css";
import AddUser from "./AddUser";
import { getUserData, SearchUserData } from "../../service/userService";
import { getUserTableData } from "../../redux/userSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAdminRequirmentsPagination } from "../../redux/adminRequirmentsSlice";
import { setPerPaginationrefresh } from "../../redux/candidateSlice";

const UserHeader = ({ refresh, page, perPage, setIsAdminUserSpinner, setPage }: any) => {
  const [inputVal, setInputVal] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPerPaginationrefresh({page:1 ,perPage:10}))
    dispatch(setAdminRequirmentsPagination(1))
    const searchData = {
      searchData: inputVal,
      page: page,
      perPage: perPage,
    };

    if (inputVal === "") {
      getUserData(page, perPage).then((res: any) => {
        if (res.success) {

          setIsAdminUserSpinner(false);

        }
        dispatch(getUserTableData(res?.data));
      });
    } else {
      const getUserSearchData = setTimeout(() => {
        SearchUserData(searchData).then((res: any) => {
          if (res.success) {
            setIsAdminUserSpinner(false);

          }
          dispatch(getUserTableData(res?.data));
        });
      }, 1000);

      return () => clearTimeout(getUserSearchData);
    }
  }, [refresh, page, perPage, inputVal]);

  return (
    <div className="requirments-header-container">
      <span>
        <input
          value={inputVal}
          type={"text"}
          placeholder={"Search Anything"}
          onChange={(e: any) => { setInputVal(e.target.value); setPage(1) }}
        />
        <button>
          <FiSearch />
        </button>
      </span>
      <AddUser />
    </div>
  );
};

export default UserHeader;
