import React, { useEffect, useState } from 'react'
import { BsCheck } from 'react-icons/bs'
import ResetPassword from './ResetPassword';

const VerificationIcon = ({otp, email}:any) => {
    const [resetModel, setResetModel]:any = useState(false);

    
    useEffect(() =>{
        setTimeout(() =>{
            setResetModel(true)
        }, 3000)
    }, [])
  return (
    <>  
        {resetModel ?  <ResetPassword otp={otp} email={email}/>  :
        <div className="verification-popup-show animate__animated animate__fadeInDown">
            <BsCheck className="bsCheck" />
            <p>Verification Successfull</p>
          </div>}
    </>
  )
}

export default VerificationIcon