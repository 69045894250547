import "../../style/login.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useRef, useState } from "react";
import { userLogin } from "../../service/loginService";
import { useDispatch } from "react-redux";
import { userLoginAction } from "../../redux/login";
import ForgotModel from "./Forgot";
import { CircularProgress } from "@mui/material";

const SignIn = () => {
  const [forgotModel, setForgotModel]: any = useState(false);
  const [loaderIcon, setLoaderIcon]: any = useState(false);
  const formRef: any = useRef(null);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const handleLogin = async (e: any) => {
    formRef.current.reportValidity();
    e.preventDefault();
    if (formRef.current.reportValidity()) {
      if (loginData?.email !== "" && loginData?.password !== "") {
        setLoaderIcon(true);
      }
      userLogin(loginData)
        .then((res: any) => {
          if (res.success) {
            dispatch(userLoginAction(res.data));
            setInterval(() => {
              setLoaderIcon(false);
            }, 1500);
          } else {
            setInterval(() => {
              setLoaderIcon(false);
            }, 1500);
          }
        })
        .catch((err: any) => {
          setLoaderIcon(false);
        });
    }
  };

  const changeState = (e: any) => {
    setForgotModel(true);
  };

  return (
    <div className="singIn-main-section ">
      {forgotModel ? (
        <ForgotModel setForgotModel={setForgotModel} />
      ) : (
        <Box
          component="form"
          sx={{ "& > :not(style)": { m: 1.5, width: "87%" } }}
          noValidate={false}
          autoComplete="off"
          ref={formRef}
        >
          <TextField
            className="animate__animated animate__fadeInDown"
            value={loginData.email}
            type={"email"}
            id="outlined-basic"
            label="Personal email"
            variant="outlined"
            required
            onChange={(e: any) =>
              setLoginData({ ...loginData, email: e.target.value })
            }
          />
          <TextField
            required
            className="animate__animated animate__fadeInDown"
            value={loginData.password}
            autoComplete="new-password"
            id="outlined-basic"
            label="Password"
            type="password"
            variant="outlined"
            onChange={(e: any) =>
              setLoginData({ ...loginData, password: e.target.value })
            }
          />

          <button
            disabled={loaderIcon}
            className="animate__animated animate__fadeInDown"
            onClick={(e: any) => handleLogin(e)}
            // style={{  background: "#0e9280" }}
            style={{ background: loaderIcon ? "#7cb7af" : "#0e9280" }}
          >
            {" "}
            {loaderIcon ? (
              <CircularProgress
                style={{
                  color: "white",
                  height: "18px",
                  width: "18px",
                  position: "relative",
                  left: "0px",
                  top: "2px",
                }}
              />
            ) : null}{" "}
            Login
          </button>
          <button
            onClick={(e: any) => changeState(e)}
            type="button"
            className="fgt-button animate__animated animate__fadeInDown"
            style={{ color: "#265f9d", outline: "none" }}
          >
            Forgot your password?
          </button>
        </Box>
      )}

      {/* <ForgotModel /> */}
    </div>
  );
};

export default SignIn;
