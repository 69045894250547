import { Box, CircularProgress, TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import "../../style/login.css";
import { IoIosArrowBack } from "react-icons/io";
import Http from "../../service/http";
import group831 from "../../assets/Group 831.png";
import SentOtpCom from "./SentOtpCom";
import { useNavigate } from "react-router-dom";

const MobileForgot = () => {
  const [loaderIcon, setLoaderIcon]: any = useState(false);
  const [email, setEmail]: any = useState("");
  const [otpModel, setOtpModel]: any = useState(false);
  const formRef: any = useRef(null);
  const navigate = useNavigate()  
  const changeModel = async (e: any) => {
    e.preventDefault();
    formRef.current.reportValidity();
    if (email !== "") {
      setLoaderIcon(true);
      if (formRef?.current?.reportValidity) {
        const body: any = {
          email: email,
        };
        const result: any = await Http.post(
          "user/forgotPasswordMobileOtp",
          body,
          false
        );
        if (result.success) {
          setOtpModel(true);
          setInterval(() => {
            setLoaderIcon(false);
          }, 1000);
        } else {
          setLoaderIcon(false);
        }
      }
    }
  };

  return (
    <div className="wiseskulls-signup-signin">
      <div className="mobile-sign-main-container d-block d-sm-block d-md-block d-lg-none">
        <header>
          <img src={group831} alt="" className="Group 831" />
        </header>
        <div className="be-wise-earn-with animate__animated animate__fadeInDown">
          <p>
            Be Wise, Earn with{" "}
            <span style={{ fontWeight: "500" }}>Wise Skulls</span>
          </p>
        </div>
        <div className="send-otp-holder form-holder">
          {otpModel ? (
            <SentOtpCom email={email} />
          ) : (
            <Box
              component="form"
              sx={{ "& > :not(style)": { m: 1.5, width: "100%" } }}
              noValidate
              autoComplete="off"
              ref={formRef}
            >
              <TextField
                required
                className="animate__animated animate__fadeInDown"
                id="outlined-basic"
                label="Email or Phone Number"
                variant="outlined"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
              <button
                className="animate__animated animate__fadeInDown"
                type="submit"
                onClick={(e: any) => changeModel(e)}
                style={{ background: loaderIcon ? "#7cb7af" : "#0e9280" }}
              >
                {" "}
                {loaderIcon ? (
                  <CircularProgress
                    style={{
                      color: "white",
                      height: "18px",
                      width: "18px",
                      position: "relative",
                      left: "0px",
                      top: "2px",
                    }}
                  />
                ) : null}
                Send OTP
              </button>

              <button
                onClick={() => navigate('/sign')}
                style={{
                  width: "15%",
                  height: "15px",
                  fontSize: "15px",
                  marginRight: "14rem",
                  backgroundColor: "transparent",
                  color: "#265f9d",
                  display: "flex",
                  justifyContent: "",
                }}
              >
                <IoIosArrowBack
                  style={{ fontSize: "20px", marginTop: "1px" }}
                />
                Back
              </button>
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileForgot;
