import "../../style/login.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Link, useLocation } from "react-router-dom";
import usaFlag from "../../assets/usa-flag.png";
import { useEffect, useRef, useState } from "react";
import { userRegister } from "../../service/loginService";
import { Autocomplete, CircularProgress, FormHelperText } from "@mui/material";
import Http from "../../service/http";
import { AiFillCheckCircle } from "react-icons/ai";
import { message } from "antd";

const SignUp = ({ setSignBtnStatus }: any) => {
  const { search }: any = useLocation();
  const [stateOfUsa, setStateOfUsa]: any = useState([]);
  const [otpSentSuccessFully, setOtpSentSuccessFully]: any = useState(false);
  const [loaderForFinalVerify, setLoaderForFinalVerify]: any = useState(false);
  const [loaderForResendBtn, setLoaderForResendBtn]: any = useState(false);
  const [finalSuccess, setFinalSuccess]: any = useState(false);
  const [cityOfStates, setCityOfStates]: any = useState([]);
  const [enterOtpBtn, setEnterOtpBtn]: any = useState(false);
  const [isOtpField, setIsOtpField]: any = useState(false);
  const [loaderForVerify, setLoaderForVerify]: any = useState(false);
  const [numberFieldDisable, setNumberFieldDisable]: any = useState(false);
  const refferalCode = new URLSearchParams(search).get("refferalCode");
  const [formValidationError, setFormValidationError]: any = useState([]);
  const [isOtpVerifyBtn, setIsOtpVerifyBtn]: any = useState(false);
  const [loaderIcon, setLoaderIcon]: any = useState(false);
  const [boxOtp, setBoxOtp]: any = useState("");

  const [signUpData, setSignUpData] = useState({
    fullName: "",
    email: "",
    phone: "",
    password: "",
    jobTitle: "",
    country: "USA",
    state: "",
    city: "",
    linkedInProfile: "",
    inviteCode: refferalCode || "",
    otp: "",
  });

  useEffect(() => {
    getUsaState();
  }, []);

  const getUsaState = async () => {
    const result: any = await Http.get("common/usStates", false);
    if (result.success) {
      setStateOfUsa(result?.data);
    } else {
    }
  };

  const formRef: any = useRef(null);
  const handleUserRegister = (e: any) => {
    e.preventDefault();
    formRef.current.reportValidity();
    const data = {
      ...signUpData,
      phone: signUpData.phone.replace(/\D/g, ""),
    };
    if (formRef.current.reportValidity() && isPasswordValid()) {
      setLoaderIcon(true);

      userRegister(data)
        .then((res: any) => {
          if (res.success) {
            setSignBtnStatus(false);
          }
          setLoaderIcon(false);
        })
        .catch((res) => {
          const errors = res?.data?.errors?.map((error: any) => error?.param);
          setFormValidationError(errors);
          setLoaderIcon(false);
        });
    }
  };

  const usaState: any = stateOfUsa?.map((e: any) => {
    return { label: e?.stateName, id: e?.stateId };
  });

  const usaCity: any = cityOfStates?.map((e: any) => {
    return { label: e };
  });



  const manageState = async (state: any) => {
    if (state?.id !== undefined) {
      setCityOfStates([]);
      const result: any = await Http.get(`common/usCities/${state?.id}`, false);
      if (result?.success) {
        if (result?.data?.length !== 0) {
          setCityOfStates(result?.data);
          setSignUpData({
            ...signUpData,
            state: state?.label,
            city: "",
          });
        } else {
          setCityOfStates([state?.label]);
          setSignUpData({
            ...signUpData,
            state: state?.label,
            city: state?.label,
          });
        }
      }
    }
  };

  const manageCity = (data: any) => {
    setSignUpData({
      ...signUpData,
      city: data?.label,
    });
  };

  const isPasswordValid = () => {
    if (signUpData?.password.length < 8) {
      return false;
    } else {
      return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/.test(signUpData?.password);
    }
  };

  // console.log(signUpData.phone.replace(/\D/g, ""));

  const verifyOtp = async () => {

    setLoaderForVerify(true);
    const body: any = {
      mobileNumber: signUpData.phone.replace(/\D/g, ""),
    };
    Http.post("user/sendMobileOtp", body, true).then((e: any) => {
      if (e.success) {
        setIsOtpField(true);
        // setLoaderForVerify(false);
        setIsOtpVerifyBtn(false);
        setOtpSentSuccessFully(true);
        setNumberFieldDisable(true);
      } else {
        setLoaderForVerify(false);
      }
    }).catch((c: any) => {
      setLoaderForVerify(false);
    });

  };

  const functionForOtp = async (e: any) => {
    setBoxOtp(e.target.value);
    console.log(boxOtp);

    if (boxOtp.length == 5) {
      setEnterOtpBtn(true);
    } else {
      setEnterOtpBtn(false);
    }
  };

  const validationForOtp = async (e: any) => {
    e.preventDefault();
    console.log("boxOtp?.length >>", boxOtp?.length);

    if (boxOtp?.length != 6) return message.error("OTP must have 6 digits")
    setLoaderForFinalVerify(true);
    const body: any = {
      mobileNumber: signUpData.phone.replace(/\D/g, ""),
      otp: boxOtp,
    };
    const result: any = await Http.post("user/verifyMobileOtp", body, false);
    // console.log(result);
    if (result.success) {
      setIsOtpField(false);
      setFinalSuccess(true);
      setSignUpData({
        ...signUpData,
        otp: boxOtp,
      });
    } else {
      // setIsOtpField(false);
      setLoaderForFinalVerify(false);
    }
  };
  const [timer, setTimer]: any = useState(30);

  const resendFun = async () => {
    setLoaderForResendBtn(true);
    const body: any = {
      mobileNumber: signUpData.phone.replace(/\D/g, ""),
    };
    const result: any = await Http.post("user/sendMobileOtp", body, false);
    if (result.success) {
      setTimer(30);
      message.success("OTP send successfully");
      setLoaderForResendBtn(false);
    } else {
      message.error("Error");
    }
  };

  const ResendOption = () => {
    useEffect(() => {
      if (timer > 0) {
        const clearTime = setTimeout(() => {
          setTimer(timer - 1);
        }, 1000);

        return () => {
          clearTimeout(clearTime);
        };
      }
    }, [timer]);


    return (
      <>
        {timer == 0 ? (
          <p
            style={{
              cursor: "pointer",
              float: "right",
              margin: "0px",
              color: "#265f9d",
            }}
            onClick={() => resendFun()}
          >
            Resend
          </p>
        ) : (
          <p style={{
            cursor: "pointer",
            float: "right",
            margin: "0px",
            color: "#265f9d",
          }}>You can Resend Otp after {timer} seconds</p>
        )}
      </>
    );
  };

  return (
    <Box
      component="form"
      sx={{ "& > :not(style)": { m: 1.5, width: "87%" } }}
      noValidate={false}
      autoComplete="off"
      ref={formRef}
    >
      <TextField
        className="animate__animated animate__fadeInDown"
        required
        value={signUpData.fullName}
        // error={formValidationError?.includes("fullName") ? true : false}
        id="outlined-basic"
        label="Full Name"
        variant="outlined"
        onChange={(e: any) =>
          setSignUpData({ ...signUpData, fullName: e.target.value })
        }
      />
      <TextField
        className="animate__animated animate__fadeInDown"
        value={signUpData.email}
        id="outlined-basic"
        error={formValidationError?.includes("email") ? true : false}
        helperText={
          formValidationError?.includes("email") ? "Incorrect entry" : ""
        }
        label="Personal email"
        variant="outlined"
        required
        onChange={(e: any) =>
          setSignUpData({ ...signUpData, email: e.target.value })
        }
      />
      <TextField
        required
        className="animate__animated animate__fadeInDown"
        value={signUpData.password}
        autoComplete="new-password"
        error={formValidationError?.includes("password") ? true : false}
        helperText={
          formValidationError?.includes("password") ? "Incorrect entry" : ""
        }
        id="outlined-password-input"
        label="Create a New Password"
        type="password"
        onChange={(e: any) =>
          setSignUpData({ ...signUpData, password: e.target.value })
        }
      />
      {signUpData.password !== "" && !isPasswordValid() ? (
        <FormHelperText error id="accountId-error">
          {
            "password must be 8 characters long and should contain both letters and numbers."
          }
        </FormHelperText>
      ) : null}
      <TextField
        required
        className="animate__animated animate__fadeInDown"
        id="outlined-basic"
        label="Your Job Title"
        variant="outlined"
        value={signUpData.jobTitle}
        error={formValidationError?.includes("jobTitle") ? true : false}
        helperText={
          formValidationError?.includes("jobTitle") ? "Incorrect entry" : ""
        }
        onChange={(e: any) =>
          setSignUpData({ ...signUpData, jobTitle: e.target.value })
        }
      />
      <span className="usaflag-or-usernumber animate__animated animate__fadeInDown">
        <img src={usaFlag} alt="" className="giusaflag" />
        <input
          required
          disabled={numberFieldDisable}
          type="text"
          name=""
          id=""
          placeholder="Phone Number *"
          className="phone-input"
          value={signUpData.phone}
          onChange={(e: any) => {
            var cleaned = ("" + e.target.value).replace(/\D/g, "");
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
              setIsOtpVerifyBtn(true);
              setSignUpData({
                ...signUpData,
                phone: "(" + match[1] + ") " + match[2] + "-" + match[3],
              });
            } else {
              setSignUpData({
                ...signUpData,
                phone: e.target.value.replace(/\D/g, ""),
              });
            }
          }}
        />
        <button
          className="verify-btn"
          type="button"
          // disabled={!isOtpVerifyBtn}
          style={{
            color: "#265f9d",
            // cursor: isOtpVerifyBtn ? "pointer" : "not-allowed",
            // color: isOtpVerifyBtn ? "#265f9d" : "#a3a3a3",
          }}
          onClick={() => verifyOtp()}
        >
          {" "}
          {finalSuccess ? (
            <span className="checkCircle-after-verify">
              <AiFillCheckCircle className="aiFillCheckCircle" />
              <p className="verified">Verified</p>
            </span>
          ) : otpSentSuccessFully ? (
            <span style={{ cursor: "auto", color: "green" }}>Sent</span>
          ) : loaderForVerify ? (
            <CircularProgress
              style={{ width: "25px", height: "25px", marginTop: "5px" }}
            />
          ) : (
            "Verify"
          )}{" "}
        </button>
        {/* <span className="checkCircle-after-verify">
          <AiFillCheckCircle className="aiFillCheckCircle" />
          <p className="verified">Verified</p>
        </span> */}
      </span>
      {isOtpField ? (
        <FormHelperText
          style={{ color: "blue", margin: "0px" }}
          className="animate__animated animate__fadeInDown"
          id="accountId-error"
        >
          {/* <button style={{float:"right", background:"none", color:"#265f9d", width:"0px", height:"0px", fontSize:"12px"}}>Resend</button> */}
          {loaderForResendBtn ? (
            <CircularProgress
              style={{
                width: "15px",
                height: "15px",
                marginTop: "5px",
                float: "right",
                marginRight: "7px",
              }}
            />
          ) : (
            <div>
              <ResendOption />
            </div>
          )}
        </FormHelperText>
      ) : null}
      {isOtpField ? (
        <span className="usaflag-or-usernumber animate__animated animate__fadeInDown">
          <input
            className="enter-otp"
            type="number"
            name=""
            id=""
            placeholder="Enter OTP"
            value={boxOtp}
            onChange={(e: any) => functionForOtp(e)}
          // onPaste={(e: any) => functionForOtp(e)}
          />
          <button
            className="validate"
            onClick={(e: any) => validationForOtp(e)}
            // disabled={!enterOtpBtn}
            style={{
              color: "#265f9d",
              // color: enterOtpBtn ? "#265f9d" : "#a3a3a3",
              // cursor: enterOtpBtn ? "pointer" : "not-allowed",
            }}
          >
            {loaderForFinalVerify ? (
              <CircularProgress
                style={{ width: "25px", height: "25px", marginTop: "5px" }}
              />
            ) : (
              "Validate"
            )}
          </button>
        </span>
      ) : null}
      <TextField
        required
        className="animate__animated animate__fadeInDown"
        defaultValue={"USA"}
        id="outlined-basic"
        variant="outlined"
        disabled
      />
      <Autocomplete
        className="animate__animated animate__fadeInDown"
        disablePortal
        id="combo-box-demo"
        options={usaState}
        sx={{ width: 300 }}
        value={signUpData.state}
        onChange={(e: any, data: any) => manageState(data)}
        renderInput={(params) => (
          <TextField className="state-search-field" required {...params} label="State" />
        )}
      />
      <Autocomplete
        className="animate__animated animate__fadeInDown"

        disablePortal
        id="combo-box-demo"
        disabled={signUpData.city == signUpData.state && signUpData.city.length > 0 ? true : false}
        value={signUpData?.city}
        options={usaCity?.length > 0 ? usaCity : [{ label: "Please select state first." }]}
        getOptionDisabled={(option: any) => option?.label === "Please select state first."}
        sx={{ width: 300 }}
        onChange={(e: any, data: any) => manageCity(data)}
        renderInput={(params) => (
          <TextField className="state-search-field" required {...params} label="City" />
        )}
      />
      <TextField
        required
        className="animate__animated animate__fadeInDown"
        value={signUpData.linkedInProfile}
        error={formValidationError?.includes("linkedInProfile") ? true : false}
        helperText={
          formValidationError?.includes("linkedInProfile")
            ? "Incorrect entry"
            : ""
        }
        id="outlined-basic"
        label="Linkedin Profile"
        variant="outlined"
        onChange={(e: any) =>
          setSignUpData({ ...signUpData, linkedInProfile: e.target.value })
        }
      />
      <span
        className="animate__animated animate__fadeInDown"
        style={{ display: "flex", gap: "10px" }}
      >
        <input
          type="checkbox"
          required
          name=""
          id=""
          style={{ width: "20px", height: "25px", outline: "none" }}
        />
        <Link
          to={"/tc"}
          target={"_blank"}
          className="animate__animated animate__fadeInDown"
          style={{ color: "#265f9d", outline: "none" }}
        >
          Terms and Conditions*
        </Link>
      </span>
      <button
        className="animate__animated animate__fadeInDown"
        type="submit"
        onClick={(e: any) => handleUserRegister(e)}
        style={{
          width: "48%",
          background: loaderIcon ? "#265f9da6" : "#265f9d",
        }}
      >
        {loaderIcon ? (
          <CircularProgress
            style={{
              color: "white",
              height: "18px",
              width: "18px",
              position: "relative",
              left: "0px",
              top: "2px",
            }}
          />
        ) : null}
        Create Account
      </button>
    </Box>
  );
};

export default SignUp;
