import { async } from "q";
import Http from "./http";

export const getUserRequirementData = async (data: any) => {
  return await Http.post("candidate/search", data, false).then((res: any) => {
    return res;
  });
};

export const totalReferralsData = async () => {
  return await Http.get("user/totalReferrals", false).then((res: any) => {
    return res;
  });
};

export const totalEarnings = async () => {
  return await Http.get("user/totalEarning", false).then((earning: any) => {
    return earning;
  });
};

export const hotRequirementsApi = async (data:any) => {
  return await Http.post("user/getHotRequirements",data, false).then((res: any) => {
    return res;
  });
};

export const newReferenceApi = async (newRefrence: any) => {
  return await Http.post("candidate/add", newRefrence, true).then(
    (reference: any) => {
      return reference;
    }
  );
};

export const RequirementOverviewApi = async (data: any) => {
  return await Http.get(`user/getSingleRequirement/${data}`, false).then(
    (data: any) => {
      return data;
    }
  );
};

export const updateCandidateApi = async (data:any) => {
  return await Http.put(`candidate/update`,data, false).then((e:any)=>{
      return e
  })
}

export const usStateApi = async () =>{
  return await Http.get(`common/usStates`,false).then((e:any)=>{
      return e
  })
}

export const usCityApi = async (cityData:any) =>{
  return await Http.get(`common/usCities/${cityData}`,false).then((data:any)=>{
    return data
  })
}
