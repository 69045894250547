import React, { useState } from "react";
import "../style/login.css";
import wiseskullsblue from "../assets/WiseskullsBlue.svg";
import group831 from "../assets/Group 831.png";
import group652 from "../assets/Group 652.png";
import group827 from "../assets/Group 827.png";
import group656 from "../assets/Group 656.png";
import maskGroup2 from "../assets/Mask Group 2.png";
import SignUp from "../component/loginComp/SignUp";
import SignIn from "../component/loginComp/SignIn";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineComputerDesktop } from "react-icons/hi2";
import { FiMail, FiPhoneCall } from "react-icons/fi";
import { isMobile } from "react-device-detect";

const Login = () => {
  const [signBtnStatus, setSignBtnStatus] = useState(true);
  const navigate = useNavigate();

  return (
    <div className="login-page-main-component">
      <header className="wiseskulls-login-logo">
        <img
          src={wiseskullsblue}
          alt=""
          className="wiseskullsblue d-none d-lg-block d-lg-block"
        />
        <img
          src={group831}
          alt=""
          className="group831 .d-none d-lg-none d-xl-none"
        />
        <div className="wiseskulls-signup-signin d-lg-none">
          <div className="signup-signin-holder d-lg-none">
            <button
              className={`sign-in-button ${!signBtnStatus ? "" : "inActive"}`}
              onClick={() => {
                setSignBtnStatus(false);
                navigate("/sign", { state: false });
              }}
            >
              Sign in
            </button>
            <button
              className={`sign-up-button ${signBtnStatus ? "" : "inActive"}`}
              onClick={() => {
                setSignBtnStatus(true);
                navigate("/sign", { state: true });
              }}
            >
              Sign Up
            </button>
          </div>
        </div>
      </header>
      <div className="container lacture-and-login-holder">
        <div className="wiseskulls-hey-there">
          <h1>Hey there!</h1>
          <h3>
            Want to make some extra cash without putting in too much effort?
          </h3>
          <br />
          <br />
          <p>
            Want to make some extra cash without putting in too much effort?
            <br />
            We have designed a comprehensive rewards program to recognize and
            appreciate your contributions to our recruitment process.
          </p>
          <br />
          <p>
            Wise Skulls is Introducing a referral program designed exclusively
            for IT professionals like you. You can make $500 when your referred
            individual starts the project successfully.
          </p>
          <br />
          <p>
            In addition, upon referring 10 successful placements, you will be
            entitled to a generous super bonus of $3000.
          </p>
          <br />
          <p>
            It's a win-win situation for everyone involved, and all you have to
            do is refer your colleagues, friends, or family to earn passive
            income.
          </p>
          <br />
          <p>
            This is a valuable opportunity that you won't want to miss! Please
            keep in mind that this referral program is only intended for IT
            professionals and NOT for any IT recruiter or agency.
          </p>
        </div>

        <div className="wiseskulls-signup-signin d-none d-lg-block d-xl-block">
          <div className="signup-signin-holder">
            <button
              className={`sign-up-button ${signBtnStatus ? "" : "inActive"}`}
              onClick={() => setSignBtnStatus(true)}
            >
              Sign Up
            </button>
            <button
              className={`sign-in-button ${!signBtnStatus ? "" : "inActive"}`}
              onClick={() => setSignBtnStatus(false)}
            >
              Sign in
            </button>
          </div>
          <div className="form-holder animate__animated animate__fadeIn">
            <p>
              Be Wise, Earn with{" "}
              <span style={{ fontWeight: "500" }}>Wise Skulls</span>
            </p>
            {signBtnStatus ? (
              <SignUp setSignBtnStatus={setSignBtnStatus} />
            ) : (
              <SignIn />
            )}
          </div>
        </div>
      </div>
      <div className="signup-aboutus-main-div">
        <div className="container how-does-this-work">
          <p>How does this work?</p>
          <img src={group827} alt="" />
          <i>A typical scenario of how it works,</i>
        </div>
        <div className="signup-aboutUs-paragraph">
          <ul>
            <li>You will see job requirements on this portal</li>
            <br />
            <li>
              {" "}
              You can refer as many relevant candidates as you can for all the
              job requirements.
            </li>
            <br />
            <li>
              You will be notified when your referred candidate is selected for
              an interview or job and when he/she starts working.
            </li>
            <br />
            <li>
              You will be eligible for $500 for each successful placement as
              soon as the candidate starts, and at one month's completion, you
              will receive the money.
            </li>
            <br />
            <li>
              When such 10 candidates have started the project successfully,
              full-time or contact, you will be entitled to a bonus of $3000
            </li>
            <br />
          </ul>
          <p>
            Not only will you be rewarded for your referral, but your candidate
            will also benefit from our top-notch recruitment services.
          </p>{" "}
          <br />
          <p>
            We're looking to grow our team with talented individuals, and we
            know that you have a great network of tech-savvy friends and
            colleagues who can help us achieve our goals collectively.
          </p>
          <br />
          <p>
            {" "}
            So, let's make this referral program a success and spread the joy of
            working with us to your network of IT professionals!{" "}
          </p>
          <br />
          <p>
            So why not spread the word and reap the rewards?{" "}
            <span
              onClick={() => {
                if (isMobile) {
                  navigate("/sign", { state: true });
                } else {
                  navigate("/")
                }
              }}
              style={{ cursor: "pointer" }}
            >
              Sign up now!
            </span>
          </p>
        </div>
        <div className="signup-footer">
          <img src={group656} alt="" className="group656" />
          <img src={maskGroup2} alt="" className="maskGroup2" />
          <p className="letsGrowTogether">Let's Grow Together</p>
          <img src={group652} alt="" className="group652" />
          <div className="signup-contactDetails d-none d-sm-flex d-md-flex d-lg-flex d-xl-flex">
            <span className="icon-holder">
              <HiOutlineComputerDesktop className="computerDesktop" />
            </span>
            <p className="getInTouch">
              Get in touch and let us know how we can help.
            </p>
            <div className="phone-WebSite-email-holder ">
              <p className="phone-icone-number-holder">
                <span>
                  <FiPhoneCall />
                </span>
                +1 669-260-9707
              </p>
              <a
                href="https://www.wiseskulls.com/"
                target="_blank"
                style={{ textDecoration: "none" }}
                rel="noreferrer"
              >
                <p>www.wiseskulls.com</p>
              </a>
              <p className="mail-icone-email-holder">
                <span>
                  <FiMail />
                </span>
                pauravn@wiseskulls.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
