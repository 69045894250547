import { TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { bankingDetailsAPI, getBankingDetailsAPI, } from "../../service/profileService";
import { Button, message } from "antd";
import { Space, Spin } from "antd";
import Box from "@mui/material/Box";


const BankDetailsPage = () => {

  const formRef: any = useRef(null);


  const [loadSubmit, setLoadSubmit] = useState(false);
  const [bankDetailsLoader, setBankDetailsLoader] = useState(false);
  const [userBankDetails, setUserBankDetails]: any = useState({
    accountName: "",
    fullAddress: "",
    routingNumber: "",
    bankName: "",
    bankFullAddress: "",
    accountNumber: "",
  });


  const setBankDetails = async () => {
    formRef.current.reportValidity();

    if (userBankDetails.accountName === '' || userBankDetails.fullAddress === '' || userBankDetails.routingNumber === '' || userBankDetails.bankName === '' || userBankDetails.bankFullAddress === '' || userBankDetails.accountNumber ==='') {
      formRef.current.reportValidity();
    } else {
      setLoadSubmit(true);
      await bankingDetailsAPI(userBankDetails)
        .then((response: any) => {
          if (response.success) {
            message.success("Bank Details Submitted Successfully...");
            setLoadSubmit(false);
          } else {
            setLoadSubmit(false);
          }
        })
        .catch((err: any) => {
          setLoadSubmit(false);
        });
    }

  };

  useEffect(() => {
    setBankDetailsLoader(true);
    getBankingDetailsAPI().then((resss: any) => {
      setUserBankDetails({
        accountName: resss?.data?.accountName,
        fullAddress: resss?.data?.fullAddress,
        routingNumber: resss?.data?.routingNumber,
        bankName: resss?.data?.bankName,
        bankFullAddress: resss?.data?.bankFullAddress,
        accountNumber: resss?.data?.accountNumber,
      });
      setBankDetailsLoader(false);
    });
  }, []);


  return (
    <>

      <div>
        {bankDetailsLoader ? (
          <Space size="middle" className="bankDetailsSpin">
            <Spin size="large" />
          </Space>
        ) : (
          <Box className="bank-details-main-section"
            component="form"
            noValidate
            autoComplete="off"
            ref={formRef}
          >
            <TextField
              className="single-input-class"
              id="outlined-uncontrolled"
              label="Account Name"
              type={"text"}
              required
              value={userBankDetails.accountName}
              onChange={(e: any) =>
                setUserBankDetails({
                  ...userBankDetails,
                  accountName: e.target.value,
                })
              }
            />
            <TextField
              className="single-input-class"
              id="outlined-uncontrolled"
              label="Bank Name"
              type={"text"}
              required
              value={userBankDetails.bankName}
              onChange={(e: any) =>
                setUserBankDetails({
                  ...userBankDetails,
                  bankName: e.target.value,
                })
              }
            />

            <TextField
              className="single-input-class"
              id="outlined-uncontrolled"
              label="Account Number"
              type={"text"}
              required
              value={userBankDetails.accountNumber}
              onChange={(e: any) =>
                setUserBankDetails({
                  ...userBankDetails,
                  accountNumber: e.target.value,
                })
              }
            />
            <TextField
              className="single-input-class"
              id="outlined-uncontrolled"
              label="Routing Number"
              type={"text"}
              required
              value={userBankDetails.routingNumber}
              onChange={(e: any) =>
                setUserBankDetails({
                  ...userBankDetails,
                  routingNumber: e.target.value
                })
              }
            />

            <div className="address-filed">
              <TextField
                className="single-address-class"
                id="outlined-uncontrolled"
                label="Account Holders Full Address"
                type={"text"}
                required
                value={userBankDetails.fullAddress}
                onChange={(e: any) =>
                  setUserBankDetails({
                    ...userBankDetails,
                    fullAddress: e.target.value,
                  })
                }
              />

              <TextField
                className="single-address-class"
                id="outlined-uncontrolled"
                label="Bank Full Address"
                type={"text"}
                required
                value={userBankDetails.bankFullAddress}
                onChange={(e: any) =>
                  setUserBankDetails({
                    ...userBankDetails,
                    bankFullAddress: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <Button
                loading={loadSubmit}
                className="bank-details-submit-btn"
                onClick={() => setBankDetails()}
              >
                Submit
              </Button>
            </div>
          </Box>
        )}
      </div>
    </>
  );
};

export default BankDetailsPage;
