import React, { useEffect, useRef } from "react";
import "../../style/referForJob.css";
// import "../../style/addReference.css";
import { useState } from "react";
import { Button, Modal } from "antd";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { newReferenceApi } from "../../service/userDashboardService";
import { userRefresh } from "../../redux/userSlice";
import { MdOutlineCancel } from "react-icons/md";
import { Autocomplete } from "@mui/material";
import { usStateApi } from "../../service/userDashboardService";
import { usCityApi } from "../../service/userDashboardService";

const ReferForJob = ({ setReferJOb, referJOb, jobIdAndTitleData }: any) => {
  const formRef: any = useRef(null);
  const [referReferenceErrors, setReferReferenceErrors]: any = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [referUsStateData, setReferUsStateData]: any = useState([]);
  const [referCityData, setReferCityData] = useState([]);
  const [referCityVal, setReferCityVal]: any = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [referNewReference, setReferNewReference]: any = useState({
    fullName: "",
    requirementId: "",
    title: "",
    email: "",
    phone: "",
    linkedInProfile: "",
    state: "",
    city: "",
  });

  // console.log("referUsStateData>>>>>", referUsStateData);

  useEffect(() => {
    setReferNewReference({
      ...referNewReference,
      requirementId: jobIdAndTitleData?.id,
    });
  }, [jobIdAndTitleData]);

  const dispatch = useDispatch();

  const referNewReferenceFun = async () => {
    setSubmitLoader(true);
    formRef.current.reportValidity();
    const data = {
      ...referNewReference,
      phone: referNewReference.phone.replace(/\D/g, ""),
    };
    if (formRef.current.reportValidity()) {
      await newReferenceApi(data)
        .then(() => {
          dispatch(userRefresh());
          setReferJOb(false);
          setSubmitLoader(false);
          setReferReferenceErrors([]);
        })
        .catch((err: any) => {
          const errData = err?.data?.errors?.map((param: any) => {
            return param.param;
          });
          setReferReferenceErrors(errData);
          setSubmitLoader(false);
        });
    } else {
      setSubmitLoader(false);
    }
  };

  useEffect(() => {
    if (referJOb) {
      setIsListLoading(true);
      usStateApi().then((e: any) => {
        setIsListLoading(false);
        setReferUsStateData(e?.data);
      });
    }
  }, [referJOb]);

  return (
    <>
      <Modal
        className="client-refer-for-job"
        width={800}
        open={referJOb}
        // onOk={() => setReferJOb(false)}
        // onCancel={() => setReferJOb(false)}
        footer={null}
        afterClose={() =>
          setReferNewReference({
            fullName: "",
            requirementId: "",
            title: "",
            email: "",
            phone: "",
            linkedInProfile: "",
            state: "",
            city: "",
          })
        }
      >
        <div className="new-refer-section" style={{ height: "max-content" }}>
          <div className="new-refer-header">
            <p>Add New Reference</p>
            <p className="newRefCancel">
              <MdOutlineCancel onClick={() => setReferJOb(false)} />
            </p>
          </div>
          <div className="refer-all-id-input">
            <div className="refer-name-job-id">
              <Box
                className="refer-textfield-all-input"
                component="form"
                sx={{ "& > :not(style)": { m: 0, width: "230px" } }}
                noValidate
                autoComplete="off"
                ref={formRef}
              >
                <TextField
                  required
                  error={referReferenceErrors.includes("fullName")}
                  helperText={
                    referReferenceErrors?.includes("fullName") &&
                    "Incorrect entry"
                  }
                  id="outlined-basic"
                  value={referNewReference?.fullName}
                  label="Full Name"
                  variant="outlined"
                  onChange={(e: any) => {
                    setReferNewReference({
                      ...referNewReference,
                      fullName: e.target.value,
                    });
                  }}
                />
                <TextField
                  required
                  error={referReferenceErrors.includes("title")}
                  helperText={
                    referReferenceErrors?.includes("title") && "Incorrect entry"
                  }
                  id="outlined-basic"
                  value={referNewReference?.title}
                  label="Title"
                  variant="outlined"
                  onChange={(e: any) => {
                    setReferNewReference({
                      ...referNewReference,
                      title: e.target.value,
                    });
                  }}
                />
                <TextField
                  required
                  error={referReferenceErrors.includes("email")}
                  helperText={
                    referReferenceErrors?.includes("email") && "Incorrect entry"
                  }
                  id="outlined-basic"
                  value={referNewReference?.email}
                  label="Personal Email"
                  variant="outlined"
                  onChange={(e: any) => {
                    setReferNewReference({
                      ...referNewReference,
                      email: e.target.value,
                    });
                  }}
                />
                <TextField
                  required
                  error={referReferenceErrors.includes("phone")}
                  helperText={
                    referReferenceErrors?.includes("phone") && "Incorrect entry"
                  }
                  id="outlined-basic"
                  value={referNewReference?.phone}
                  label="Phone Number"
                  variant="outlined"
                  type="text"
                  onChange={(e: any) => {
                    var cleaned = ("" + e.target.value).replace(/\D/g, "");
                    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                    if (match) {
                      setReferNewReference({
                        ...referNewReference,
                        phone:
                          "(" + match[1] + ") " + match[2] + "-" + match[3],
                      });
                    } else {
                      setReferNewReference({
                        ...referNewReference,
                        phone: e.target.value.replace(/\D{10}$/g, ""),
                      });
                    }
                  }}
                />
                <TextField
                  error={referReferenceErrors.includes("linkedInProfile")}
                  helperText={
                    referReferenceErrors?.includes("linkedInProfile") &&
                    "Incorrect entry"
                  }
                  id="outlined-basic"
                  value={referNewReference?.linkedInProfile}
                  label="linkedin ID"
                  variant="outlined"
                  onChange={(e: any) => {
                    setReferNewReference({
                      ...referNewReference,
                      linkedInProfile: e.target.value,
                    });
                  }}
                />

                <Autocomplete
                  className="form-autocomplete"
                  disablePortal
                  loading={true}
                  loadingText={"Loading please wait....."}
                  id="combo-box-demo"
                  value={referNewReference?.state}
                  onChange={(e: any, value: any) => {
                    if (value?.id !== undefined) {
                      setReferCityData([]);
                      usCityApi(value?.id).then((ele: any) => {
                        if (ele?.data?.length > 0) {
                          setReferCityData(ele?.data);
                          setReferNewReference({
                            ...referNewReference,
                            state: value?.label,
                            city: "",
                          });
                          setReferCityVal("");
                        } else {
                          setReferNewReference({
                            ...referNewReference,
                            state: value?.label,
                            city: value?.label,
                          });
                          setReferCityVal(value?.label);
                        }
                      });
                    }
                  }}
                  options={referUsStateData?.map((e: any) => {
                    if (referNewReference?.state !== e?.stateName) {
                      return { label: e?.stateName, id: e?.stateId };
                    } else {
                      return { label: e?.stateName, id: e?.stateId };
                    }
                  })}
                  sx={{ width: 300 }}
                  renderInput={(params: any) => {
                    return (
                      <TextField
                        required
                        className="auto-text"
                        {...params}
                        label="State"
                      />
                    );
                  }}
                />
                <div className="last-city-sec">
                  <Autocomplete
                    className="form-autocomplete refer-CityClass"
                    disablePortal
                    // loading={true}
                    // loadingText={"Loading please wait....."}
                    value={referNewReference?.city}
                    disabled={referNewReference.city == referNewReference.state && referNewReference?.city?.length > 0 ? true : false}
                    id="combo-box-demo"
                    onChange={(e: any, value: any) => {
                      setReferCityVal(value);
                      setReferNewReference({
                        ...referNewReference,
                        city: value?.label,
                      });
                    }}
                    options={
                      referNewReference?.state?.length > 0
                        ? referCityData?.map((e: any) => {
                            if (referNewReference?.state !== e?.stateName) {
                              return { label: e };
                            } else {
                              return { label: e };
                            }
                          })
                        : [{ label: "Please select state first" }]
                    }
                    getOptionDisabled={(option) =>
                      option?.label === "Please select state first"
                    }
                    sx={{ width: 300 }}
                    renderInput={(params: any) => {
                      return (
                        <TextField
                          required
                          className="auto-text"
                          {...params}
                          label="City"
                        />
                      );
                    }}
                  />

                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="jobIdClass"
                    disabled
                    value={`${jobIdAndTitleData?.jpcCode} - ${jobIdAndTitleData?.title}`}
                  />
                </div>
              </Box>
            </div>
          </div>
          <div className="new-refer-submit-btn">
            <Button loading={submitLoader} onClick={referNewReferenceFun}>
              {" "}
              Submit{" "}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReferForJob;
