import { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import "../../style/referrerStyle/ReferrerLeftSide.css";
import { getReferrarsDetails } from "../../service/referralService";
import { setAdminReferralsDetails } from "../../redux/adminReferralSlice";
import ReferrerExportBtn from "./ReferrerExportBtn";
import { BiErrorAlt } from "react-icons/bi";
import { Spin } from "antd";

const ReferrerLeftSide = ({
  setSearchReferralData,
  searchReferralData,
  setIsRefererLoading,
  referrerDataLoading,

}: any) => {

  const [myStyle, setMyStyle]: any = useState("");


  const referralData = useSelector(
    (state: any) => state?.adminReferrals?.referralsList?.data
  );

  const dispatch = useDispatch();

  useEffect(()=>{
    const data:any = {}
    dispatch(setAdminReferralsDetails(data))

  },[])

  const handleReferrerDetails = (e: any, id: any) => {
      e.preventDefault();
    setMyStyle(id);
    setIsRefererLoading(true)
    if (id !== null) {
      getReferrarsDetails(id).then((response: any) => {
        dispatch(setAdminReferralsDetails(response?.data));
        setIsRefererLoading(false)
      });
    }
  }

  return (
    <div className="referrer-left-main-section">
      <div className="referrer-left-header">
        <p>Referrers</p>
        <ReferrerExportBtn />
      </div>
      <div className="referrer-search-section">
        <input
          type="text"
          placeholder="Search By Referrer Details"
          value={searchReferralData?.searchDetails}
          onChange={(e: any) =>
            setSearchReferralData({
              ...searchReferralData,
              searchDetails: e.target.value,
            })
          }
        />
        <CiSearch className="search-icon" />
      </div>
      <div className="job-id-referrer-name-section">
        <div className="job-header-section">
          <p>Referrer ID</p>
        </div>
        <div className="referrer-header-section">
          <p>Referrer Name</p>
        </div>
      </div>


      {referrerDataLoading ? (
        <div className="referrer-spinnerLoading">
          <Spin size="large" />
        </div>
      ) : (
        <div className="second-data-section">
          {
            referralData?.length === 0 ? (<div className="noDataFoundSec">
              <span>
                <BiErrorAlt style={{ fontSize: '25px', marginBottom: '15px' }} />
              </span>
              <p style={{ fontWeight: "500" }} >No Data Found</p>
            </div>) :
              referralData?.map((item: any, index: any) => {
                return (
                  <>
                    <div className={`first-name-section`} style={{ background: myStyle == item?.id ? "#265f9d" : "white", color: myStyle == item?.id ? "white" : "black" }} key={index} id={item?.id} onClick={(e: any) => handleReferrerDetails(e, item?.id)}>
                      <div className="job-name" style={{ width: "50%", cursor: "pointer" }}>
                        <p>{item?.id}</p>
                      </div>
                      <div
                        className="referrer-name"
                        style={{ width: "50%", paddingLeft: "8px", cursor: "pointer" }}
                      >
                        <h6 style={{ fontSize: "14px", lineHeight: "2.2", whiteSpace: "nowrap" }} >{item?.fullName}</h6>
                      </div>
                    </div>
                  </>
                );
              })}
        </div>
      )}
    </div>
  );
};

export default ReferrerLeftSide;
