import React, { useEffect } from "react";
import "../../style/candidate.css";
import { useRowSelect, useTable } from "react-table";
import { useSelector } from "react-redux";
import { Spin, Tooltip } from "antd";
import { BiErrorAlt } from "react-icons/bi";
import { BsLinkedin } from "react-icons/bs";
import { Link } from "react-router-dom";
import SwitchComp from "./SwitchComp";
import moment from "moment";
const CandidateTable = ({
  candidateHiringStatus,
  setcandidateHiringStatus,
  setCandiDateIdSend,
  candiDateIdSend,
  isLoading,
}: any) => {
  const candidatesData = useSelector(
    (state: any) => state?.candidate?.candidatesList
  );

  const isUser = useSelector((state:any)=> state?.login?.user)

  const columns: any = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Requirement Name",
        accessor: "requirementTitle",
      },
      {
        Header: "Candidate Name",
        accessor: "fullName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone No.",
        accessor: "phone",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Payment Status",
        accessor: "isPaid",
      },
      {
        Header: "Candidate Status",
        accessor: "hiringStatus",
      },
      {
        Header: "Lead Status",
        accessor: "leadStatus",
      },
      
      {
        Header: "LinkedIn ID",
        accessor: "linkedInProfile",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Referred By",
        accessor: "referredBy",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
      },
      {
        Header: "Updated At",
        accessor: "updatedAt",
      },
    ],
    []
  );

  const Checkbox = React.forwardRef(({ indeterminate, ...rest }: any, ref) => {
    const defaultRef: any = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <span className="candidate-header-span">
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </span>
    );
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  }: any = useTable(
    {
      columns,
      data: candidatesData || [],
      initialState: {
        hiddenColumns:isUser?.role !=="0"?["isPaid"]:[],
        }
    },
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }: any) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }: any) => (
            <Checkbox
              {...row.getToggleRowSelectedProps()}
              className="candidate-id-td-checkbox"
            />
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    setcandidateHiringStatus({
      ...candidateHiringStatus,
      candidateIds: selectedFlatRows?.map((row: any) => row?.original?.id),
    });
    setCandiDateIdSend(selectedFlatRows?.map((row: any) => row?.original?.id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows]);

  return (
    <div className="candidate-table-container">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        {isLoading ? (
          <div className="candidateTableSpiner">
            <Spin tip="Loading" size="large" />
          </div>
        ) : candidatesData?.length > 0 ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    if (cell.column.id === "isPaid") {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="candidate-id-td"
                        >
                          <SwitchComp payMentStatus={cell.row.original} />
                        </td>
                      );
                    }
                    if (cell?.column?.id === "createdAt") {
                      return (
                        <td>
                          {moment(cell.value).format("DD/MM/YYYY, hh:mm:ss a")}
                        </td>
                      );
                    }
                    if (cell?.column?.id === "updatedAt") {
                      return (
                        <td>
                          {moment(cell.value).format("DD/MM/YYYY, hh:mm:ss a")}
                        </td>
                      );
                    }

                    if (cell?.column?.id === "linkedInProfile") {
                      if(cell?.value !== "") 
                      {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="candidate-id-td"
                        >
                          <Tooltip
                            placement="top"
                            title={cell?.value}
                            color={"blue"}
                            key={"white"}
                          >
                            <Link to={cell?.value} target="_blank">
                              <BsLinkedin size={16} />
                            </Link>
                          </Tooltip>
                        </td>
                      );} else {
                        return (
                          <td> <BsLinkedin color="#959595" size={16}/></td>
                        )
                      }
                    }

                    return (
                      <td {...cell.getCellProps()}>{cell?.value===null || cell?.value===""? "NA" : cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <div className="candidate-nodata-box">
              <p>
                <BiErrorAlt className="nodata-icon" />
              </p>
              <p className="nodata-text">No Data Found</p>
            </div>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default CandidateTable;
