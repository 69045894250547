import Http from "./http";

export const getUserData = async (page: any, perPage: any) => {
  return await Http.get(
    `admin/recruiter/list?page=${page}&perPage=${perPage}`,
    false
  ).then((res: any) => {
    return res;
  });
};

export const addUserData = async (data: any) => {
  return await Http.post("admin/recruiter/add", data, true).then((res: any) => {
    return res;
  });
};

export const SearchUserData = async (data: any) => {
  return await Http.post("admin/recruiter/search", data, false).then(
    (res: any) => {
      return res;
    }
  );
};

export const DeleteUserData = async (id: any) => {
  return await Http.delete(`admin/recruiter/delete/${id}`, false).then((res: any) => {
    return res;
  });
};

export const updateUserData = async (data: any) => {
    return await Http.put(`admin/recruiter/update`, data, true).then((res: any) => {
      return res;
    });
  };