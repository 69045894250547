/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import "../../style/ReferredUsers.css";
import { ImTrophy } from "react-icons/im";
import { BiErrorAlt } from "react-icons/bi";
import AddReference from "./AddReference";
import sharePng from "../../assets/Group 832.png";
import { FiEdit, FiSearch } from "react-icons/fi";
import { MdSpeakerNotes } from "react-icons/md";
import {
  getUserRequirementData,
} from "../../service/userDashboardService";
import NotePopUp from "./NotePopUp";
import UserDashboardPagination from "./UserDashboardPagination";
import RequirementOverview from "./RequirementOverview";
import { useDispatch, useSelector } from "react-redux";
import { Space, Spin } from "antd";
import { setCandidateTableData } from "../../redux/userDash";
import EditUserModal from "./EditUserModal";
import { isMobile } from "react-device-detect";
import { FaRegEdit } from "react-icons/fa";

const ReferredUsers = ({
  requirementLoading,
  setRequirementOverviewValue,
  requirementOverviewValue,
  requirementOverviewAllData,
  referJOb,
  setReferJOb,
}: any) => {
  const [searchByStatusValue, setSearchByStatusValue] = useState("");
  const [searchAnyValue, setSearchAnyValue] = useState("");
  const [yourReferralsData, setYourReferralsData]: any = useState([]);
  const [isFirstLoaded, setFirstLoaded] = useState(false);
  const [notesIconValue, setNotesIconValue] = useState([]);
  const [paginationPageVal, setPaginationPageVal] = useState("1");
  const [noteModal, setNoteModal] = useState(false);
  const [allHiredRejectedVal, setAllHiredRejectedVal] = useState("All");
  const [loaderState, setLoaderState] = useState(false);
  const [getCandidateNoteData, setGetCandidateNoteData] = useState("");
  const [editCandidateData, setEditCandidateData]:any = useState({});
  const [refModalOpen, setRefModalOpen] = useState(false);
  const { rewardsData }: any = useSelector((state: any) => state.candidateData);
  const { userRefresh } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    setFirstLoaded(true);
  }, [userRefresh]);

  useEffect(() => {
    if(!isMobile){
      handleCandidateList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchByStatusValue, paginationPageVal, userRefresh]);

  useEffect(() => {
    if (!isFirstLoaded) {
      return;
    } else {
      const getData:any = setTimeout(() => {
        handleCandidateList();
      }, 800);

      return () => clearTimeout(getData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchAnyValue]);

  const handleCandidateList = async () => {
    const searchData = {
      searchAny: searchAnyValue,
      searchByStatus: searchByStatusValue,
      page: Number(paginationPageVal),
      perPage: 7,
    };

    setLoaderState(true);
    await getUserRequirementData(searchData).then((res: any) => {
      setYourReferralsData(res?.data);
      setLoaderState(false);
      dispatch(setCandidateTableData(res?.data?.data));
    });
  };

  const showReferJobModal = () => {
    setRequirementOverviewValue(false);
    setReferJOb(true);
  };

  return (
    <>
      <div className="referredUsersMainDiv">
        {requirementOverviewValue ? (
          <RequirementOverview
            referJOb={referJOb}
            setReferJOb={setReferJOb}
            showReferJobModal={showReferJobModal}
            requirementLoading={requirementLoading}
            requirementOverviewAllData={requirementOverviewAllData}
            setRequirementOverviewValue={setRequirementOverviewValue}
          />
        ) : null}

        <div className="referredAndEarningMainDiv">
          <div className="totalReferralsDiv">
            <p className="totalReferralsFirstp">{rewardsData?.referral || 0}</p>
            <p className="totalReferralsSecoundp">Total Referrals</p>
          </div>
          <div className="earningsDiv">
            <p className="earningsIcon">
              <ImTrophy />
            </p>
            <div className="earningContaine">
              <p className="earningContaineFirstP">
                ${rewardsData?.earning || 0}
              </p>
              <p className="earningContaineSecoundP">Earnings</p>
            </div>
          </div>
          <div className="addReferenceMainDivContaine">
            <AddReference />
          </div>
        </div>
        <p className="referredFirstDivIcon">
          <img src={sharePng} alt="" />
        </p>
        <div className="referredAndAddreferenceMinDiv">
          <div className="referredMaindiv">
            <div className="referredFirstDiv">
              <p className="referredUserText">Your Referrals</p>
            </div>
            <div className="referredSecoundDiv">
              <p
                className={
                  allHiredRejectedVal === "All"
                    ? "referredAllP1"
                    : "referredAllP"
                }
                id=""
                onClick={(e: any) => {
                  setSearchByStatusValue(e.target.id);
                  setAllHiredRejectedVal(e.target.innerText);
                  setPaginationPageVal("1");
                }}
              >
                All
              </p>
              <p
                className={
                  allHiredRejectedVal === "Hired"
                    ? "referredHiredText1"
                    : "referredHiredText"
                }
                id="1"
                onClick={(e: any) => {
                  setSearchByStatusValue(e.target.id);
                  setAllHiredRejectedVal(e.target.innerText);
                  setPaginationPageVal("1");
                }}
              >
                Hired
              </p>
              <p
                className={
                  allHiredRejectedVal === "Rejected"
                    ? "referredRejectText1"
                    : "referredRejectText"
                }
                id="2"
                onClick={(e: any) => {
                  setSearchByStatusValue(e.target.id);
                  setAllHiredRejectedVal(e.target.innerText);
                  setPaginationPageVal("1");
                }}
              >
                Rejected
              </p>
              <p
                className={
                  allHiredRejectedVal === "In Progress"
                    ? "referredRejectText1"
                    : "referredRejectText"
                }
                id="0"
                onClick={(e: any) => {
                  setSearchByStatusValue(e.target.id);
                  setAllHiredRejectedVal(e.target.innerText);
                  setPaginationPageVal("1");
                }}
              >
                In Progress
              </p>
            </div>
          </div>
          <div className="addreferenceMainDiv referred-header-container">
            <span>
              <input
                className="searchByCandidateInput"
                type={"text"}
                placeholder={"Search By Candidate Details"}
                onChange={(e: any) => { setSearchAnyValue(e.target.value); setPaginationPageVal("1") }}
              />
              <button>
                <FiSearch />
              </button>
            </span>
          </div>
        </div>
        <div className="user-dashboard-table">
          <table className="referredUsersTable">
            <thead className="referredUsersTableThead">
              <tr>
                <th className="referredUsersTableFirstTh">Candidate</th>
                <th className="referredUsersTableSecoundTh">Status</th>
                <th className="referredUsersTableThirdtTh">Amount Earned</th>
              </tr>
            </thead>

            {yourReferralsData?.data?.length > 0 ? (
              <tbody className="referredUsersTableTbody">
                {loaderState ? (
                  <Space className="tableSpiner" size="middle">
                    <Spin size="large" />
                  </Space>
                ) : (
                  
                  yourReferralsData?.data?.map((ele: any) => {
                    return (
                      <tr
                        onMouseOver={(e: any) => {
                          setNotesIconValue(ele);
                        }}
                      >
                        <td className="referredUsersTableTbodyFirstTd">
                          <p
                            className="referredUsersTableTbodyFirstTdPTag"
                            onClick={() => {
                              setRefModalOpen(true);
                              setEditCandidateData(ele);
                            }}
                          >
                            {ele?.fullName}
                            {
                            ele === notesIconValue ?  <FiEdit/> : ""
                            }
                          </p>
                        </td>
                        <td className="statusTd">
                          {ele?.hiringStatus}{" "}
                          {/* {ele?.note === null || ele?.note === "" ? (
                            ""
                            // icone Data Show
                          ) : (
                           ""
                           // icone Data Show
                          )
                          } */}
                          <>
                            {ele === notesIconValue ? (
                              <MdSpeakerNotes
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setNoteModal(true);
                                  setGetCandidateNoteData(ele);
                                }}
                              />
                            ) : null}
                          </>
                        </td>
                        {ele?.referralAmount != false &&
                          ele?.referralAmount != null ? (
                          <td className="amountEarnedTd">
                            {" "}
                            ${ele?.referralAmount}{" "}
                            {ele?.isPaid ? (
                              <span className="paidStatus">Paid</span>
                            ) : (
                              <span className="unPaidStatus">UnPaid</span>
                            )}{" "}
                          </td>
                        ) : (
                          <td>--</td>
                        )}
                      </tr>
                    );
                  })
                )}

              </tbody>
            ) : (
              <tbody className="referredUsersTableTbody">
                <div className="noDataFoundDiv">
                  <p>
                    <BiErrorAlt className="noDataIcon" />
                  </p>
                  <p className="noDataText">No Data Found</p>
                </div>

                {/* <tr className="testOnly">
                  <td>
                    <BiErrorAlt />
                  </td>
                  <td>No Data Found</td>
                  <td></td>
                </tr> */}
              </tbody>
            )}
          </table>
        </div>
        <UserDashboardPagination
          yourReferralsData={yourReferralsData}
          setPaginationPageVal={setPaginationPageVal}
        />

        <NotePopUp
          setNoteModal={setNoteModal}
          noteModal={noteModal}
          getCandidateNoteData={getCandidateNoteData}
        />

        <EditUserModal
          editCandidateData={editCandidateData}
          setEditCandidateData={setEditCandidateData}
          setRefModalOpen={setRefModalOpen}
          refModalOpen={refModalOpen}
        />
      </div>
    </>
  );
};

export default ReferredUsers;
