import {  createSlice } from '@reduxjs/toolkit';


export interface LoginState {
  user:any
}

const UnparsedData:any = localStorage.getItem('Ws_Referral_App') 
const isUser = JSON.parse(UnparsedData || null)

const initialState: LoginState = {
  user: isUser || {}
};


export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    userLoginAction: (state:any, action:any) => {
      localStorage.setItem('Ws_Referral_App', JSON.stringify(action.payload))
      state.user = action.payload
    },
    userLogOut: (state:any) => {
      localStorage.clear()
      state.user = {}
    }
  },
});

export const { userLoginAction, userLogOut} = loginSlice.actions;


export default loginSlice.reducer;
