import React from 'react'
import '../../style/notePopUp.css'
import { Modal } from 'antd';
import { MdOutlineCancel } from 'react-icons/md';


const NotePopUp = ({ setNoteModal, noteModal, getCandidateNoteData }: any) => {
    return (
        <>
            <Modal
                className='note-modal-section'
                width={700}
                open={noteModal}
                onOk={() => setNoteModal(false)}
                onCancel={() => setNoteModal(false)}
                footer={null}
            >
                <div className='note-modal-all-details' style={{ height: "350px" }} >
                    <div className='note-modal-header' >
                        <h5>Notes</h5>
                        <p className='note-popUp-cancel'><MdOutlineCancel onClick={()=>setNoteModal(false)}/></p>
                    </div>
                    <div className='all-not-data' >
                        <p>{getCandidateNoteData?.hiringStatus === "In Progress" ? "At this stage of the process, 'In Progress' means that we're actively evaluating the candidate's qualifications and experience to see if there's a suitable position available for them. If we've already evaluated them and determined that there isn't a current fit, we still appreciate your referral and want you to know that we're giving it our full attention. In either case, we're committed to keeping you informed of any updates or changes in the candidate's status. Thank you for your contribution to our referral program." : getCandidateNoteData?.note}</p>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default NotePopUp
