import "../../style/candidate.css";
import { Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAdminCandidatesPagination } from "../../redux/candidateSlice";

const CandidatePagination = () => {

  const dispatch = useDispatch()
  const candidatesData = useSelector((state: any) => state?.candidate)

  const handleCandidatesPage = (e: any, page: number) => {
    e.preventDefault();
    dispatch(setAdminCandidatesPagination(page));
  }

  return (
    <div className="candidate-pagination-container">
      <div className="candidate-pagination-container-text">
        <p>Showing {candidatesData?.candidatesList?.length} of{" "}
          {candidatesData?.candidatesPagination?.totalRecords} entries</p>
      </div>

      <Pagination
        className="candidate-pagination"
        count={candidatesData?.candidatesPagination?.totalPage}
        page={Number(candidatesData?.candidatesPagination?.currentPage)}
        shape="rounded"
        showFirstButton
        showLastButton
        color="primary"
        onChange={handleCandidatesPage}

      />

    </div>
  );
};

export default CandidatePagination;
