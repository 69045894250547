import React from "react";
// import { useEffect, useRef } from "react";
import { MdOutlineCancel } from "react-icons/md";
import "../../style/RequirementOverview.css";
import { Space, Spin } from "antd";

const RequirementOverview = ({
  referJOb,
  setReferJOb,
  showReferJobModal,
  requirementLoading,
  setRequirementOverviewValue,
  requirementOverviewAllData,
}: any) => {

  //  outside false

  // const wrapperRef: any = useRef(null);
  // useEffect(() => {
  //   document.addEventListener("click", handleCancel, false);
  //   return () => {
  //     document.removeEventListener("click", handleCancel, false);
  //   };
  // }, []);

  // const handleCancel = (event: any) => {
  //   if (wrapperRef?.current && !wrapperRef?.current.contains(event?.target)) {
  //     console.log("click")
  //     if (event.target.className.includes("requirement-main-box")) {
  //       setRequirementOverviewValue(false);
  //     } else {
  //       console.log("event>>>", event.target.className);
  //     }
  //   }
  // };
  
  return (
    <>
      <div className="requirement-main-box ">
        <div className="required-box animate__animated animate__fadeIn">
          {requirementLoading ? (
            <Space size="middle" className="required-loader">
              <Spin size="large" />
            </Space>
          ) : (
            <>
              <div
                className="job-title "
                // ref={wrapperRef}
              >
                <p className="jobCodeText">
                  {" "}
                  {requirementOverviewAllData?.jpcCode}
                </p>
                <span>
                  {" "}
                  <b> - {requirementOverviewAllData?.title}</b>
                  <p className="jobStatusReq">
                    {requirementOverviewAllData?.projectType} -{" "}
                    {requirementOverviewAllData?.jobType},{" "}
                    {requirementOverviewAllData?.state}
                  </p>
                </span>
              </div>
              <div className="bold-words-and-refer-click">
                <div className="all-bold-title">
                  <div className="bold-duration-title">
                    <b> Duration - </b> {requirementOverviewAllData?.duration}
                  </div>
                  <div className="bold-required-title">
                    <b> Required Skills - </b>{" "}
                    {requirementOverviewAllData?.requiredSkills}
                  </div>
                  <div
                    className="bold-detailed-title"
                    style={{ width: "100%" , textOverflow: "ellipsis",overflow: "hidden"}}
                  >
                    {/* <span> Detailed JD-</span>
                    <textarea   value= {requirementOverviewAllData?.detailedJd} disabled></textarea> */}
                    {/* {
                      requirementOverviewAllData?.detailedJd
                    } */}
                    <b> Detailed JD -</b>
                    <span style={{whiteSpace:"pre-wrap"}}>
                      {requirementOverviewAllData?.detailedJd}
                    </span>
                  </div>
                </div>
                <div className="refer-job-btn">
                  <button onClick={showReferJobModal}>
                    Refer for this job
                  </button>
                </div>
                <MdOutlineCancel
                  className="cancel-but"
                  onClick={() => setRequirementOverviewValue(false)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RequirementOverview;
