import {  createSlice } from '@reduxjs/toolkit';



export interface UserDashState {
    candidateTable:any,
    hotRequirementsData:any,
    rewardsData:any
}

const initialState: UserDashState = {
    candidateTable:[],
    hotRequirementsData:[],
    rewardsData:{}
};

export const userDashSlice = createSlice({
    name:'userDash',
    initialState,
    reducers:{
        setCandidateTableData : (state:any,action:any) =>{
            state.candidateTable = action.payload
        },
        setHotRequirements:(state:any,action:any)=>{
            state.hotRequirementsData = action.payload
        },
        setRewardsData: (state:any, action:any) => {
            state.rewardsData = action.payload
        }
    }

});

export const { setCandidateTableData, setHotRequirements, setRewardsData } = userDashSlice.actions;

export default userDashSlice.reducer;
