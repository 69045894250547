import { useState, useEffect } from "react";
import "../../style/Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import SkullsLogo from "../../assets/WiseskullsBlue.svg";
import demoImg from "../../assets/demoImg.jpg";
import { FaPowerOff } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Space, message } from "antd";
import Offcanvas from "react-bootstrap/Offcanvas";
import Avatar from "react-avatar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogOut } from "../../redux/login";
import ChangePasswordBtn from "./ChangePasswordBtn";
import ProfileSection from "./ProfileSection";
// import copy from "copy-to-clipboard";
import {
  totalEarnings,
  totalReferralsData,
} from "../../service/userDashboardService";
import { IoCopy } from "react-icons/io5";
import { FRONTEND_URL } from "../../service/http";
import { setRewardsData } from "../../redux/userDash";
import { getUserAPI } from "../../service/profileService";
import { setFileDetails } from "../../redux/profileSlice";

const Header = () => {
  const userRole: any = useSelector((state: any) => state.login.user);
  const userAvatar: any = useSelector(
    (state: any) => state.profile.userData?.avatar
  );
  const [profileView, setProfileView] = useState(true);
  const [referCode, setReferCode]: any = useState("Referral Code");
  const [finalCode, setFinalCode]: any = useState("");
  const avatar = userAvatar ? userAvatar : userRole.avatar;
  const userSince = new Date(userRole.createdAt).getFullYear();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showUserEarningHeader, setShowUserEarningHeader]: any = useState({});
  const [showUserReferralsCountHeader, setShowUserReferralsCountHeader]: any =
    useState({});
  const { rewardsData }: any = useSelector((state: any) => state.candidateData);
  const { userRefresh } = useSelector((state: any) => state.user);
  // console.log("rewardsData>>>>",rewardsData)

  const logOut = () => {
    dispatch(userLogOut());
  };

  useEffect(() => {
    let rewardData: any = { referral: "", earning: "" };

    if (userRole?.role === "1") {
      totalEarnings().then((res: any) => {
        setShowUserEarningHeader(res.data)
        rewardData = { ...rewardData, earning: res.data.totalReferralAmount }
        dispatch(setRewardsData(rewardData))
      });
      totalReferralsData().then((res: any) => {
        setShowUserReferralsCountHeader(res.data)
        rewardData = { ...rewardData, referral: res.data.totalReferralsCount }
        dispatch(setRewardsData(rewardData));
      });
      getUserAPI().then((response: any) => {
        dispatch(setFileDetails(response?.data))
        if (response.success) {
        }
      })
    }
  }, [userRefresh]);

  const expand = "md";

  const close: any = document.getElementsByClassName("btn-close");

  const popOpenWithProfile = () => {
    document.getElementById("basic-nav-dropdown")?.click();
  };

  const getCopy: any = async (e: any) => {
    const referralLink = `${FRONTEND_URL}login?refferalCode=${userRole?.inviteCode}`;
    const isCopied: any = await navigator?.clipboard?.writeText(referralLink);
    if (referralLink) {
      message.success("Copied");
      setFinalCode(e);
    } else {
      message.error("Error, While copy referral code.");
    }
  };

  return (
    <>
      <div className="main-navbar-in-referrals-page">
        <Navbar key={expand} bg="light" expand={expand} className="mb-3">
          <Container fluid>
            <Navbar.Brand>
              <img
                src={SkullsLogo}
                onClick={() => {
                  userRole?.role === "1"
                    ? navigate("/user-dashboard")
                    : navigate("/candidate");
                }}
                alt=""
                className="navbar-in-logo-img"
              />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              {profileView ? (
                <Offcanvas.Header className="offCanva" closeButton>
                  <Offcanvas.Title
                    id={`offcanvasNavbarLabel-expand-${expand}`}
                  ></Offcanvas.Title>
                  <div className="offcanva-in-profile">
                    <div
                      className="profile-details "
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div className="pi animate__animated animate__slideInLeft">
                        {" "}
                        <Avatar
                          round
                          size="60px"
                          src={avatar || demoImg}
                        ></Avatar>{" "}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              fontSize: 18,
                              fontFamily: "Helvetica",
                              fontWeight: "700",
                            }}
                          >
                            {userRole?.fullName}
                          </span>
                          <span
                            style={{
                              fontSize: 15,
                              color: "#B7B7B7",
                              fontFamily: "Helvetica",
                              fontWeight: "500",
                            }}
                          >
                            Member Since {userSince}
                          </span>
                        </div>
                      </div>

                      <div
                        className="pi animate__animated animate__slideInLeft"
                        style={{ gap: "35px", justifyContent: "flex-end" }}
                      >
                        <span>
                          {" "}
                          <b className="total-referrals-num">
                            {" "}
                            {
                              showUserReferralsCountHeader?.totalReferralsCount
                            }{" "}
                          </b>
                          <br />
                          <p>Total Referrals</p>
                        </span>
                        <span>
                          <b className="total-earnings-num">
                            {" "}
                            ${showUserEarningHeader?.totalReferralAmount}
                          </b>
                          <br />
                          <p>Earnings</p>
                        </span>
                      </div>

                      <button
                        onClick={() => setProfileView(false)}
                        className="profile-details-in-viev-but animate__animated animate__slideInLeft"
                      >
                        View Profile
                      </button>
                    </div>
                  </div>
                  <Offcanvas.Body
                    style={{ width: "100%", height: "65vh" }}
                    className="animate__animated animate__slideInLeft"
                  >
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        textAlign: "center",
                        height: "90%",
                      }}
                    >
                      <div className="">
                        <p
                          onClick={() => {
                            navigate("/ref-req");
                            close[0].click();
                          }}
                          style={{ fontWeight: 600, fontSize: 17 }}
                        >
                          Refer on requirements
                        </p>
                        <p
                          onClick={() => {
                            navigate("/user-dashboard");
                            close[0].click();
                          }}
                          style={{ fontWeight: 600, fontSize: 17 }}
                        >
                          Your Referrals
                        </p>
                      </div>
                      <div className="">
                        <hr className="logoutbut-befor-upperline" />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <button
                            onClick={logOut}
                            className="profile-details-in-logout-but"
                          >
                            <FaPowerOff style={{ fontSize: 18 }} />
                            Logout
                          </button>
                        </div>
                      </div>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas.Header>
              ) : (
                <Offcanvas.Header className="offCanva">
                  <span
                    className="profile-close "
                    onClick={() => setProfileView(true)}
                  >
                    <AiOutlineCloseCircle />
                  </span>
                  <Offcanvas.Title
                    id={`offcanvasNavbarLabel-expand-${expand}`}
                  ></Offcanvas.Title>
                  <ProfileSection userSince={userSince} showUserEarningHeader={showUserEarningHeader} showUserReferralsCountHeader={showUserReferralsCountHeader} />
                </Offcanvas.Header>
              )}
            </Navbar.Offcanvas>
            <Nav className="justify-content-end flex-grow-1 pe-3 d-none d-lg-block">
              <div className="profile-sec ">
                {/* <Nav.Link href="#action2"></Nav.Link> */}

                <div className="menu-in-header">
                  {userRole?.role === "0" ? (
                    <>
                      <Link
                        className={
                          location.pathname === "/candidate" ||
                            location.pathname === "/"
                            ? "candidate-but1"
                            : "candidate-but"
                        }
                        to={"/candidate"}
                        style={{cursor:'pointer'}}
                      >
                        Candidate
                      </Link>
                      <Link
                        className={
                          location.pathname === "/requirments"
                            ? "requirements-but1"
                            : "requirements-but"
                        }
                        to={"/requirments"}
                        style={{cursor:'pointer'}}
                      >
                        Requirements
                      </Link>
                      <Link
                        className={
                          location.pathname === "/referrals"
                            ? "referrals-but1"
                            : "referrals-but"
                        }
                        to={"/referrals"}
                        style={{cursor:'pointer'}}
                      >
                        Referrals
                      </Link>
                    </>
                  ) : null}
                </div>



                <Space className="profile-drop " size={16} wrap>
                  <div style={{ cursor: "pointer" }}>
                    <Avatar
                      onClick={() => popOpenWithProfile()}
                      round
                      size="45px"
                      src={avatar || demoImg}
                    ></Avatar>
                  </div>
                  <NavDropdown title="" id="basic-nav-dropdown" style={{cursor:'pointer'}}>
                    <div className="drop-down" >
                      <NavDropdown.Item className="main-navbar-in-profile-dropdown main">
                        {" "}
                        <Avatar
                          round
                          size="35px"
                          src={avatar || demoImg}
                        ></Avatar>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              fontSize: 12,
                              fontFamily: "Helvetica",
                              fontWeight: "700",
                            }}
                          >
                            {userRole.fullName}
                          </span>
                          <span
                            style={{
                              fontSize: 10,
                              color: "#B7B7B7",
                              fontFamily: "Helvetica",
                              fontWeight: "500",
                            }}
                          >
                            {userRole?.role === "0"
                              ? "Admin"
                              : userRole?.role === "2"
                                ? "Recruiter"
                                : `Member Since ${userSince}`}
                          </span>
                        </div>
                      </NavDropdown.Item>
                      {userRole?.role === "2" ? (
                        <NavDropdown.Item>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              justifyContent: "center",
                            }}
                          >
                            <span
                              style={{ fontSize: "13px", marginRight: "7px" }}
                            >
                              {referCode}
                            </span>
                            <IoCopy onClick={() => getCopy()} />
                          </div>
                        </NavDropdown.Item>
                      ) : null}
                      <NavDropdown.Item
                        style={{
                          justifyContent: "center",
                          borderBottom:
                            userRole?.role === "1" ? "1px solid #b1abab7a" : "",
                        }}
                      >
                        {userRole?.role === "0" ? (
                          <ChangePasswordBtn logOut={logOut} />
                        ) : userRole?.role === "1" ? (
                          <button
                            onClick={() => navigate("/profile")}
                            className="navbar-in-profile-vive-but"
                          >
                            View Profile
                          </button>
                        ) : null}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ display: "block" }}>
                        {userRole?.role === "0" ? (
                          <Link to={"/user"}>
                            <div className="view-user" style={{cursor:'pointer'}}>
                              <hr style={{ color: "silver" }} />
                              View Users
                              <hr style={{ color: "silver" }} />
                            </div>
                          </Link>
                        ) : userRole?.role === "2" ? null : null}
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item>
                        <button
                          onClick={logOut}
                          className="navbar-in-profile-Logout-but"
                        >
                          <FaPowerOff />
                          LogOut
                        </button>
                      </NavDropdown.Item>
                    </div>
                  </NavDropdown>
                </Space>
              </div>
            </Nav>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default Header;

/*
 
 
*/
