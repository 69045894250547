import React, { useRef } from "react";
import "../../style/addUser.css";
import { useState } from "react";
import { Button, Modal } from "antd";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { addUserData } from "../../service/userService";
import { useDispatch } from "react-redux";
import { userRefresh } from "../../redux/userSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { MdOutlineCancel } from "react-icons/md";

const AddUser = () => {
  const [addUserModal, setAddUserModal]: any = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const [addUserErrors, setaddUserErrors]: any = useState({});
  const [userFieldData, setUserFieldData]: any = useState({
    fullName: "",
    email: "",
    phone: "",
    password: "",
    role: "",
  });
  const dispatch = useDispatch();
  const formRef: any = useRef(null);
  const showAddUserModal = () => {
    setAddUserModal(true);
  };

  const clearFields = () => {
    setUserFieldData({
      fullName: "",
      email: "",
      phone: "",
      password: "",
      role: "",
    });
  };

  const handleUserModalOk = () => {
    setAddUserModal(false);
  };

  const handleUserModalCancel = () => {
    setAddUserModal(false);
  };

  const handleUserSubmit = (e: any) => {
    e.preventDefault();
    setSubmitLoader(true)
    formRef.current.reportValidity();
    if (formRef.current.reportValidity()) {
      addUserData(userFieldData)
        .then((res: any) => {
          if (res.success) {
            dispatch(userRefresh());
            setAddUserModal(false);
            setSubmitLoader(false)

            clearFields();
          } else {
            setaddUserErrors(res);
            setSubmitLoader(false)

          }
        })
        .catch(({ data }: any) => {
          setaddUserErrors(data?.errors[0]);
          setSubmitLoader(false)

        });
    } else {
      setSubmitLoader(false)

    }
  };


  return (
    <>
      <div className="add-user-click-btn">
        <button onClick={showAddUserModal}> Add User </button>
      </div>

      <Modal
        className="user-modal-pop"
        open={addUserModal}
        // onOk={handleUserModalOk}
        // onCancel={handleUserModalCancel}
        afterClose={() => clearFields()}
        footer={null}
        closable={false}
      >
        <div className="add-user-all-details" style={{ height: "550px" }}>
          <div className="user-header-section">
            <p> Add Recruiter</p>
            <p className="new-reference-header-cancel">
              <MdOutlineCancel onClick={() => setAddUserModal(false)} />
              </p>
          </div>
          <div className="all-user-input-section" style={{ padding: "20px" }}>
            <div
              className="user-id-name-email-section"
              style={{ marginLeft: "50px" }}
            >
              <Box
                className="user-text-input-section"
                component="form"
                sx={{ "& > :not(style)": { m: 0, width: "350px" } }}
                noValidate
                autoComplete="off"
                ref={formRef}
              >
                <TextField
                  value={userFieldData.fullName}
                  error={addUserErrors?.message?.includes("fullName")}
                  helperText={
                    addUserErrors?.message?.includes("fullName") &&
                    "Incorrect entry."
                  }
                  required
                  className="user-add-required-all-details"
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                  style={{ marginTop: "20px" }}
                  onChange={(e: any) =>
                    setUserFieldData({
                      ...userFieldData,
                      fullName: e.target.value,
                    })
                  }
                />
                <TextField
                  value={userFieldData.email}
                  error={addUserErrors?.message?.includes("Email")}
                  helperText={
                    addUserErrors?.message?.includes("Email") &&
                    `${addUserErrors?.message}`
                  }
                  required
                  className="user-add-required-all-details"
                  type="email"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  style={{ marginTop: "25px" }}
                  onChange={(e: any) =>
                    setUserFieldData({
                      ...userFieldData,
                      email: e.target.value,
                    })
                  }
                />
                <TextField
                  value={userFieldData.phone}
                  required
                  className="user-add-required-all-details"
                  id="outlined-basic"
                  label="Phone"
                  variant="outlined"
                  style={{ marginTop: "20px" }}
                  onChange={(e: any) =>
                    setUserFieldData({
                      ...userFieldData,
                      phone: e.target.value.replace(/\D/g, ""),
                    })
                  }
                />
                <TextField
                  value={userFieldData.password}
                  required
                  className="user-add-required-all-details"
                  type={"password"}
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  style={{ marginTop: "20px" }}
                  onChange={(e: any) =>
                    setUserFieldData({
                      ...userFieldData,
                      password: e.target.value,
                    })
                  }
                />
                <FormControl
                  fullWidth
                  style={{ marginTop: "20px" }}
                >
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    required
                    className="user-add-required-all-details"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userFieldData?.role}
                    label="Role"
                    onChange={(e: any) =>
                      setUserFieldData({
                        ...userFieldData,
                        role: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={2}>Recruiter</MenuItem>
                    <MenuItem value={0}>Admin</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
          <div className="add-user-submit-btn">
            <Button loading={submitLoader} onClick={(e: any) => handleUserSubmit(e)}> Submit </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddUser;
