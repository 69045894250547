import { message } from "antd";
import axios from "axios";

const API_URL = "https://refer-api.wiseskulls.com" ; // Production
// const API_URL = "https://ws-referral-program-2epfc.ondigitalocean.app" ; // Developement
// const API_URL = "http://192.168.1.186:8080" ; // Mehmud
// const API_URL = "http://192.168.1.176:8080"; // Akram

const FRONTEND_URL = "https://refer.wiseskulls.com/";

const getHeader = () => {
  let user: any = localStorage.getItem("Ws_Referral_App");

  user = user ? JSON.parse(user).token : null;

  if (user) {
    return {
      "Content-Type": "application/json",
      authorization: user,
    };
  }
};

export default class Http {
  static get(url: string, msg: boolean) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${API_URL}/${url}`,
        headers: getHeader(),
      })
        .then(function (response: any) {
          if (response.data && response.data.success) {
            response.data.message &&
              msg &&
              message.success(response.data.message, 0.6);
            resolve(response.data);
          } else {
            message.success(response.data.message, 0.6);
            reject(response.data);
          }
        })
        .catch(function (error: any) {
          // alert(error);
          //
          if (error.response.status === 404) {
            //
            message.error(error.response.data.message);
          } else if (error.response.status === 429) {
            message.error(error.response.data, 5);
          } else if (error.response.status === 401) {
            localStorage.clear();
            window.location.pathname = "/";
            message.error("Logged Out");
          } else {
            message.error("Server Error");
          }
          if (
            error.response.data.message === "Invalid or expired token provided!"
          ) {
            localStorage.clear();
            window.location.pathname = "/";
          }
          reject(error.response.data);
        });
    });
  }

  static post(url: string, body: any, msg: boolean) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${API_URL}/${url}`,
        data: body,
        headers: getHeader(),
      })
        .then(function (response: any) {
          if (response.data && response.data.success) {
            response.data.message &&
              msg &&
              message.success(response.data.message, 1.5);
            resolve(response.data);
          } else {
            message.error(response.data.message, 1.5);
            resolve(response.data);
          }
        })
        .catch(function (error: any) {
          error.response.data.message &&
            msg &&
            message.error(error.response.data.message);
          if (error.response.status === 429) {
            message.error(error.response.data, 5);
          }
          if (url !== "user/login") {
            setTimeout(() => {
              if (error.response.status === 401) {
                localStorage.clear();
                window.location.pathname = "/";
              }
            }, 2000);
          }
          reject(error.response);
        });
    });
  }

  static put(url: string, body: any, msg: boolean) {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        url: `${API_URL}/${url}`,
        data: body,
        headers: getHeader(),
      })
        .then(function (response: any) {
          if (response.data && response.data.success) {
            response.data.message &&
              msg &&
              message.success(response.data.message, 1);
            resolve(response.data);
          } else {
            response.data.message && message.error(response.data.message);
            reject(response.data);
          }
        })
        .catch(function (error: any) {
          // alert(error);
          if (error.response.status === 429) {
            message.error(error.response.data, 5);
          }

          if (error.response.status === 401) {
            localStorage.clear();
            window.location.pathname = "/";
          }
          //
          reject(error.response.data);
        });
    });
  }

  static delete(url: string, msg: boolean) {
    return new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: `${API_URL}/${url}`,
        headers: getHeader(),
      })
        .then(function (response: any) {
          if (response.data && response.data.success) {
            response.data.message &&
              msg &&
              message.success(response.data.message, 1);
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function (error: any) {
          // alert(error);
          if (error.response.status === 429) {
            message.error(error.response.data, 5);
          }
          if (error.response.status === 401) {
            error.response.message && message.error(error.response.message);
            localStorage.clear();
            window.location.pathname = "/";
          }
          //
          reject(error.response.data);
        });
    });
  }
}

export { API_URL, FRONTEND_URL };
