import React from "react";
import "../../style/requirments.css";
import { Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAdminRequirmentsPagination } from "../../redux/adminRequirmentsSlice";

const RequirmentsPagination = () => {
  const dispatch = useDispatch();
  const requirementPaginationData = useSelector(
    (state: any) => state?.adminRequirments
  );

  const handleRequirementsPage = (e: any, page: number) => {
    e.preventDefault();
    dispatch(setAdminRequirmentsPagination(page));
  };


  return (
    <div className="requirments-pagination-container">
      <div className="requirments-pagination-text">
        <p>
          Showing {requirementPaginationData?.requirmentsList?.data?.length} of{" "}
          {requirementPaginationData?.requirmentsPagination?.totalRecords} entries
        </p>
      </div>
      <Pagination
        className="requirments-pagination"
        count={requirementPaginationData?.requirmentsPagination?.totalPage}
        shape="rounded"
        showFirstButton
        showLastButton
        color="primary"
        page={Number(requirementPaginationData?.requirmentsPagination?.currentPage)}
        onChange={handleRequirementsPage}
      />
    </div>
  );
};

export default RequirmentsPagination;
