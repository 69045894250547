import axios from "axios";
import { DropzoneArea } from "material-ui-dropzone";
import { useEffect, useState } from "react";
import { IoPersonSharp } from "react-icons/io5";
import { Button, message } from "antd";
import { API_URL } from "../../service/http";
import { userLogOut } from "../../redux/login";
import { useDispatch } from "react-redux";



const AvatarPage = ({ profileUserData }: any) => {
  const [userImage, setUserImage]: any = useState({});
  const [loadAvatar, setLoadAvatar] = useState(false);
  const [imageHas404, setImageHas404] = useState(false);
  const dispatch = useDispatch()

  const uploadImage = () => {
    if (!userImage) return
    setLoadAvatar(true)
    let user: any = localStorage.getItem("Ws_Referral_App");
    user = user ? JSON.parse(user).token : null;
    let formData = new FormData();
    formData.append("avatar", userImage);

    axios({
      method: "put",
      url: `${API_URL}/user/avatar-update`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: user,
      },
    }).then((ress: any) => {
      if (ress?.data?.success) {
        setLoadAvatar(false)
        setUserImage({})
        message.success("Avatar Uploaded Successfully...");
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      } else {
        message.error("Image not upload...");
        setLoadAvatar(false)
      }
    }).catch((err: any) => {
      if (err.response.status === 401) {
        setLoadAvatar(false)
        message.error("Please login again...");
        setTimeout(() => {
          dispatch(userLogOut());
        }, 2000)
      }
    })
  };

  useEffect(() => {
    if (!profileUserData?.avatar) return

    axios.get(profileUserData?.avatar).then(() => {
      setImageHas404(false)
    }).catch(() => {
      setImageHas404(true)
    })

  }, [profileUserData?.avatar])
  
  return (
    <>
      <div>
        <div className="avatar-main-section">
          <div className="avatar-notice-head">
            <p>Update a JPG or PNG Image</p>
          </div>
          <DropzoneArea
            initialFiles={[!imageHas404 ? profileUserData?.avatar : null]}
            acceptedFiles={["image/*"]}
            dropzoneText={"Drag and drop Image or browse for file instead"}
            onChange={(files) => {
              setUserImage(files[0]);
            }}
            showPreviews={true}
            showPreviewsInDropzone={false}
            filesLimit={1}
            maxFileSize={1000000}
            getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
              return ` ${rejectedFile.size} File is too big. Size limit is ${maxFileSize} Megabyte`;
            }}
          />

          <div className="profile-avatar-ic-border">
            {!userImage?.path && <IoPersonSharp className="profile-avatar-ic" />}
          </div>
        </div>
        <Button loading={loadAvatar} disabled={!userImage?.path ? true : false} className={loadAvatar || !userImage?.path ? `update-avatar-btn1` : `update-avatar-btn`} onClick={uploadImage}>
          Update
        </Button>
      </div>
    </>
  );
};

export default AvatarPage;
