import Http from "./http";

export const adminRequirmentListApi = async (page: number, perPage: number) => {
  return await Http.get(
    `admin/requirement/list?page=${page}&perPage=${perPage}`,
    false
  );
};

export const adminRequirmentSearchApi = async (data: any) => {
  return await Http.post(`admin/requirement/search`, data, false);
};

export const adminAddRequirementApi = async (data: any) => {
  return await Http.post(`admin/requirement/add`, data, true);
};

export const adminEditRequirementApi = async (data: any) => {
  return await Http.put(`admin/requirement/update`, data, true);
};

export const adminDeleteRequirementApi = async (id: any) => {
  return await Http.delete(`admin/requirement/delete/${id}`, false);
};
