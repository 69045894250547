
import { FiSearch } from "react-icons/fi";
import "../../style/requirments.css";
import AddRequirementBtn from "./AddRequirementBtn";
import { setRequirmentPerPaginationrefresh } from "../../redux/adminRequirmentsSlice";
import { useDispatch } from "react-redux";

const RequirmentsHeader = ({ setSearchData, searchData }: any) => {

  const dispatch = useDispatch()

  return (
    <div className="requirments-header-container">
      <span>
        <input
          type={"text"}
          placeholder={"Search Anything"}
          value={searchData}
          onChange={(e: any) => {
            dispatch(setRequirmentPerPaginationrefresh({ page: 1, perPage: 10 }));
            setSearchData(e.target.value);
          }
          }
        />
        <button>
          <FiSearch />
        </button>
      </span>
      <AddRequirementBtn />
    </div>
  );
};

export default RequirmentsHeader;
