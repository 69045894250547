import { createSlice } from "@reduxjs/toolkit";

export interface AdminrequirmentsState {
  requirmentsList: any;
  requirmentsPagination: any;
  requirmentSearchData: any;
  requirmentRefresh: boolean;
  page: number;
  perPage: number;
}

const initialState: AdminrequirmentsState = {
  requirmentsList: [],
  requirmentsPagination: {},
  requirmentSearchData: [],
  requirmentRefresh: false,
  page: 1,
  perPage: 10,
};

export const adminRequirmentsSlice = createSlice({
  name: "adminRequirments",
  initialState,
  reducers: {
    setAdminRequirmentsList: (state, action) => {
      state.requirmentsList = action?.payload?.data;
      state.requirmentsPagination = action?.payload?.data?.pagination;
    },
    setAdminRequirmentsSearchData: (state, action) => {
      state.requirmentsList = action.payload.data;
      state.requirmentsPagination = action.payload.data.pagination;
      
    },setRequirmentPerPaginationrefresh:(state:any,action)=> {
      state.page=action.payload.page
      state.perPage=action.payload.perPage

    },

    setAdminRequirmentsRefresh: (state) => {
      state.requirmentRefresh = !state.requirmentRefresh;
    },
    setAdminRequirmentsPagination: (state, action) => {
      state.page = action.payload;
      
    },
  },
});

export const {
  setAdminRequirmentsList,
  setAdminRequirmentsSearchData,
  setAdminRequirmentsRefresh,
  setAdminRequirmentsPagination,
  setRequirmentPerPaginationrefresh
} = adminRequirmentsSlice.actions;

export default adminRequirmentsSlice.reducer;
