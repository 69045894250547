import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useState, useRef } from "react";
import { Button, message } from "antd";
import { changePasswordAPI } from "../../service/profileService";
import Box from "@mui/material/Box";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

const PasswordPage = () => {
  const [userChangePassword, setUserChangePassword]:any = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [updatePasswordLoad, setUpdatePasswordLoad] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formRef: any = useRef(null);

  const isPasswordValid = () => {
    if (userChangePassword?.confirmPassword.length < 8) {
      return false;
    } else {
      return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/.test(
        userChangePassword?.confirmPassword
      );
    }
  };

  const handlePassword = async (e: any) => {
    formRef.current.reportValidity();
    if (userChangePassword.oldPassword === userChangePassword.newPassword) {
    } else if (isPasswordValid()) {
      if (
        formRef.current.reportValidity() &&
        userChangePassword?.newPassword === userChangePassword?.confirmPassword
      ) {
        e.preventDefault();
        setUpdatePasswordLoad(true);
        await changePasswordAPI(userChangePassword)
          .then((response: any) => {
            if (response.success) {
              setUpdatePasswordLoad(false);
              message.success("Password Change Successfully...");
              setUserChangePassword({
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              })
            } else {
              setUpdatePasswordLoad(false);
            }
          })
          .catch((error: any) => {
            setUpdatePasswordLoad(false);
          });
      }
    }
  };
  return (
    <>
      <div>
        <div className="password-main-section">
          <div>
            <p>
              Your password must be at least eight characters long and should
              contain both letters and numbers.
            </p>
            <p>
              We strongly encourage you to use a brand new password and NOT one
              used on another website account.
            </p>
          </div>

          <div>
            <Box
              className="password-section-group"
              component="form"
              noValidate
              autoComplete="off"
              ref={formRef}
            >
              <FormControl
                sx={{ width: "42ch" }}
                variant="outlined"
                className="single-input-class"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Current Password
                </InputLabel>
                <OutlinedInput
                  style={{ paddingBottom: "1px" }}
                  className="single-input-class"
                  id="outlined-adornment-password"
                  type={showOldPassword ? "text" : "password"}
                  onChange={(e: any) =>
                    setUserChangePassword({
                      ...userChangePassword,
                      oldPassword: e.target.value,
                    })
                  }
                  autoComplete="new-password"
                  required
                  value={userChangePassword.oldPassword}
                  error={
                    userChangePassword.oldPassword ===
                      userChangePassword.newPassword &&
                    userChangePassword.oldPassword !== ""
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowOldPassword((show) => !show)}
                        edge="end"
                      >
                        {showOldPassword ? (
                          <MdVisibilityOff />
                        ) : (
                          <MdVisibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <FormControl
                sx={{ width: "42ch" }}
                className="single-input-class"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  New Password
                </InputLabel>
                <OutlinedInput
                  style={{ paddingBottom: "1px" }}
                  id="outlined-adornment-password"
                  type={showNewPassword ? "text" : "password"}
                  autoComplete="new-password"
                  required
                  value={userChangePassword.newPassword}
                  error={
                    userChangePassword.oldPassword ===
                      userChangePassword.newPassword &&
                    userChangePassword.oldPassword !== ""
                  }
                  onChange={(e: any) =>
                    setUserChangePassword({
                      ...userChangePassword,
                      newPassword: e.target.value,
                    })
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword((show) => !show)}
                        edge="end"
                      >
                        {showNewPassword ? (
                          <MdVisibilityOff />
                        ) : (
                          <MdVisibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {userChangePassword.oldPassword ===
                  userChangePassword.newPassword &&
                userChangePassword.oldPassword !== "" ? (
                  <FormHelperText error id="accountId-error">
                    {"same password*"}
                  </FormHelperText>
                ) : null}
              </FormControl>

              <FormControl
                sx={{ width: "42ch" }}
                variant="outlined"
                className="single-input-class"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  style={{ paddingBottom: "1px" }}
                  className="single-input-class"
                  id="outlined-adornment-password"
                  type={showConfirmPassword ? "text" : "password"}
                  autoComplete="new-password"
                  required
                  error={
                    userChangePassword.confirmPassword !==
                      userChangePassword.newPassword &&
                    userChangePassword.confirmPassword !== ""
                  }
                  value={userChangePassword.confirmPassword}
                  onChange={(e: any) =>
                    setUserChangePassword({
                      ...userChangePassword,
                      confirmPassword: e.target.value,
                    })
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword((show) => !show)}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <MdVisibilityOff />
                        ) : (
                          <MdVisibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {userChangePassword.confirmPassword !==
                  userChangePassword.newPassword &&
                userChangePassword.confirmPassword !== "" ? (
                  <FormHelperText error id="accountId-error">
                    {"password not same*"}
                  </FormHelperText>
                ) : null}
                {userChangePassword.confirmPassword !== "" &&
                !isPasswordValid() ? (
                  <FormHelperText error id="accountId-error">
                    {
                      "password must be 8 characters long and should contain both letters and numbers."
                    }
                  </FormHelperText>
                ) : null}
              </FormControl>

              <Button
                loading={updatePasswordLoad}
                className="update-password-btn"
                onClick={handlePassword}
              >
                Update Password
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordPage;
