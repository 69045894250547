import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHotRequirements } from "../../redux/userDash";
import { GrClose } from "react-icons/gr";
import { hotRequirementsApi, RequirementOverviewApi, } from "../../service/userDashboardService";
import { useNavigate } from "react-router";
import moment from "moment";

const ReferOnReq = () => {
  const reqData: any = useSelector((state: any) => state.candidateData) || [];  
  const [selectedView, setSelectedView] = useState(false);
  const [selectedReq, setSelectedReq] = useState({
    id: "",
    duration: "",
    projectType:"",
    jobType: "",
    jpcCode: "",
    title: "",
  });
  const [selectedReqData, setSelectedReqData]: any = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    hotRequirementsApi({searchAny:""}).then((res: any) => {
      dispatch(setHotRequirements(res?.data));
    });
  }, []);


  const RequirementOverviewFun = (ele: any) => {
    RequirementOverviewApi(ele).then((res: any) => {
      setSelectedReqData(res?.data);
    });
  };

  return (
    <div className="dash-head d-md-none">
      <p style={{fontWeight:"700"}} >Refer on requirements</p>
      <hr />
      {selectedView ? (
        <div className="req-over animate__animated animate__fadeInUp">
          <div className="req-div" style={{marginTop:"-1rem"}}>
            <div
              className="requirement"
              style={{
                backgroundColor: "#EFEFEF",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                height: "120px",
                padding:"10px",
                alignItems: "center",
              }}
            >
              <div className="text">
                <p>
                  {selectedReq.jpcCode}- {selectedReq.title}
                </p>
                <span style={{marginBottom: "0.5rem"}} >{selectedReq.projectType}-{selectedReq.jobType}</span>
                <p style={{textAlign:"start"}} >Duration- {selectedReq.duration}</p>
              </div>
              <button
                onClick={() =>
                  navigate("/refer-req", {
                    state: { id: selectedReq.id, jpcCode: selectedReq.jpcCode, title: selectedReq.title },
                  })
                }
                className="add-ref-btn"
              >
                Add Reference
              </button>
              <span
                onClick={() => setSelectedView(false)}
                className="close-btn"
              >
                <GrClose />
              </span>
            </div>
            <div className="hr-div">
              <hr style={{width:"100%", marginTop:"0px"}} />
            </div>
          </div>
          <div className="req-view">
            Required Skills - {selectedReqData?.requiredSkills}
            <br />
            {selectedReqData?.detailedJd}
          </div>
        </div>
      ) : (
        <div className="req-div">
          {reqData?.hotRequirementsData?.map((e: any) => {
            return (
              <div
                onClick={() => {
                  setSelectedReq(e);
                  RequirementOverviewFun(e.id);
                  setSelectedView(true);
                }}
                className="requirement"
              >
                <p>
                  {e.jpcCode}- {e.title}
                </p>
                <span>{e.projectType}-{e.jobType}</span>
                <p>Duration- {e.duration}</p>
                <div>
                  <p style={{fontSize:"15px", color:"#959595", fontWeight:"500"}}>
                     Requirement created date  -  <span style={{color:"#0E4D92"}} >{ moment(e?.createdAt).format('LL') }</span>
                  </p>
                </div>
                <hr className="hr" />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ReferOnReq;
