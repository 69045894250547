import { useMemo } from "react";
import "../../style/requirments.css";
import { useTable } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { message, Popconfirm, Spin, } from "antd";
import { setAdminRequirmentsRefresh } from "../../redux/adminRequirmentsSlice";
import { adminDeleteRequirementApi } from "../../service/requirmentsService";
import EditRequirement from "./EditRequirement";
import { BiErrorAlt } from "react-icons/bi";


const RequirmentsTable = ({isLoading}:any) => {
  const dispatch = useDispatch();

  const columns: any = useMemo(
    () => [
      {
        Header: "Jpc Code",
        accessor: "jpcCode", // accessor is the "key" in the data
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Total References",
        accessor: "candidateCount", // accessor is the "key" in the data
      },
      {
        Header: "",
        accessor: "action", // accessor is the "key" in the data
      },
    ],
    []
  );
  const requirementList = useSelector((state: any) =>state?.adminRequirments?.requirmentsList?.data);
const data = useMemo(()=>requirementList ||[],[requirementList]);
  const confirm = () => {
    message.success("Requirement Deleted");
  };

  const HandleDelete = (id: any) => {
    adminDeleteRequirementApi(id).then((res: any) => {
      if (res.success) {
        confirm();
        dispatch(setAdminRequirmentsRefresh());
      }
    });
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data,});
    

  return (
    <div className="requirments-table-container">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        {isLoading ?
        <div className="candidateTableSpiner">
          <Spin tip="Loading" size="large" />
        </div> :
       requirementList?.length>0 ?  <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell:any) => {
                  if (cell.column.id === "action") {
                    return (
                      <td {...cell.getCellProps()}>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "60px",
                          }}
                          className="requirment-del-span"
                        >
                          <EditRequirement data={cell.row.original}/>
                          <Popconfirm
                            title="Delete Requirement?"
                            description="Are you sure to delete this Requirement?"
                            onConfirm={() => HandleDelete(cell.row.original.id)}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                          >
                            <button>Delete</button>
                          </Popconfirm>
                        </span>
                      </td>
                    );
                  }
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>:
        <tbody>
        <div className="candidate-nodata-box">
              <p>
                <BiErrorAlt className="nodata-icon" />
              </p>
              <p className="nodata-text">No Data Found</p>
            </div>
      </tbody>
        }
      </table>
    </div>
  );
};

export default RequirmentsTable;
