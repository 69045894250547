import React, { useRef, useEffect } from "react";
import "../../style/addUser.css";
import { useState } from "react";
import {Button, Modal } from "antd";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { updateUserData } from "../../service/userService";
import { useDispatch } from "react-redux";
import { userRefresh } from "../../redux/userSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { MdOutlineCancel } from "react-icons/md";

const EditUser = ({ editData }: any) => {
  const [editUserModal, setEditUserModal] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const [userFieldData, setUserFieldData] = useState({
    id: "",
    fullName: "",
    email: "",
    phone: "",
    role: "",
    password: ""
  });

  useEffect(() => {
    setUserFieldData({
      id: editData?.id,
      fullName: editData?.fullName,
      email: editData?.email,
      phone: editData?.phone,
      role: editData.role,
      password: ""
    })

  }, [editData])


  const dispatch = useDispatch();

  const editformRef: any = useRef(null);

  const clearFields = () => {
    setUserFieldData({
      id: "",
      fullName: "",
      email: "",
      phone: "",
      role: "",
      password: ""
    });
  };

  const handleUserSubmit = (e: any) => {
    e.preventDefault();
    setSubmitLoader(true)
    editformRef.current.reportValidity();
    if (editformRef.current.reportValidity()) {
      updateUserData(userFieldData).then((res: any) => {
        if (res.success) {
          dispatch(userRefresh());
          setEditUserModal(false);
          setSubmitLoader(false)

          clearFields();
        }
      });
    }else {
      setSubmitLoader(false)

    }
  };

  return (
    <>
      <span style={{ color: "#265F9D" }} onClick={() => setEditUserModal(true)}>
        Edit
      </span>
      <Modal
        className="user-modal-pop"
        open={editUserModal}
        onOk={() => setEditUserModal(false)}
        // onCancel={() => setEditUserModal(false)}
        footer={null}
        closable={false}
      >
        <div className="add-user-all-details" style={{ height: "550px" }}>
          <div className="user-header-section">
            <p>Edit Recruiter</p>
            <p className="new-reference-header-cancel">
              <MdOutlineCancel onClick={() => setEditUserModal(false)} />
            </p>
          </div>
          <div className="all-user-input-section" style={{ padding: "20px" }}>
            <div
              className="user-id-name-email-section"
              style={{ marginLeft: "50px" }}
            >
              <Box
                className="user-text-input-section"
                component="form"
                sx={{ "& > :not(style)": { m: 0, width: "350px" } }}
                noValidate
                autoComplete="off"
                ref={editformRef}
              >
                <TextField
                  value={userFieldData.fullName}
                  required
                  className="edit-required-all-sec"
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                  style={{ marginTop: "20px" }}
                  onChange={(e: any) =>
                    setUserFieldData({
                      ...userFieldData,
                      fullName: e.target.value,
                    })
                  }
                />
                <TextField
                  value={userFieldData.email}
                  required
                  className="edit-required-all-sec"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  style={{ marginTop: "25px" }}
                  onChange={(e: any) =>
                    setUserFieldData({
                      ...userFieldData,
                      email: e.target.value,
                    })
                  }
                />
                <TextField
                  value={userFieldData.phone}
                  required
                  className="edit-required-all-sec"
                  id="outlined-basic"
                  label="Phone"
                  variant="outlined"
                  style={{ marginTop: "20px" }}
                  onChange={(e: any) =>
                    setUserFieldData({
                      ...userFieldData,
                      phone: e.target.value.replace(/\D/g, ''),
                    })
                  }
                />
                <TextField
                  value={userFieldData.password}
                  required
                  className="edit-required-all-sec"
                  id="outlined-basic"
                  label="Password"
                  type={"password"}
                  variant="outlined"
                  style={{ marginTop: "20px" }}
                  onChange={(e: any) =>
                    setUserFieldData({
                      ...userFieldData,
                      password: e.target.value,
                    })
                  }
                />
                <FormControl fullWidth className="" style={{ marginTop: "20px" }}>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userFieldData.role}
                    label="Role"
                    required
                    onChange={(e: any) =>
                      setUserFieldData({
                        ...userFieldData,
                        role: e.target.value,
                      })
                    }
                  >
                    {/* <MenuItem value={1}>Referrer</MenuItem> */}
                    <MenuItem value={2}>Recruiter</MenuItem>
                    <MenuItem value={0}>Admin</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
          <div className="add-user-submit-btn">
            <Button loading={submitLoader} onClick={(e: any) => handleUserSubmit(e)}> Update </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditUser;
