import { Box, CircularProgress, TextField } from "@mui/material";
import { useRef, useState } from "react";
import Http from "../../service/http";
import SentOtpCom from "./SentOtpCom";
import { IoIosArrowBack } from "react-icons/io";

const ForgotModel = ({ setForgotModel }: any) => {
  const [loaderIcon, setLoaderIcon]: any = useState(false);
  const [email, setEmail]: any = useState("");
  const [otpModel, setOtpModel]: any = useState(false);
  const formRef: any = useRef(null);



  const changeModel = async (e: any) => {
    e.preventDefault();
    formRef.current.reportValidity();
    if (formRef.current.reportValidity() && email !== "") {
      setLoaderIcon(true);
      if (formRef?.current?.reportValidity) {
        const body: any = {
          emailOrPhone: email,
        }
        Http.post("user/forgotPasswordMobileOtp", body, true).then((result:any) => {
          if (result.success) {
            setOtpModel(true);
            setInterval(() => {
              setLoaderIcon(false);
            }, 1000)
          } else {
            setLoaderIcon(false);
          }
        }).catch(() => {
          setLoaderIcon(false);
        })
      }
    }
  }

  return (
    <>
      {/* send OTP section start*/}
      <div className="send-otp-holder">
        {otpModel ? <SentOtpCom email={email} /> : <Box
          component="form"
          sx={{ "& > :not(style)": { m: 1.5, width: "87%" } }}
          noValidate
          autoComplete="off"
          ref={formRef}
        >
          <TextField
            required
            className="animate__animated animate__fadeInDown"
            id="outlined-basic"
            label="Email or Phone Number"
            variant="outlined"
            type={"text"}
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <button className="animate__animated animate__fadeInDown" type='submit' onClick={(e: any) => changeModel(e)} style={{ background: loaderIcon ? "#7cb7af" : "#0e9280" }}> {loaderIcon ?
            <CircularProgress
              style={{
                color: "white",
                height: "18px",
                width: "18px",
                position: "relative",
                left: "0px",
                top: "2px",
              }}
            /> : null}Send OTP</button>

          <button onClick={() => setForgotModel(false)} style={{ width: '15%', height: '15px', fontSize: '15px', marginRight: '14rem', backgroundColor: "transparent", color: '#265f9d', display: 'flex', justifyContent: '' }}><IoIosArrowBack style={{ fontSize: '20px', marginTop: '1px' }} /> Back</button>

        </Box>}
      </div>
      {/* <ResetPassword/> */}
      {/* send OTP section end*/}

    </>
  );
};

export default ForgotModel;
