import Http from "./http"


export const userLogin = async(data:any) => {

  return await Http.post(`user/login`,data,true)
    
}

export const userRegister = async(data:any) => {

  return await Http.post(`user/register`,data,true)
    
}