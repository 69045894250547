import { useState, useRef, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import "../../style/candidate.css";
import { TiArrowSortedDown } from "react-icons/ti";
import ChangeStatusHired from "./ChangeStatusHired";
import { useDispatch, useSelector } from "react-redux";
import {
  setAdminCandidatesRefresh,
  setPerPaginationrefresh,
} from "../../redux/candidateSlice";
import { adminCandidateStatusUpdate } from "../../service/candidateService";
import { message } from "antd";
import Http from "../../service/http";

const CandidateHeader = ({
  setcandidateHiringStatus,
  candidateHiringStatus,
  adminCandidateData,
  setAdminCandidateData,
  candiDateIdSend,
  setIsLoading
}: any) => {
  const [dropDownShow, setdropDownShow]: any = useState(false);
  const [changeHiredModalOpen, setChangeHiredModalOpen] = useState(false);
  const wrapperRef: any = useRef(null);
  const wrapperRef2: any = useRef(null);
  const [isChangeLeadDropDown, setIsChangeLeadDropDown]: any = useState(false);

  const dispatch = useDispatch();

  const isUser = useSelector((state: any) => state?.login?.user)


  const handleCandidateSearch = (e: any) => {
    dispatch(setPerPaginationrefresh({ page: 1, perPage: 10 }));

    setAdminCandidateData({
      ...adminCandidateData,
      searchAny: e.target.value,
    });
  };

  const handleInProccess = (e: any) => {
    if (candidateHiringStatus?.candidateIds?.length > 0) {
      e.preventDefault();
      adminCandidateStatusUpdate({
        ...candidateHiringStatus,
        hiringStatus: e.target.id,
      })
        .then((response: any) => {
          if (response.success) {
            setChangeHiredModalOpen(false);
            setdropDownShow(false);
            setcandidateHiringStatus({
              candidateIds: [],
              hiringStatus: "",
              note: "",
            });
            dispatch(setAdminCandidatesRefresh());
          } else {

          }
        })
        .catch((err: any) => {
          message.error(err.errors[0].msg);
        });
    } else {
      message.error("Please select at least one candidate", 1)

    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside2, false);
    return () => {
      document.removeEventListener("click", handleClickOutside2, false);
    };
  });

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setChangeHiredModalOpen(false);
      if (
        event.target.className === "status-btn" ||
        event.target.className === "status-span-btn" ||
        event.target.className.baseVal === "status-span-btn-icon"
      ) {
      } else {
        setdropDownShow(false);
      }
    }
  };

  const handleClickOutside2 = (event: any) => {
    if (wrapperRef2.current && !wrapperRef2.current.contains(event.target)) {
      if (
        event.target.className === "lead-btn" ||
        event.target.className === "lead-span-btn" ||
        event.target.className.baseVal === "lead-span-btn-icon"
      ) {
      } else {
        setIsChangeLeadDropDown(false);
      }
    }
  };
  const chnageLead: any = async (e: any) => {
    if (candiDateIdSend?.length > 0) {
      const body: any = {
        candidateIds: candiDateIdSend,
        leadStatus: e,
      }

      await Http.put("admin/candidate/changeCandidateLeadStatus", body, true).then((res: any) => {
        setIsLoading(true)
        if (res.success) {
          dispatch(setAdminCandidatesRefresh());
          setIsLoading(false)

        } else {
          setIsLoading(false)
        }
      }).catch((err: any) => {
        message.error(err?.errors[0]?.msg);
        setIsLoading(false)
      })
    } else {
      message.error("Please select at least one candidate", 1)
    }
  }


  return (
    <>
      <div className="candidate-header-container">
        <span>
          <input
            type={"text"}
            placeholder={"Search Anything"}
            value={adminCandidateData?.searchAny}
            onChange={handleCandidateSearch}
          />
          <button>
            <FiSearch />
          </button>
        </span>
        <div className="candidate-header-btn-box">
          <button
            className="lead-btn"
            onClick={() =>{
              if(candiDateIdSend?.length >0){
               setIsChangeLeadDropDown(!isChangeLeadDropDown)
              }else {
                message.error("Please select at least one candidate", 1)

              }
              }}
          >
            <span className="lead-span-btn">Change Lead Status</span>
            <TiArrowSortedDown
              className="lead-span-btn-icon"
              style={{ fontSize: "20px" }}
            />
            {isChangeLeadDropDown && (
              <div className="candidate-dropdown-container" ref={wrapperRef2}>
                <button onClick={() => { chnageLead(0) }}>New Lead</button>
                <button onClick={() => { chnageLead(1) }}>Added to Database</button>
                <button onClick={() => { chnageLead(2) }}>Details Gathering</button>
              </div>
            )}
          </button>
          {isUser?.role === "0" && <button
            className="status-btn"
            onClick={() => {
             if(candidateHiringStatus?.candidateIds?.length > 0){
              setdropDownShow(!dropDownShow);
            }else {
          message.error("Please select at least one candidate", 1)
     }
            }}
          >
            <span className="status-span-btn">Change Hiring Status</span>
            <TiArrowSortedDown
              className="status-span-btn-icon"
              style={{ fontSize: "20px" }}
            />
            {dropDownShow ? (
              <div className="candidate-dropdown-container" ref={wrapperRef}>
                <button
                  onClick={() => {
                    if (candidateHiringStatus?.candidateIds?.length > 0) {
                      setChangeHiredModalOpen(true);
                      setcandidateHiringStatus({
                        ...candidateHiringStatus,
                        hiringStatus: "1",
                      });
                    } else {
                      message.error("Please select at least one candidate", 1)
                    }
                  }}
                >
                  Hired
                </button>
                <button
                  onClick={() => {
                    if (candidateHiringStatus?.candidateIds?.length > 0) {
                      setChangeHiredModalOpen(true);
                      setcandidateHiringStatus({
                        ...candidateHiringStatus,
                        hiringStatus: "2",
                      });
                    } else {
                      message.error("Please select at least one candidate", 1)
                    }
                  }}
                >
                  Rejected
                </button>
                <button onClick={handleInProccess} id="0">
                  In Process
                </button>
                <button onClick={handleInProccess} id="3">
                  Closed
                </button>
              </div>
            ) : null}
          </button>}
        </div>
      </div>
      <ChangeStatusHired
        changeHiredModalOpen={changeHiredModalOpen}
        setChangeHiredModalOpen={setChangeHiredModalOpen}
        candidateHiringStatus={candidateHiringStatus}
        setcandidateHiringStatus={setcandidateHiringStatus}
        setIsLoading={setIsLoading}
      />
    </>
  );
};

export default CandidateHeader;
