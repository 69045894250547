import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCandidateTableData } from "../../redux/userDash";
import { getUserRequirementData } from "../../service/userDashboardService";
import { isMobile } from "react-device-detect";
import { Space, Spin } from "antd";

const MobileUserDashboard = () => {
  const [loaderState, setLoaderState] = useState(false);
  const [searchByStatusValue, setSearchByStatusValue]: any = useState(null);
  const [searchAnyValue, setSearchAnyValue] = useState("");
  const candiData: any = useSelector((state: any) => state.candidateData) || [];
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (searchByStatusValue !== null || searchAnyValue !== "") {
      handleCandidateList();
    } else if (isMobile) {
      handleCandidateList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchAnyValue, searchByStatusValue]);

  const handleCandidateList = async () => {
    const searchData = {
      searchAny: searchAnyValue,
      searchByStatus: searchByStatusValue,
      page: 1,
      perPage: 10,
    };
    setLoaderState(true);
    await getUserRequirementData(searchData)
      .then((res: any) => {
        if (res.success) {
          setLoaderState(false);
          dispatch(setCandidateTableData(res?.data?.data));
        }
      })
      .catch((err: any) => {
        setLoaderState(false);
      });
  };

  return (
    <>
      <div className="dash-head d-md-none">
        <p>Your Referrals & their Status</p>
        <div className="head-search">
          <div className="candidate-header-container" style={{ width: "70%" }}>
            <span>
              <input
                value={searchAnyValue}
                style={{
                  width: "100%",
                  height: "30px",
                  marginBottom: "4px",
                  fontFamily: "'Poppins', sans-serif",
                }}
                type={"text"}
                placeholder={"Search Anything"}
                onChange={(e: any) => {
                  setSearchAnyValue(e.target.value);
                }}
              />
              <button style={{ height: "30px" }}>
                <FiSearch />
              </button>
            </span>
          </div>
          <button
            onClick={() => navigate("/add-ref-mob")}
            className="add-ref-btn"
          >
            Add Reference
          </button>
        </div>
      </div>
      <div className="dash-table">
        <table className="table">
          <thead>
            <tr>
              <th style={{ paddingLeft: "10%" }} scope="col">
                Referral Name
              </th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {!loaderState ? (
              <>
                {" "}
                {candiData?.candidateTable?.length > 0 ? (
                  candiData?.candidateTable?.map((e: any) => {
                    return (
                      <tr>
                        <td style={{ paddingLeft: "10%" }}>{e.fullName}</td>
                        <td>{e.hiringStatus}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      width={"100%"}
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        height: "50vh",
                        position: "relative",
                        top: "50%",
                        left: "0%",
                      }}
                      colSpan={3}
                    >
                      No Data Found
                    </td>
                  </tr>
                )}
              </>
            ) : (
              <Space size="middle" className="hotRequirementLoader">
                <Spin size="large" />
              </Space>
            )}
          </tbody>
        </table>
      </div>
      <div className="foot-nav">
        <span
          className={searchByStatusValue === null || searchByStatusValue ===  "" ? "active" : "inActive"}
          onClick={() => setSearchByStatusValue("")}
        >
          ALL
        </span>
        <span
          className={searchByStatusValue === "1" ? "active" : "inActive"}
          onClick={() => setSearchByStatusValue("1")}
        >
          Hired
        </span>
        <span
          className={searchByStatusValue === "2" ? "active" : "inActive"}
          onClick={() => setSearchByStatusValue("2")}
          style={{ border: "none" }}
        >
          Rejected
        </span>
      </div>
    </>
  );
};

export default MobileUserDashboard;
