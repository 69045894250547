import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { Button } from 'antd';
import { useRef, useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa';
import { changeEmailAPI, emailChangeOtpAPI } from '../../service/profileService';
import { useDispatch } from 'react-redux';
import { message } from 'antd'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { userLogOut } from '../../redux/login';



const EmailPage = ({ profileUserData }: any) => {

    const [showPassword, setShowPassword] = useState(false);
    const [showOtpSection, setShowOtpSection] = useState(false);
    const [showConfirmSection, setShowConfirmSection] = useState(false)
    const [changeEmail, setChangeEmail] = useState({ email: '', currentPassword: '', otp: "" })
    const [loading, setLoading] = useState(false)
    const [confarmLoading, setConfarmLoading] = useState(false)
    const formRef: any = useRef(null);
    const dispatch = useDispatch()

    const handleUserEmail = async () => {
        formRef.current.reportValidity();
        if (formRef.current.reportValidity()) {
            setLoading(true)
            if (profileUserData?.email === changeEmail?.email) {
                setLoading(false);
            } else {
                await emailChangeOtpAPI(changeEmail).then((res: any) => {
                    if (res.success) {
                        setShowOtpSection(true);
                        setLoading(false);

                    } else {
                        setLoading(false);
                    }
                }).catch((err: any) => {
                    setLoading(false);
                });
            }
        }
    }
    const handleOtp = async () => {
        formRef.current.reportValidity();
        if (changeEmail.otp === '') {
            formRef.current.reportValidity();
        } else {
            setConfarmLoading(true)
            const { email, otp } = changeEmail
            await changeEmailAPI({ newEmail: email, otp: otp }).then((res: any) => {
                if (res.success) {
                    message.success("Email Changed Successfully Please Login Again...");
                    setShowConfirmSection(true);
                    setConfarmLoading(false);
                    setShowOtpSection(false);
                    dispatch(userLogOut());
                } else {
                    setConfarmLoading(false)
                }
            }).catch((err: any) => {
                setConfarmLoading(false)
            })
        }
    }

    return (
        <>
            <div>
                <div className='email-main-section'>
                    <div className='email-sec-paragraphs'>
                        <p>Want to change your email address? No problem. Just enter your new address and password below and click the "Change Email" button
                        </p>
                        <p>
                            We'll send a confirmation email to you right away. When it arrives, you must click the link inside to confirm it's really you making this request. (Just a little precaution!)
                        </p>
                        <p>
                            If we don't hear from you, we'll suspend your account for your protection.
                        </p>
                    </div>
                    <div className='current-email-address-p-tag'>
                        <p>Current Email Address</p>
                        <h4>{profileUserData.email}</h4>
                    </div>
                    <Box className='email-and-otp-section'
                        component="form"
                        noValidate
                        autoComplete="off"
                        ref={formRef}
                    >
                        <div className='email-change-group'>
                            <label>New Email Address</label>
                            <TextField className='single-input-class'
                                disabled={showOtpSection}
                                id="outlined-uncontrolled"
                                label="Email ID"
                                type={'email'}
                                required
                                error={profileUserData?.email === changeEmail?.email}
                                helperText={profileUserData?.email === changeEmail?.email && changeEmail?.email !== '' ? 'same email' : null}
                                onChange={(e: any) => setChangeEmail({ ...changeEmail, email: e.target.value })}
                            />
                            <FormControl sx={{ width: '43.1ch' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    style={{ paddingBottom: '1px' }}
                                    disabled={showOtpSection}
                                    className='single-input-class'
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={(e: any) => setChangeEmail({ ...changeEmail, currentPassword: e.target.value })}
                                    autoComplete='new-password'
                                    required
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword((show) => !show)}
                                                edge="end"
                                            >
                                                {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>

                            <div className='change-email-div'>
                                <Button
                                    loading={loading}
                                    disabled={loading || showOtpSection}
                                    className={loading || showOtpSection ? `email-sec-change-email-but1` : `email-sec-change-email-but`}
                                    onClick={handleUserEmail}>Change Email
                                </Button>
                            </div>
                        </div>

                        {showConfirmSection ?
                            <div className='email-changed-succ-section'>
                                <FaCheckCircle className='succ-check-icon' />
                                <p style={{ textAlign: 'center' }}>Email Changed <br /> Successfully</p>
                            </div>
                            : ''}
                        {showOtpSection ?
                            <div className='otp-main-section'>
                                <div className='otp-inp-and-conf-btn'>
                                    <input type="text" placeholder='Enter OTP' className='enter-otp-input' onChange={(e: any) => setChangeEmail({ ...changeEmail, otp: e.target.value.replace(/\D/g,'')})} />
                                    <div>
                                        <Button loading={confarmLoading} disabled={confarmLoading} className='otp-confirm-btn' onClick={() => { handleOtp() }} >Confirm</Button>
                                    </div>
                                </div>
                                <p>
                                    OTP sent on Register Mobile Number
                                </p>
                            </div>
                            : ''
                        }
                    </Box>
                </div>
            </div >
        </>
    )
}

export default EmailPage