import Http from "./http";

export const getReferralList = async (data: any) => {
  return await Http.post(`admin/referrer/getReferrersList`, data, false);
};

export const getReferrarsDetails = async (id: any) => {
  return await Http.get(`admin/referrer/getReferrerDetails/${id}`, false);
};

export const getReferrsActiveApi = async (data:any) => {
      return await Http.post(`admin/referrer/deleteReferrer`,data , false) 
}

export const getReferralNoteApi = async (data:any) => {
     return await Http.post(`admin/referrer/addReferrerNote`,data, true)
}
