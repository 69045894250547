import Http from "./http";

export const adminCandidateListApi = async (data: any) => {
  return await Http.post(`admin/candidate/search`, data, false);
};

export const adminCandidateStatusUpdate = async (data: any) => {
  return await Http.put(`admin/candidate/candidateHiringStatus`, data, true);
};


export const adminCandidatePaymentStatusUpdate = async (data: any) => {
  return await Http.put(`admin/candidate/changeCandidatePaymentStatus`, data, false);
};
