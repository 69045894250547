import 'react-tabs/style/react-tabs.css';
import '../style/Profile.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState } from 'react';
import { ImArrowLeft2 } from 'react-icons/im';
import { Link } from 'react-router-dom';
import ProfilePage from '../component/profileComp/ProfilePage';
import EmailPage from '../component/profileComp/EmailPage';
import PasswordPage from '../component/profileComp/PasswordPage';
import AvatarPage from '../component/profileComp/AvatarPage';
import BankDetailsPage from '../component/profileComp/BankDetailsPage';
import { useSelector } from 'react-redux';
import ContactUs from '../component/profileComp/ContactUs';





const Profile = () => {
  const [profileLoader, setProfileLoader] = useState(false)
  const user: any = useSelector(
    (state: any) => state.profile.userData
  );



  return (
    <div className='profile-main-section'>
      <div>
        <Link to={'/user-dashboard'}>
          <ImArrowLeft2 className='profile-left-icon' />
        </Link>
      </div>
      <Tabs className={'profile'}>
        <TabList>
          <Tab>Profile</Tab>
          <Tab>E-mail</Tab>
          <Tab>Password</Tab>
          <Tab>Avatar</Tab>
          <Tab>Bank Details</Tab>
          <Tab>Contact Us</Tab>
        </TabList>

        <TabPanel>
          <ProfilePage profileUserData={user} profileLoader={profileLoader} setProfileLoader={setProfileLoader} />
        </TabPanel>

        <TabPanel>
          <EmailPage profileUserData={user} />
        </TabPanel>

        <TabPanel>
          <PasswordPage />
        </TabPanel>

        <TabPanel>
          <AvatarPage profileUserData={user} />
        </TabPanel>

        <TabPanel>
          <BankDetailsPage />
        </TabPanel>

        <TabPanel>
          <ContactUs />
        </TabPanel>

      </Tabs>
    </div>
  )
}

export default Profile