import { useState, useEffect, useRef } from "react";
import { Modal } from "antd";
import "../../style/addRefMobile.css";
import Box from "@mui/material/Box";
import MaskBtm from "../../assets/Group 995.png";
import TextField from "@mui/material/TextField";
import { newReferenceApi } from "../../service/userDashboardService";
import { useNavigate } from "react-router";
// import { State, City } from "country-state-city";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Http from "../../service/http";

const AddRefMobile = () => {
  const [refModalOpen, setRefModalOpen] = useState(false);
  const [stateOfUsa, setStateOfUsa]: any = useState([]);
  const [cityOfStates, setCityOfStates]: any = useState([]);
  const formRef: any = useRef(null);
  const navigate = useNavigate();

  const [addReferenceErrors, setAddReferenceErrors]: any = useState([]);

  const [showRefrenceval, setShowRefrenceval] = useState({
    fullName: "",
    requirementId: "",
    title: "",
    email: "",
    phone: "",
    linkedInProfile: "",
    state: "",
    city: "",
  });

  // const showReferenceModal = () => {
  //   setRefModalOpen(true);

  // };

  const addReferenceSubmit = async (e: any) => {
    e.preventDefault();
    formRef.current.reportValidity();
    if (formRef.current.reportValidity()) {
      await newReferenceApi(showRefrenceval)
        .then((ele: any) => {
          if (ele?.success === true) {
            setRefModalOpen(false);
            navigate("/user-dashboard");
          }
        })
        .catch(({ data }) => {
          const errors = data?.errors?.map(({ param }: any) => param);

          setAddReferenceErrors(errors);
        });
    }
  };

  useEffect(() => {
    getUsaState();
  }, []);

  const getUsaState = async () => {
    const result: any = await Http.get("common/usStates", false);
    if (result.success) {
      setStateOfUsa(result?.data);
    }
  };

  const usaState: any = stateOfUsa?.map((e: any) => {
    return { label: e?.stateName, id: e?.stateId };
  });

  const usaCity: any = cityOfStates?.map((e: any) => {
    return { label: e };
  });

  const manageState = async (data: any) => {
    const result: any = await Http.get(`common/usCities/${data?.id}`, false);
    if (result?.success) {
      if (result?.data?.length !== 0) {
        setCityOfStates(result?.data);
        setShowRefrenceval({
          ...showRefrenceval,
          state: data?.label,
          city: "",
        });
      } else {
        setCityOfStates([data?.label]);
        setShowRefrenceval({
          ...showRefrenceval,
          state: data?.label,
          city: data?.label,
        });
      }
    }
  };

  return (
    <>
      <div className="form-div form-add-ref-div  d-md-none">
        <p className="para animate__animated animate__fadeInDown">
          Fill out the form to add new Reference
        </p>
        <div className="ref-form" style={{ width: "100%" }}>
          <Box
            className="textfield-input-name"
            component="form"
            sx={{ "& > :not(style)": { m: 0, width: "230px" } }}
            noValidate
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            autoComplete="off"
            ref={formRef}
          >
            <TextField
              required
              className="animate__animated animate__fadeInDown"
              style={{ width: "70%", margin: "13px 0px" }}
              error={addReferenceErrors?.includes("fullName")}
              helperText={
                addReferenceErrors?.includes("fullName") && "Incorrect entry."
              }
              value={showRefrenceval.fullName}
              id="fullName"
              type="text"
              label="Full Name"
              variant="outlined"
              onChange={(e: any) =>
                setShowRefrenceval({
                  ...showRefrenceval,
                  fullName: e.target.value,
                })
              }
            />
            <TextField
              required
              className="animate__animated animate__fadeInDown"
              style={{ width: "70%", margin: "13px 0px" }}
              error={addReferenceErrors?.includes("title")}
              helperText={
                addReferenceErrors?.includes("title") && "Incorrect entry."
              }
              value={showRefrenceval.title}
              id="title"
              type="text"
              label="Title"
              variant="outlined"
              onChange={(e: any) =>
                setShowRefrenceval({
                  ...showRefrenceval,
                  title: e.target.value,
                })
              }
            />
            <TextField
              required
              className="animate__animated animate__fadeInDown"
              style={{ width: "70%", margin: "13px 0px" }}
              error={addReferenceErrors?.includes("email")}
              helperText={
                addReferenceErrors?.includes("email") && "Incorrect entry."
              }
              value={showRefrenceval.email}
              id="email"
              type="email"
              label="Personal email"
              variant="outlined"
              onChange={(e: any) =>
                setShowRefrenceval({
                  ...showRefrenceval,
                  email: e.target.value,
                })
              }
            />
            <TextField
              required
              className="animate__animated animate__fadeInDown"
              style={{ width: "70%", margin: "13px 0px" }}
              error={addReferenceErrors?.includes("phone")}
              helperText={
                addReferenceErrors?.includes("phone") && "Incorrect entry."
              }
              value={showRefrenceval.phone}
              id="phone"
              type="number"
              label="Phone Number"
              variant="outlined"
              onChange={(e: any) =>
                setShowRefrenceval({
                  ...showRefrenceval,
                  phone: e.target.value,
                })
              }
            />
            <TextField
              required
              className="animate__animated animate__fadeInDown"
              style={{ width: "70%", margin: "13px 0px" }}
              error={addReferenceErrors?.includes("linkedInProfile")}
              helperText={
                addReferenceErrors?.includes("linkedInProfile") &&
                "Incorrect entry."
              }
              value={showRefrenceval.linkedInProfile}
              id="linkedInProfile"
              type="url"
              label="Linkedin ID"
              variant="outlined"
              onChange={(e: any) =>
                setShowRefrenceval({
                  ...showRefrenceval,
                  linkedInProfile: e.target.value,
                })
              }
            />
            <Autocomplete
              className="animate__animated animate__fadeInDown"
              disablePortal
              style={{ width: "70%", margin: "13px 0px" }}
              id="combo-box-demo"
              options={usaState}
              sx={{ width: 300 }}
              value={showRefrenceval.state}
              onChange={(e: any, data: any) => manageState(data)}
              renderInput={(params) => (
                <TextField
                  required
                  style={{ width: "100%", margin: "0px" }}
                  className="state-search-field"
                  {...params}
                  label="State"
                />
              )}
            />
            <Autocomplete
              className="animate__animated animate__fadeInDown"
              disablePortal
              style={{ width: "70%", margin: "13px 0px" }}
              disabled={
                showRefrenceval.city == showRefrenceval.state &&
                showRefrenceval.city.length > 0
                  ? true
                  : false
              }
              id="combo-box-demo"
              value={showRefrenceval.city}
              options={
                usaCity?.length > 0
                  ? usaCity
                  : [{ label: "Please select state first." }]
              }
              getOptionDisabled={(option: any) =>
                option?.label === "Please select state first."
              }
              sx={{ width: 300 }}
              onChange={(e: any, data: any) =>
                setShowRefrenceval({
                  ...showRefrenceval,
                  city: data?.label,
                })
              }
              renderInput={(params: any) => (
                <TextField
                  required
                  style={{ width: "100%", margin: "0px" }}
                  className="state-search-field"
                  {...params}
                  label="City"
                />
              )}
            />
          </Box>
        </div>
        <div className="reference-submit-btn">
          <button
            className="animate__animated animate__fadeInDown"
            type="submit"
            onClick={(e: any) => addReferenceSubmit(e)}
          >
            {" "}
            Submit{" "}
          </button>
        </div>
        <div className="img-div animate__animated animate__fadeInDown">
          <img src={MaskBtm} alt="" />
        </div>
      </div>
    </>
  );
};

export default AddRefMobile;
