import { MdOutlineEmail, MdPhoneInTalk } from 'react-icons/md'
import ContactImg from '../../assets/US_Contact_Icon.png'

const ContactUs = () => {
    return (
        <>
            <div className='contact-us-main-section'>
                <div className='contact-black-logo'>
                    <img src={ContactImg} style={{ height: '30px' }}/>
                </div>
                <p className='header-text-in-contact-sec'>Get in touch and let us know how we can help.</p>
                <div className="email-and-phone-sec">

                    <div className="email-section">
                        <span><MdOutlineEmail style={{ fontSize: '22px', marginRight: '15px' }} /></span>
                        <p className='email-para'>pauravn@wiseskulls.com</p>
                    </div>

                    <div className="number-section">
                        <span><MdPhoneInTalk style={{ fontSize: '28px', marginRight: '12px', paddingBottom: '6px' }} /></span>
                        <p className='phone-para'>+1 6692609707</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ContactUs