import styled from "@emotion/styled";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useDispatch } from "react-redux";
import { setAdminCandidatesRefresh } from "../../redux/candidateSlice";
import { adminCandidatePaymentStatusUpdate } from "../../service/candidateService";
import { Popconfirm } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const SwitchComp = ({ payMentStatus }: any) => {
  const dispatch = useDispatch();
  const handleStatusChange = () => {
    const data = {
      id: payMentStatus?.id,
      isPaid: !payMentStatus?.isPaid,
    };
    adminCandidatePaymentStatusUpdate(data)
      .then((res: any) => {
        if (res.success) {
          dispatch(setAdminCandidatesRefresh());
        }
      })
      .catch((err: any) => {});
  };

  const MaterialUISwitch = styled(Switch)(({ theme }: any) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('https://icons.veryicon.com/png/System/Small%20%26%20Flat/sign%20check.png')`,
          backgroundSize: "contain",
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme?.palette?.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme?.palette?.mode === "dark" ? "#003892" : "white",
      width: 25,
      height: 25,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('https://icon-library.com/images/close-icon-png/close-icon-png-19.jpg')`,
        backgroundSize: "contain",
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme?.palette?.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
      height: "80%",
      width: "80%",
    },
  }));

  const popDescription = (
    <span style={{ width: "fit-content" }}>
      Are you sure to change <b>payment status</b> of <b>{payMentStatus?.fullName} ?</b>
    </span>
  );
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Popconfirm
            title="Payment Status?"
            description={popDescription}
            onConfirm={() => handleStatusChange()}
            onCancel={() => {}}
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            okText="Yes"
            cancelText="No"
          >
            <MaterialUISwitch sx={{ m: 1 }} checked={payMentStatus?.isPaid} />
          </Popconfirm>
        }
        label=""
        onChange={() => {}}
      />
    </FormGroup>
  );
};

export default SwitchComp;
