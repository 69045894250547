import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RequirmentsHeader from '../component/requirementComp/RequirmentsHeader'
import RequirmentsPagination from '../component/requirementComp/RequirmentsPagination'
import RequirmentsTable from '../component/requirementComp/RequirmentsTable'
import { setAdminRequirmentsList, setAdminRequirmentsSearchData } from '../redux/adminRequirmentsSlice'
import { adminRequirmentListApi, adminRequirmentSearchApi } from '../service/requirmentsService'
import '../style/requirments.css'
import { setPerPaginationrefresh } from '../redux/candidateSlice'

const Requirments = () => {
  const [searchData, setSearchData]: any = useState("")
  const [isLoading, setIsLoading] = useState(false)




  const dispatch = useDispatch()
  const adminRequirements = useSelector((state: any) => state?.adminRequirments)




  useEffect(() => {
    dispatch(setPerPaginationrefresh({page:1 ,perPage:10}))
    if (searchData !== "") {
      const getRequiremnetSearchData = setTimeout(() => {
        setIsLoading(true)
        adminRequirmentSearchApi({ searchAny: searchData, page: adminRequirements?.page, perPage: adminRequirements?.perPage }).then((res: any) => {
          dispatch(setAdminRequirmentsSearchData(res));
          setIsLoading(false)
        });
      }, 1000);

      return () => clearTimeout(getRequiremnetSearchData);

    } else {
      setIsLoading(true)
      adminRequirmentListApi(adminRequirements?.page, adminRequirements?.perPage).then((res: any) => {
        dispatch(setAdminRequirmentsList(res))
        setIsLoading(false)
      }).catch((err: any) => {
        setIsLoading(false)

      })
    }

  }, [adminRequirmentListApi, adminRequirements?.requirmentRefresh, adminRequirements?.page, adminRequirements?.perPage, searchData])


  return (
    <div className='requirments-container'>
      <RequirmentsHeader setSearchData={setSearchData} searchData={searchData} />
      <RequirmentsTable isLoading={isLoading} />
      <RequirmentsPagination />
    </div>
  )
}

export default Requirments