import React from "react";
import { Pagination } from "@mui/material";
import "../../style/UserDashboardPagination.css";

const UserDashboardPagination = ({
  yourReferralsData,
  setPaginationPageVal,
}: any) => {

  const userDashPagination = (e: any, page: any) => {
    setPaginationPageVal(page);
  };
  return (
    <div className="userDashboard-pagination-container">
      <div className="userDashboard-pagination-container-text">
        <p>
          Showing {yourReferralsData?.pagination?.totalRecords >= 7 ? yourReferralsData?.pagination?.totalPerpage : yourReferralsData?.pagination?.totalRecords} of{" "}
          {yourReferralsData?.pagination?.totalRecords} entries
        </p>
      </div>
      <Pagination
        onChange={userDashPagination}
        className="userDashboard-pagination"
        page={Number(yourReferralsData?.pagination?.currentPage)}
        count={yourReferralsData?.pagination?.totalPage}
        shape="rounded"
        showFirstButton
        showLastButton
        color="primary"
      />
    </div>
  );
};

export default UserDashboardPagination;
