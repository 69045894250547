import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "antd";
import "../../style/EditUserModal.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  updateCandidateApi,
  usStateApi,
} from "../../service/userDashboardService";
import { usCityApi } from "../../service/userDashboardService";
import { useDispatch, useSelector } from "react-redux";
import { userRefresh } from "../../redux/userSlice";
import { MdOutlineCancel } from "react-icons/md";
import { Autocomplete } from "@mui/material";

const EditUserModal = ({
  setEditCandidateData,
  editCandidateData,
  setRefModalOpen,
  refModalOpen,
}: any) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [addReferenceErrors, setAddReferenceErrors]: any = useState([]);
  const [editUsStateVal, setEditUsStateVal]: any = useState([]);
  const [countryCityVal, setCountryCityVal]: any = useState([]);
  const [editstateId, setEditstateId] = useState("");
  const { hotRequirementsData }: any =
    useSelector((state: any) => state.candidateData) || [];
  const formRef: any = useRef(null);

  const dispatch = useDispatch();

  const editReferenceSubmit = async () => {
    formRef.current.reportValidity();
    const data = {
      ...editCandidateData,
      phone: editCandidateData.phone.replace(/\D/g, ""),
    };
    if (formRef.current.reportValidity()) {
      setSubmitLoader(true);

      await updateCandidateApi(data)
        .then((ele: any) => {
          if (ele?.success === true) {
            setRefModalOpen(false);
            setSubmitLoader(false);
            dispatch(userRefresh());
          } else {
            setSubmitLoader(false);
          }
        })
        .catch(({ data }) => {
          const errors = data?.errors?.map(({ param }: any) => param);
          setAddReferenceErrors(errors);
          setSubmitLoader(false);
        });
    }
  };

  useEffect(() => {
    if (refModalOpen) {
      setIsListLoading(true);
      usStateApi().then((ele: any) => {
        setIsListLoading(false);
        setEditUsStateVal(ele?.data);
      });
    }
  }, [refModalOpen]);

  useEffect(() => {
    editUsStateVal.filter((ele: any) => {
      if (ele.stateName === editCandidateData?.state) {
        setIsListLoading(true);

        return usCityApi(ele?.stateId).then((e: any) => {
          setIsListLoading(false);
          setCountryCityVal(e?.data);
        });
      }
    });
  }, [editUsStateVal]);

  const SelectedRequirement = () => {
    const reqText = hotRequirementsData.filter(
      (req: any) => editCandidateData?.requirementId === req.id
    );

    if (reqText.length > 0) {
      return reqText?.[0]?.jpcCode + "-" + reqText?.[0]?.title;
    } else {
      return editCandidateData?.Requirement == null
        ? ""
        : `${editCandidateData?.Requirement?.jpcCode} - ${editCandidateData?.Requirement?.title}`;
    }
  };

  return (
    <>
      <Modal
        className="reference-edit-details"
        width={780}
        open={refModalOpen}
        // onOk={() => setRefModalOpen(false)}
        // onCancel={() => setRefModalOpen(false)}
        footer={null}
      >
        <div
          className="new-reference-all-section"
          style={{ height: "max-content", paddingBottom: 25 }}
        >
          <div className="new-reference-header">
            <p> Edit Reference </p>
            <p className="reference-cancel-btn-sec">
              {" "}
              <MdOutlineCancel onClick={() => setRefModalOpen(false)} />{" "}
            </p>
          </div>
          <div className="all-input-name-section" style={{ padding: "20px" }}>
            <div className="name-and-title-part">
              <div className="name-section">
                <Box
                  className="textfield-input-name"
                  component="form"
                  sx={{ "& > :not(style)": { m: 0, width: "230px" } }}
                  noValidate
                  autoComplete="off"
                  ref={formRef}
                >
                  <TextField
                    required
                    value={editCandidateData?.fullName}
                    id="fullName"
                    type="text"
                    label="Full Name"
                    variant="outlined"
                    onChange={(e: any) =>
                      setEditCandidateData({
                        ...editCandidateData,
                        fullName: e.target.value,
                      })
                    }
                  />
                  <TextField
                    required
                    value={editCandidateData?.title}
                    id="title"
                    type="text"
                    label="Title"
                    variant="outlined"
                    onChange={(e: any) =>
                      setEditCandidateData({
                        ...editCandidateData,
                        title: e.target.value,
                      })
                    }
                  />
                  <TextField
                    required
                    value={editCandidateData?.email}
                    id="email"
                    type="email"
                    label="Personal email"
                    variant="outlined"
                    onChange={(e: any) =>
                      setEditCandidateData({
                        ...editCandidateData,
                        email: e.target.value,
                      })
                    }
                  />
                  <TextField
                    required
                    value={editCandidateData?.phone}
                    id="phone"
                    type="text"
                    label="Phone Number"
                    variant="outlined"
                    onChange={(e: any) => {
                      const cleaned = ("" + e.target.value).replace(/\D/g, "");
                      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                      if (match) {
                        setEditCandidateData({
                          ...editCandidateData,
                          phone:
                            "(" + match[1] + ") " + match[2] + "-" + match[3],
                        });
                      } else {
                        setEditCandidateData({
                          ...editCandidateData,
                          phone: e.target.value.replace(/\D{10}$/g, ""),
                        });
                      }
                    }}
                  />
                  <TextField
                    value={editCandidateData?.linkedInProfile}
                    id="linkedInProfile"
                    type="text"
                    label="Linkedin ID"
                    variant="outlined"
                    onChange={(e: any) =>
                      setEditCandidateData({
                        ...editCandidateData,
                        linkedInProfile: e.target.value,
                      })
                    }
                  />

                  <Autocomplete
                    aria-required
                    loading={isListLoading}
                    loadingText={"loading please wait....."}
                    className="form-autocomplete"
                    disablePortal
                    id="combo-box-demo"
                    value={editCandidateData?.state}
                    onChange={(e: any, value: any) => {
                      setEditCandidateData({
                        ...editCandidateData,
                        state: value?.label,
                        city: "",
                      });
                      if (value?.id !== undefined) {
                        usCityApi(value?.id).then((e: any) => {
                          if (e?.data.length == 0) {
                            setCountryCityVal([]);
                            setEditCandidateData({
                              ...editCandidateData,
                              state: value?.label,
                              city: value?.label,
                            });
                          } else {
                            setCountryCityVal(e?.data);
                          }
                        });
                      }
                    }}
                    options={editUsStateVal?.map((e: any) => {
                      if (e?.stateName !== editCandidateData?.state) {
                        return { label: e?.stateName, id: e?.stateId };
                      } else {
                        return { label: e?.stateName, id: e?.stateId };
                      }
                    })}
                    sx={{ width: 300 }}
                    renderInput={(params: any) => (
                      <TextField
                        required
                        className="auto-text"
                        {...params}
                        label="State"
                      />
                    )}
                  />
                  <Autocomplete
                    className="form-autocomplete"
                    loading={isListLoading}
                    loadingText={"loading please wait....."}
                    disablePortal
                    id="combo-box-demo"
                    disabled={editCandidateData.city == editCandidateData.state && editCandidateData?.city?.length > 0 ? true : false}

                    options={
                      editCandidateData?.state?.length > 0
                        ? countryCityVal?.map((e: any) => {
                            return { label: e };
                          })
                        : [{ label: "Please select state first" }]
                    }
                    getOptionDisabled={(option) =>
                      option?.label === "Please select state first"
                    }
                    onChange={(e: any, value: any) => {
                      setEditCandidateData({
                        ...editCandidateData,
                        city: value?.label,
                      });
                    }}
                    value={editCandidateData?.city}
                    sx={{ width: 300 }}
                    renderInput={(params: any) => (
                      <TextField
                        required
                        className="auto-text"
                        {...params}
                        label="City"
                      />
                    )}
                  />
                  <Autocomplete
                    className="form-autocomplete"
                    disablePortal
                    clearIcon={false}
                    id="combo-box-demo"
                    options={[
                      ...hotRequirementsData?.map((req: any) => {
                        return {
                          id: req.id,
                          label: req.jpcCode + "-" + req.title,
                        };
                      }),
                      {
                        id: 0,
                        label: "--Select--",
                      },
                    ]}
                    getOptionDisabled={(option: any) =>
                      option?.label === "Please select state first"
                    }
                    onChange={(e: any, value: any) => {
                      setEditCandidateData({
                        ...editCandidateData,
                        requirementId: value?.id,
                      });
                    }}
                    value={
                      editCandidateData?.requirementId === 0
                        ? "No requirement Opted"
                        : SelectedRequirement()
                    }
                    sx={{ width: 300 }}
                    renderInput={(params: any) => (
                      <TextField
                        className="auto-text"
                        {...params}
                        label="Requirement"
                      />
                    )}
                  />

                  {/* <Autocomplete
                    className="form-autocomplete"
                    disablePortal
                    clearIcon={false}
                    id="combo-box-demo"
                    options={[
                      ...hotRequirementsData?.map((req: any) => {
                        return {
                          id: req.id,
                          label: req.jpcCode + "-" + req.title,
                        };
                      }),
                      {
                        id: 0,
                        label: "--Select--",
                      },
                    ]}
                    getOptionDisabled={(option: any) =>
                      option?.label === "Please select state first"
                    }
                    onChange={(e:any,value:any)=>{
                      console.log("value,",value.label)
                      setEditCandidateData({
                        ...editCandidateData,
                        requirementId: value.label
                      })
                    }}


                    value={editCandidateData?.Requirement == null ? "" : `${editCandidateData?.Requirement?.jpcCode} - ${editCandidateData?.Requirement?.title}`}
                    sx={{ width: 300 }}
                    renderInput={(params: any) => (
                      <TextField
                        className="auto-text"
                        {...params}
                        label="Requirement"
                      />
                    )}
                  /> */}
                </Box>
              </div>
            </div>
          </div>
          <div className="reference-submit-btn">
            <Button loading={submitLoader} onClick={editReferenceSubmit}>
              {" "}
              Update{" "}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditUserModal;
