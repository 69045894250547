import { Box, TextField } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { userUpdateAPI } from "../../service/profileService";
import { Button, Space, Spin, message } from "antd";





const ProfilePage = ({ profileUserData }: any) => {

  const [profileLoader, setProfileLoader] = useState(false);
  const [hasErrors, setHasErrors]: any = useState([]);

  const [profileData, setProfileData]: any = useState({
    fullName: profileUserData?.fullName,
    jobTitle: profileUserData?.jobTitle,
    linkedInProfile: profileUserData?.linkedInProfile,
    phone: profileUserData?.phone,
    country: profileUserData?.country,
    email: profileUserData?.email,
  });
  const formRef: any = useRef();

  useEffect(() => {
    setProfileData({
      fullName: profileUserData?.fullName,
      jobTitle: profileUserData?.jobTitle,
      linkedInProfile: profileUserData?.linkedInProfile,
      phone: profileUserData?.phone,
      country: profileUserData?.country,
      email: profileUserData?.email,
    });
  }, [profileUserData]);



  const handleUpdateProfile = async (e: any) => {
    formRef.current.reportValidity();
    if (profileData.fullName === '' || profileData.jobTitle === '' || profileData.phone === '' || profileData.linkedInProfile === '') {
      formRef.current.reportValidity();
    } else {
      const { fullName, jobTitle, linkedInProfile, phone } = profileData;
      e.preventDefault();
      setProfileLoader(true)

      await userUpdateAPI({ fullName, jobTitle, linkedInProfile, phone }).then(
        (response: any) => {
          if (response.success) {
            setProfileLoader(false)
            message.success("Profile Updated Successfully...");
          } else {
            setProfileLoader(false)
          }
        }).catch((err: any) => {

          const errors = err?.errors?.map((e: any) => e?.param)
          setHasErrors(errors)
          // message.error("Enter valid linkedIn profile...");
          setProfileLoader(false)

        })
    }

  };


  return (
    <>

      <div>
        <div>
          <Box className='password-section-group'
            component="form"
            noValidate
            autoComplete="off"
            ref={formRef}
          >              <div className="profile-input-group">
              <TextField
                required
                className="single-input-class"
                id="outlined-uncontrolled"
                label="Full Name"
                error={hasErrors?.includes('fullName') ? true : false}
                value={profileData.fullName || ""}
                onChange={(e: any) =>
                  setProfileData({ ...profileData, fullName: e.target.value })
                }
              />
            </div>
            <div className="profile-input-group1">
              <TextField
                className="single-input-class"
                id="outlined-uncontrolled"
                label="Email ID"
                type={"email"}
                value={profileData.email || ""}
                disabled
              />
            </div>
            <div className="profile-input-group2">
              <TextField
                className="single-input-class"
                id="outlined-uncontrolled"
                label="Job Title"
                error={hasErrors?.includes('jobTitle') ? true : false}
                type={"text"}
                required
                value={profileData.jobTitle || ""}
                onChange={(e: any) =>
                  setProfileData({ ...profileData, jobTitle: e.target.value })
                }
              />
            </div>
            <div className="profile-input-group3">
              <TextField
                className="single-input-class"
                id="outlined-uncontrolled"
                label="Mobile No"
                type={"text"}
                required
                disabled
                value={profileData.phone || ""}
                onChange={(e: any) =>
                  setProfileData({ ...profileData, phone: e.target.value.replace(/\D/g, '') })
                }
              />
            </div>
            <div className="profile-input-group4">
              <TextField
                className="single-input-class"
                id="outlined-uncontrolled"
                label="Country"
                value={profileData.country || ""}
                type={"text"}
                disabled
              />
            </div>
            <div className="profile-input-group5">
              <TextField
                className="single-input-class"
                id="outlined-uncontrolled"
                label="Linkedin ID"
                type={"url"}
                required
                value={profileData.linkedInProfile || ""}
                error={hasErrors?.includes('linkedInProfile') ? true : false}
                helperText={!hasErrors?.includes('linkedInProfile') ? true : 'Enter valid linkedIn profile'}
                onChange={(e: any) =>
                  setProfileData({ ...profileData, linkedInProfile: e.target.value })
                }
              />
              <Button loading={profileLoader} className="update-profile-btn" onClick={handleUpdateProfile}>
                Update Profile
              </Button>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
