import React, { useState } from "react";
import HotRequirements from "../component/userDashboard/HotRequirements";
import ReferredUsers from "../component/userDashboard/ReferredUsers";
import "../style/UserDashboard.css";
import "../style/mobileDash.css";
import MobileUserDashboard from "../component/userDashboard/MobileUserDashboard";
import ReferForJob from "../component/userDashboard/ReferForJob";

const UserDashboard = () => {

  const [requirementOverviewValue, setRequirementOverviewValue] = useState(false);
  const [requirementOverviewAllData, setRequirementOverviewAllData] = useState([]);
  const [requirementLoading, setRequirementLoading] = useState(false);
  const [referJOb, setReferJOb] = useState(false);
  const [jobIdAndTitleData, setJobIdAndTitleData] = useState([]);


  return (
    <>
      <div className="d-none  d-md-block">
        <div className="userDashboardFirstDiv">
          <HotRequirements
          setJobIdAndTitleData={setJobIdAndTitleData}
            setRequirementLoading={setRequirementLoading}
            setRequirementOverviewValue={setRequirementOverviewValue}
            setRequirementOverviewAllData={setRequirementOverviewAllData}
          />
          <ReferredUsers
            setReferJOb={setReferJOb}
            referJOb={referJOb}
            requirementLoading={requirementLoading}
            setRequirementOverviewValue={setRequirementOverviewValue}
            requirementOverviewValue={requirementOverviewValue}
            requirementOverviewAllData={requirementOverviewAllData}
          />

          <ReferForJob setReferJOb={setReferJOb} referJOb={referJOb} setRequirementOverviewValue={setRequirementOverviewValue} jobIdAndTitleData={jobIdAndTitleData} />


        </div>
      </div>
      <div className="mobile-dash d-md-none">
        <MobileUserDashboard />
      </div>
    </>
  );
};

export default UserDashboard;
