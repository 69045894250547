import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import  adminReferralsSlice  from '../redux/adminReferralSlice';
import adminRequirmentsSlice from '../redux/adminRequirmentsSlice';
import candidateSlice from '../redux/candidateSlice';
import loginReducer from "../redux/login"
import profileSlice from '../redux/profileSlice';
import userReducer from "../redux/userSlice"
import  userDashSlice  from '../redux/userDash';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    candidate:candidateSlice,
    adminRequirments:adminRequirmentsSlice,
    user:userReducer,
    candidateData:userDashSlice,
    profile: profileSlice,
    adminReferrals:adminReferralsSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
