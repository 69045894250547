import React, { useEffect, useRef, useState } from "react";
import "../../style/mobileSign.css";
import group831 from "../../assets/Group 831.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  CircularProgress,
  // FormControl,
  FormHelperText,
  // InputLabel,
} from "@mui/material";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
import GiUsaFlag from "../../assets/usa-flag.png";
import { Link, useLocation } from "react-router-dom";
import { userRegister } from "../../service/loginService";
import { useDispatch } from "react-redux";
import { userLoginAction } from "../../redux/login";
import { userLogin } from "../../service/loginService";
import Http from "../../service/http";
import { AiFillCheckCircle } from "react-icons/ai";
import { message } from "antd";
import MobileForgot from "./MobileForgot";

const MobileSign = ({ setSignBtnStatus }: any) => {
  const { search }: any = useLocation();
  const [loginLoader, setLoginLoader] = useState(false);
  const [registerLoader, setRegisterLoader] = useState(false);
  const [formValidationError, setFormValidationError]: any = useState([]);
  const [isOtpVerifyBtn, setIsOtpVerifyBtn]: any = useState(false);
  const [loaderIcon, setLoaderIcon]: any = useState(false);
  const [boxOtp, setBoxOtp]: any = useState("");
  const [isOtpField, setIsOtpField]: any = useState(false);
  const [loaderForVerify, setLoaderForVerify]: any = useState(false);
  const [otpSentSuccessFully, setOtpSentSuccessFully]: any = useState(false);
  const [numberFieldDisable, setNumberFieldDisable]: any = useState(false);
  const [loaderForFinalVerify, setLoaderForFinalVerify]: any = useState(false);
  const [enterOtpBtn, setEnterOtpBtn]: any = useState(false);
  const [loaderForResendBtn, setLoaderForResendBtn]: any = useState(false);
  const [finalSuccess, setFinalSuccess]: any = useState(false);
  const [stateOfUsa, setStateOfUsa]: any = useState([]);
  const [cityOfStates, setCityOfStates]: any = useState([]);
  const refferalCode = new URLSearchParams(search).get("refferalCode");
  const [signUpData, setSignUpData] = useState({
    fullName: "",
    email: "",
    phone: "",
    password: "",
    jobTitle: "",
    country: "USA",
    state: "",
    city: "",
    linkedInProfile: "",
    inviteCode: refferalCode || "",
    otp: "",
  });
  const location: any = useLocation();
  const [isSignIn, setIsSignIn] = useState(false);

  const clearFields = () => {
    setSignUpData({
      fullName: "",
      email: "",
      phone: "",
      password: "",
      jobTitle: "",
      country: "USA",
      state: "",
      city: "",
      linkedInProfile: "",
      inviteCode: refferalCode || "",
      otp: "",
    });
  };

  useEffect(() => {
    setIsSignIn(location.state || false);
  }, [location.state]);

  useEffect(() => {
    getUsaState();
  }, []);

  const getUsaState = async () => {
    const result: any = await Http.get("common/usStates", false);
    if (result.success) {
      setStateOfUsa(result?.data);
    }
  };

  const formRef: any = useRef(null);

  const handleUserRegister = (e: any) => {
    if (isSignIn) {
      e?.preventDefault();
      formRef?.current?.reportValidity();
      setRegisterLoader(true);
      const data = {
        ...signUpData,
        phone: signUpData.phone.replace(/\D/g, ""),
      };
      if (formRef?.current?.reportValidity() && isPasswordValid()) {
        userRegister(data)
          .then((res: any) => {
            if (res.success) {
              clearFields();
              setRegisterLoader(false);
              setIsSignIn(!isSignIn);
              setSignBtnStatus(false);
            } else {
              setRegisterLoader(false);
            }
          })
          .catch((err: any) => {
            const errors = err?.data?.errors?.map((error: any) => error?.param);
            setFormValidationError(errors);
            setRegisterLoader(false);
          });
      }
    } else {
      setIsSignIn(true);
      setRegisterLoader(false);
    }
  };

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const handleLogin = async (e: any) => {
    formRef?.current?.reportValidity();
    if (!isSignIn && formRef?.current?.reportValidity()) {
      e.preventDefault();
      setLoginLoader(true);
      userLogin(loginData)
        .then((res: any) => {
          if (res.success) {
            setLoginLoader(false);
            dispatch(userLoginAction(res.data));
          } else {
            setLoginLoader(false);
          }
        })
        .catch((err: any) => {
          setLoginLoader(false);
        });
    } else {
      setLoginLoader(false);
      setIsSignIn(false);
    }
  };

  const usaState: any = stateOfUsa?.map((e: any) => {
    return { label: e?.stateName, id: e?.stateId };
  });

  const usaCity: any = cityOfStates?.map((e: any) => {
    return { label: e };
  });

  const manageState = async (data: any) => {
    const result: any = await Http.get(`common/usCities/${data?.id}`, false);
    if (result?.success) {
      if (result?.data?.length !== 0) {
        setCityOfStates(result?.data);
        setSignUpData({
          ...signUpData,
          state: data?.label,
          city: "",
        });
      } else {
        setCityOfStates([data?.label]);
        setSignUpData({
          ...signUpData,
          state: data?.label,
          city: data?.label,
        });

      }
    }
  };

  const isPasswordValid = () => {
    if (signUpData?.password.length < 8) {
      return false;
    } else {
      return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/.test(signUpData?.password);
    }
  };

  const verifyOtp = async () => {
    setLoaderForVerify(true);
    const body: any = {
      mobileNumber: signUpData.phone.replace(/\D/g, ""),
    };
    const result: any = await Http.post("user/sendMobileOtp", body, false);
    if (result.success) {
      setIsOtpField(true);
      // setLoaderForVerify(false);
      setIsOtpVerifyBtn(false);
      setOtpSentSuccessFully(true);
      setNumberFieldDisable(true);
    } else {
      setLoaderForVerify(false);
    }
  };

  const functionForOtp = async (e: any) => {
    setBoxOtp(e.target.value);
    console.log(boxOtp);

    if (boxOtp.length == 5) {
      setEnterOtpBtn(true);
    } else {
      setEnterOtpBtn(false);
    }
  };

  const validationForOtp = async (e: any) => {
    e.preventDefault();
    if (boxOtp?.length != 6) return message.error("OTP must have 6 digits")
    setLoaderForFinalVerify(true);
    const body: any = {
      mobileNumber: signUpData.phone.replace(/\D/g, ""),
      otp: boxOtp,
    };
    const result: any = await Http.post("user/verifyMobileOtp", body, false);
    // console.log(result);
    if (result.success) {
      setIsOtpField(false);
      setFinalSuccess(true);
      setSignUpData({
        ...signUpData,
        otp: boxOtp,
      });
    } else {
      // setIsOtpField(false);
      setLoaderForFinalVerify(false);
    }
  };
  const [timer, setTimer]: any = useState(30);

  const resendFun = async () => {
    setLoaderForResendBtn(true);
    const body: any = {
      mobileNumber: signUpData.phone.replace(/\D/g, ""),
    };
    const result: any = await Http.post("user/sendMobileOtp", body, false);
    if (result.success) {
      setTimer(30);
      message.success("OTP send successfully");
      setLoaderForResendBtn(false);
    } else {
      message.error("Error");
    }
  };

  const ResendOption = () => {
    useEffect(() => {
      if (timer > 0) {
        const clearTime = setTimeout(() => {
          setTimer(timer - 1);
        }, 1000);

        return () => {
          clearTimeout(clearTime);
        };
      }
    }, [timer]);

    return (
      <>
        {timer == 0 ? (
          <p
            style={{
              fontSize: "13px",
              cursor: "pointer",
              float: "right",
              margin: "0px",
              color: "#265f9d",
            }}
            onClick={() => resendFun()}
          >
            Resend
          </p>
        ) : (
          <p
            style={{
              fontSize: "13px",
              cursor: "pointer",
              float: "right",
              margin: "0px",
              color: "#265f9d",
            }}
          >
            You can Resend Otp after {timer} seconds
          </p>
        )}
      </>
    );
  };

  return (
    <div className="mobile-sign-main-container d-block d-sm-block d-md-block d-lg-none">
      <header>
        <img src={group831} alt="" className="Group 831" />
      </header>
      <div className="be-wise-earn-with animate__animated animate__fadeInDown">
        <p>
          Be Wise, Earn with{" "}
          <span style={{ fontWeight: "500" }}>Wise Skulls</span>
        </p>
      </div>
      {isSignIn ? (
        <div className="form-holder">
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1.5, width: "87%" } }}
            noValidate={false}
            autoComplete="off"
            ref={formRef}
          >
            <TextField
              required
              error={formValidationError?.includes("fullName") ? true : false}
              className="animate__animated animate__fadeInDown"
              value={signUpData.fullName}
              id="outlined-basic"
              label="Full Name"
              variant="outlined"
              onChange={(e: any) =>
                setSignUpData({ ...signUpData, fullName: e.target.value })
              }
            />
            <TextField
              error={formValidationError?.includes("email") ? true : false}
              className="animate__animated animate__fadeInDown"
              value={signUpData.email}
              id="outlined-basic"
              label="Personal email"
              variant="outlined"
              required
              onChange={(e: any) =>
                setSignUpData({ ...signUpData, email: e.target.value })
              }
            />
            <TextField
              error={formValidationError?.includes("password") ? true : false}
              className="animate__animated animate__fadeInDown"
              required
              value={signUpData.password}
              id="outlined-password-input"
              label="Create a New Password"
              type="password"
              autoComplete="new-password"
              onChange={(e: any) =>
                setSignUpData({ ...signUpData, password: e.target.value })
              }
            />

            {signUpData.password !== "" && !isPasswordValid() ? (
              <FormHelperText
                style={{ color: "red", fontSize: "10px" }}
                error
                id="accountId-error"
              >
                {
                  "password must be 8 characters long and should contain both letters and numbers."
                }
              </FormHelperText>
            ) : null}
            <TextField
              required
              fullWidth
              className="animate__animated animate__fadeInDown"
              id="outlined-basic"
              label="Your Job Title"
              variant="outlined"
              value={signUpData.jobTitle}
              error={formValidationError?.includes("jobTitle") ? true : false}
              helperText={
                formValidationError?.includes("jobTitle")
                  ? "Incorrect entry"
                  : ""
              }
              onChange={(e: any) =>
                setSignUpData({ ...signUpData, jobTitle: e.target.value })
              }
            />
            <span className="usaflag-or-usernumber animate__animated animate__fadeInDown">
              {/* <GiUsaFlag className="giusaflag" /> */}
              <img src={GiUsaFlag} alt="" className="giusaflag" />
              <input
                required
                type="text"
                name=""
                id=""
                placeholder="Phone Number"
                className="phone-input"
                value={signUpData.phone}
                onChange={(e: any) => {
                  var cleaned = ("" + e.target.value).replace(/\D/g, "");
                  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                  if (match) {
                    setIsOtpVerifyBtn(true);
                    setSignUpData({
                      ...signUpData,
                      phone: "(" + match[1] + ") " + match[2] + "-" + match[3],
                    });
                  } else {
                    setSignUpData({
                      ...signUpData,
                      phone: e.target.value.replace(/\D/g, ""),
                    });
                  }
                }}
              />
              <button
                className="verify-btn"
                type="button"
                disabled={!isOtpVerifyBtn}
                style={{
                  cursor: isOtpVerifyBtn ? "pointer" : "not-allowed",
                  color: isOtpVerifyBtn ? "#265f9d" : "#a3a3a3",
                }}
                onClick={() => verifyOtp()}
              >
                {finalSuccess ? (
                  <span className="checkCircle-after-verify">
                    <AiFillCheckCircle className="aiFillCheckCircle" />
                    <p className="verified">Verified</p>
                  </span>
                ) : otpSentSuccessFully ? (
                  <span style={{ cursor: "auto", color: "green" }}>Sent</span>
                ) : loaderForVerify ? (
                  <CircularProgress
                    style={{ width: "25px", height: "25px", marginTop: "5px" }}
                  />
                ) : (
                  "Verify"
                )}
              </button>
            </span>
            {isOtpField ? (
              <FormHelperText
                style={{ color: "blue", margin: "0px" }}
                className="animate__animated animate__fadeInDown"
                id="accountId-error"
              >
                {/* <button style={{float:"right", background:"none", color:"#265f9d", width:"0px", height:"0px", fontSize:"12px"}}>Resend</button> */}
                {loaderForResendBtn ? (
                  <CircularProgress
                    style={{
                      width: "15px",
                      height: "15px",
                      marginTop: "5px",
                      float: "right",
                      marginRight: "7px",
                    }}
                  />
                ) : (
                  <div>
                    <ResendOption />
                  </div>
                )}
              </FormHelperText>
            ) : null}
            {isOtpField ? (
              <span className="usaflag-or-usernumber animate__animated animate__fadeInDown">
                <input
                  className="enter-otp"
                  type="number"
                  name=""
                  id=""
                  placeholder="Enter OTP"
                  value={boxOtp}
                  onChange={(e: any) => functionForOtp(e)}
                />
                <button
                  className="validate"
                  onClick={(e: any) => validationForOtp(e)}
                  // disabled={!enterOtpBtn}
                  style={{
                    color:"#265f9d",
                    cursor:"pointer"
                    // color: enterOtpBtn ? "#265f9d" : "#a3a3a3",
                    // cursor: enterOtpBtn ? "pointer" : "not-allowed",
                  }}
                >
                  {loaderForFinalVerify ? (
                    <CircularProgress
                      style={{
                        width: "25px",
                        height: "25px",
                        marginTop: "5px",
                      }}
                    />
                  ) : (
                    "Validate"
                  )}
                </button>
              </span>
            ) : null}
            <TextField
              required
              className="animate__animated animate__fadeInDown"
              defaultValue={"USA"}
              id="outlined-basic"
              variant="outlined"
              disabled
            />
            <Autocomplete
              className="animate__animated animate__fadeInDown"
              disablePortal
              id="combo-box-demo"
              options={usaState}
              sx={{ width: 300 }}
              value={signUpData.state}
              onChange={(e: any, data: any) => manageState(data)}
              renderInput={(params) => (
                <TextField
                  required
                  className="state-search-field"
                  {...params}
                  label="State"
                />
              )}
            />
            <Autocomplete
              className="animate__animated animate__fadeInDown"
              disablePortal
              id="combo-box-demo"
              disabled={
                signUpData.city == signUpData.state &&
                signUpData.city.length > 0
                  ? true
                  : false
              }
              value={signUpData.city}
              options={
                usaCity?.length > 0
                  ? usaCity
                  : [{ label: "Please select state first." }]
              }
              getOptionDisabled={(option: any) =>
                option?.label === "Please select state first."
              }
              sx={{ width: 300 }}
              onChange={(e: any, data: any) =>
                setSignUpData({
                  ...signUpData,
                  city: data?.label,
                })
              }
              renderInput={(params: any) => (
                <TextField
                  required
                  className="state-search-field"
                  {...params}
                  label="City"
                />
              )}
            />

            <TextField
              required
              error={
                formValidationError?.includes("linkedInProfile") ? true : false
              }
              className="animate__animated animate__fadeInDown"
              value={signUpData.linkedInProfile}
              id="outlined-basic"
              label="Linkedin Profile"
              variant="outlined"
              onChange={(e: any) =>
                setSignUpData({
                  ...signUpData,
                  linkedInProfile: e.target.value,
                })
              }
            />
            {/* <TextField
              className="animate__animated animate__fadeInDown"
              value={signUpData.inviteCode}
              id="outlined-basic"
              label="Referral Code"
              variant="outlined"
              onChange={(e: any) =>
                setSignUpData({
                  ...signUpData,
                  inviteCode: e.target.value,
                })
              }
            /> */}
            <span
              className="animate__animated animate__fadeInDown"
              style={{ display: "flex", gap: "10px" }}
            >
              <input
                type="checkbox"
                required
                name=""
                id=""
                style={{ width: "20px", height: "25px", outline: "none" }}
              />
              <Link to={"/tc"} style={{ color: "#265f9d", outline: "none" }}>
                Terms and Conditions*
              </Link>
            </span>
          </Box>
        </div>
      ) : (
        <div className="form-holder ">
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1.5, width: "87%" } }}
            noValidate
            autoComplete="off"
            className="animate__animated animate__fadeInDown"
            ref={formRef}
          >
            <TextField
              required
              className="animate__animated animate__fadeInDown"
              id="outlined-basic"
              label="Personal email"
              variant="outlined"
              value={loginData.email || ""}
              onChange={(e: any) =>
                setLoginData({ ...loginData, email: e.target.value })
              }
            />
            <TextField
              required
              className="animate__animated animate__fadeInDown"
              id="outlined-basic"
              type={"password"}
              label="Password"
              variant="outlined"
              value={loginData.password || ""}
              onChange={(e: any) =>
                setLoginData({ ...loginData, password: e.target.value })
              }
            />
          </Box>
        </div>
      )}
      <div className="form-holder mobile-preview">
        {isSignIn ? (
          <>
            <button
              className="animate__animated animate__fadeInDown"
              type="submit"
              onClick={(e: any) => handleUserRegister(e)}
            >
              {registerLoader ? (
                <CircularProgress
                  style={{
                    color: "white",
                    height: "18px",
                    width: "18px",
                    position: "relative",
                    left: "0px",
                    top: "2px",
                  }}
                />
              ) : null}
              Create Account
            </button>
            <br />
            <Link
              to={""}
              className="already-account animate__animated animate__fadeInDown"
              onClick={(e: any) => handleLogin(e)}
            >
              Already have an Account?
            </Link>
            <button
              className="animate__animated animate__fadeInDown"
              style={{ background: "#0e9280" }}
              onClick={(e: any) => handleLogin(e)}
            >
              {loginLoader ? (
                <CircularProgress
                  style={{
                    color: "white",
                    height: "18px",
                    width: "18px",
                    position: "relative",
                    left: "0px",
                    top: "2px",
                  }}
                />
              ) : null}
              Login
            </button>
          </>
        ) : (
          <>
            <button
              className="animate__animated animate__fadeInUp"
              style={{ background: "#0e9280" }}
              onClick={(e: any) => handleLogin(e)}
            >
              {loginLoader ? (
                <CircularProgress
                  style={{
                    color: "white",
                    height: "18px",
                    width: "18px",
                    position: "relative",
                    left: "0px",
                    top: "2px",
                  }}
                />
              ) : null}
              Login
            </button>
            <Link
              to={"/forgot-password"}
              className="forgot-your-password animate__animated animate__fadeInUp"
            >
              Forgot your password?
            </Link>
            <br />
            <Link
              to={""}
              className="do-not-have-an-account animate__animated animate__fadeInUp"
              onClick={(e: any) => handleUserRegister(e)}
            >
              Do not have an account? Sign Up below
            </Link>

            <button
              className="animate__animated animate__fadeInUp"
              type="submit"
              onClick={(e: any) => handleUserRegister(e)}
            >
              {registerLoader ? (
                <CircularProgress
                  style={{
                    color: "white",
                    height: "18px",
                    width: "18px",
                    position: "relative",
                    left: "0px",
                    top: "2px",
                  }}
                />
              ) : null}
              Create Account
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default MobileSign;
