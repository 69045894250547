import { Pagination } from '@mui/material'


const UserPagination = ({data,paginationData, page, perPage, setPage, setPerPage, setIsAdminUserSpinner}:any) => {


  const handlePagination = (e: any, page: number) => {
    e.preventDefault();
    setPage(page)
    setIsAdminUserSpinner(true);
  }

  const showing = data?.length

  return (
    <div className="requirments-pagination-container">
      <div className="requirments-pagination-text">
      <p>Showing {showing} of {paginationData?.totalRecords} entries</p>
   </div>
      <Pagination
        className="requirments-pagination"
        count={paginationData?.totalPage}
        shape="rounded"
        showFirstButton
        showLastButton
        color="primary"
        page={Number(page)}
        onChange={handlePagination}
      />
    </div>
  )
}

export default UserPagination