import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "antd";
import "../../style/addReference.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { newReferenceApi } from "../../service/userDashboardService";
import { useDispatch } from "react-redux";
import { userRefresh } from "../../redux/userSlice";
import { MdOutlineCancel } from "react-icons/md";
import { Autocomplete } from "@mui/material";
import Http from "../../service/http";


const AddReference = () => {
  const [refModalOpen, setRefModalOpen] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [isCityListLoading, setIsCityListLoading] = useState(false);
  const [stateOfUsa, setStateOfUsa]: any = useState([]);
  const [cityOfStates, setCityOfStates]: any = useState([]);
  const [selectedstateId, setSelectedstateId]: any = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [addReferenceErrors, setAddReferenceErrors]: any = useState([]);
  const [cityValue, setCityValue] = useState("");
  const [showRefrenceval, setShowRefrenceval]: any = useState({
    fullName: "",
    requirementId: 0,
    title: "",
    email: "",
    phone: "",
    linkedInProfile: "",
    state: "",
    city: "",
  });

  const formRef: any = useRef(null);
  const dispatch = useDispatch();

  const addReferenceSubmit = async () => {
    formRef.current.reportValidity();
    const data = {
      ...showRefrenceval,
      phone: showRefrenceval.phone.replace(/\D/g, ""),
    };
    if (formRef.current.reportValidity()) {
      setSubmitLoader(true);
      await newReferenceApi(data)
        .then((ele: any) => {
          if (ele?.success === true) {
            setRefModalOpen(false);
            setSubmitLoader(false);
            setStateOfUsa([]);
            setCityOfStates([]);
            setAddReferenceErrors([]);
            dispatch(userRefresh());
          } else {
            setSubmitLoader(false);
            setAddReferenceErrors([]);
          }
        })
        .catch(({ data }) => {
          const errors = data?.errors?.map(({ param }: any) => param);
          setAddReferenceErrors(errors);
          setSubmitLoader(false);
        });
    }
  };
  useEffect(() => {
    if (refModalOpen) {
      setIsListLoading(true);
      Http.get("common/usStates", false).then(({ data = [] }: any) => {
        setStateOfUsa(data);
        setIsListLoading(false);
      });
    }
  }, [refModalOpen]);

  useEffect(() => {
    if (!selectedstateId) return;
    setIsCityListLoading(true);
    Http.get(`common/usCities/${selectedstateId}`, false).then(
      ({ data = [] }: any) => {
        setIsCityListLoading(false);
        setCityOfStates(data);
      }
    );
  }, [selectedstateId]);

  return (
    <>
      <div className="add-reference-click">
        <button onClick={() => setRefModalOpen(true)}> Add Reference </button>
      </div>
      <Modal
        className="reference-edit-details"
        width={780}
        open={refModalOpen}
        // onOk={() => setRefModalOpen(false)}
        // onCancel={() => setRefModalOpen(false)}
        footer={null}
        afterClose={() => {
          setCityOfStates([]);
          setShowRefrenceval({
            fullName: "",
            requirementId: 0,
            title: "",
            email: "",
            phone: "",
            linkedInProfile: "",
            state: "",
            city: "",
          });
        }}
      >
        <div
          className="new-reference-all-section"
          style={{ height: "max-content", paddingBottom: 25 }}
        >
          <div className="new-reference-header">
            <p> Add New Reference</p>
            <p className="new-reference-header-cancel">
              <MdOutlineCancel onClick={() => setRefModalOpen(false)} />
            </p>
          </div>
          <div className="all-input-name-section" style={{ padding: "20px" }}>
            <div className="name-and-title-part">
              <div className="name-section">
                <Box
                  className="textfield-input-name"
                  component="form"
                  sx={{ "& > :not(style)": { m: 0, width: "230px" } }}
                  noValidate
                  autoComplete="off"
                  ref={formRef}
                >
                  <TextField
                    required
                    error={addReferenceErrors?.includes("fullName")}
                    helperText={
                      addReferenceErrors?.includes("fullName") &&
                      "Incorrect entry."
                    }
                    value={showRefrenceval.fullName}
                    id="fullName"
                    type="text"
                    label="Full Name"
                    variant="outlined"
                    onChange={(e: any) =>
                      setShowRefrenceval({
                        ...showRefrenceval,
                        fullName: e.target.value,
                      })
                    }
                  />
                  <TextField
                    required
                    error={addReferenceErrors?.includes("title")}
                    helperText={
                      addReferenceErrors?.includes("title") &&
                      "Incorrect entry."
                    }
                    value={showRefrenceval.title}
                    id="title"
                    type="text"
                    label="Title"
                    variant="outlined"
                    onChange={(e: any) =>
                      setShowRefrenceval({
                        ...showRefrenceval,
                        title: e.target.value,
                      })
                    }
                  />
                  <TextField
                    required
                    error={addReferenceErrors?.includes("email")}
                    helperText={
                      addReferenceErrors?.includes("email") &&
                      "Incorrect entry."
                    }
                    value={showRefrenceval.email}
                    id="email"
                    type="email"
                    label="Personal email"
                    variant="outlined"
                    onChange={(e: any) =>
                      setShowRefrenceval({
                        ...showRefrenceval,
                        email: e.target.value,
                      })
                    }
                  />
                  <TextField
                    required
                    error={addReferenceErrors?.includes("phone")}
                    helperText={
                      addReferenceErrors?.includes("phone") &&
                      "Incorrect entry."
                    }
                    value={showRefrenceval.phone}
                    id="phone"
                    type="text"
                    label="Phone Number"
                    variant="outlined"
                    onChange={(e: any) => {
                      var cleaned = ("" + e.target.value).replace(/\D/g, "");
                      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                      if (match) {
                        setShowRefrenceval({
                          ...showRefrenceval,
                          phone:
                            "(" + match[1] + ") " + match[2] + "-" + match[3],
                        });
                      } else {
                        setShowRefrenceval({
                          ...showRefrenceval,
                          phone: e.target.value.replace(/^[\D]{1,10}$/g, ""),
                        });
                      }
                    }}
                  />
                  <TextField
                    error={addReferenceErrors?.includes("linkedInProfile")}
                    helperText={
                      addReferenceErrors?.includes("linkedInProfile") &&
                      "Incorrect entry."
                    }
                    value={showRefrenceval.linkedInProfile}
                    id="linkedInProfile"
                    type="text"
                    label="Linkedin ID"
                    variant="outlined"
                    onChange={(e: any) =>
                      setShowRefrenceval({
                        ...showRefrenceval,
                        linkedInProfile: e.target.value,
                      })
                    }
                  />
                  <Autocomplete
                    id="asynchronous-demo"
                    className="form-autocomplete"
                    // disablePortal
                    loading={true}
                    loadingText={"Loading please wait....."}
                    value={showRefrenceval.state}
                    onChange={(e: any, value: any) => {
                      if (value?.id !== undefined) {
                        setCityOfStates([]);
                        Http.get(`common/usCities/${value?.id}`, false).then(
                          ({ data = [] }: any) => {
                            if (data.length > 0) {
                              setCityOfStates(data);
                              setShowRefrenceval({
                                ...showRefrenceval,
                                state: value?.label,
                                city: "",
                              });
                              setCityValue("");
                            } else {
                              setCityOfStates([]);
                              setCityValue(value?.label);
                              setShowRefrenceval({
                                ...showRefrenceval,
                                city: value?.label,
                                state: value?.label,
                              });
                            }
                          }
                        );
                      }
                    }}
                    options={stateOfUsa?.map((e: any) => {
                      if (showRefrenceval?.state !== e?.stateName) {
                        return { label: e?.stateName, id: e?.stateId };
                      } else {
                        return { label: e?.stateName, id: e?.stateId };
                      }
                    })}
                    sx={{ width: 300 }}
                    renderInput={(params: any) => {
                      return (
                        <TextField
                          className="auto-text"
                          {...params}
                          label="State"
                        />
                      );
                    }}
                  />
                  <Autocomplete
                    className="form-autocomplete"
                    // loading={isCityListLoading}
                    // loadingText={"Loading please wait....."}
                    disablePortal
                    disabled={showRefrenceval.city == showRefrenceval.state && showRefrenceval?.city?.length > 0 ? true : false}
                   value={showRefrenceval?.city}
                    id="combo-box-demo"
                    options={
                      showRefrenceval?.state?.length > 0
                        ? cityOfStates?.map((e: any) => {
                            return { label: e };
                          })
                        : [{ label: "Please select state first" }]
                    }
                    getOptionDisabled={(option) =>
                      option?.label === "Please select state first"
                    }
                    onChange={(e: any, value: any) => {
                      setCityValue(value?.label);
                      setShowRefrenceval({
                        ...showRefrenceval,
                        city: value?.label,
                      });
                    }}
                    // value={cityValue ? cityValue : ""}
                    sx={{ width: 300 }}
                    renderInput={(params: any) => (
                      <TextField
                        className="auto-text"
                        {...params}
                        label="City"
                      />
                    )}
                  />
                </Box>
              </div>
            </div>
          </div>
          <div className="reference-submit-btn">
            <Button loading={submitLoader} onClick={addReferenceSubmit}>
              Submit{" "}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AddReference;
