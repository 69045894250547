import { createSlice } from '@reduxjs/toolkit';



export interface ProfileState {
    userData: any,
}

const initialState: ProfileState = {
    userData: {},
};

export const ProfileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {

        setFileDetails: (state, action) => {
            state.userData = action.payload
        }
    }

});

export const { setFileDetails } = ProfileSlice.actions;

export default ProfileSlice.reducer;
