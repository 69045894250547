import { createSlice } from "@reduxjs/toolkit";

export interface CandidateState {
  candidatesList: any;
  candidatesPagination: any;
  candidatesSearchData: any;
  candidatesRefresh: boolean;
  page: number;
  perPage: number;
}

const initialState: CandidateState = {
  candidatesList: [],
  candidatesPagination: {},
  candidatesSearchData: [],
  candidatesRefresh: false,
  page: 1,
  perPage: 10,
};

export const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    setAdminCandidatesList: (state, action) => {
      state.candidatesList = action.payload.data.data
      state.candidatesPagination = action.payload.data.pagination
    },
    setAdminCandidatesSearchData: (state, action) => {
      state.candidatesList = action.payload.data.data
      state.candidatesPagination = action.payload.data.pagination
},
    setAdminCandidatesRefresh: (state) => {
      state.candidatesRefresh = !state.candidatesRefresh
     
      

    },
    setPerPaginationrefresh:(state:any,action)=> {
      state.page=action.payload.page
      state.perPage=action.payload.perPage

    },
    setAdminCandidatesPagination: (state, action) => {
       state.page=action.payload
    },
  },
});

export const {
  setAdminCandidatesList,
  setAdminCandidatesSearchData,
  setAdminCandidatesRefresh,
  setAdminCandidatesPagination,
  setPerPaginationrefresh,
} = candidateSlice.actions;

export default candidateSlice.reducer;
