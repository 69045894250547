import {  createSlice } from '@reduxjs/toolkit';


export interface UserState {
    userTable:any,
    userRefresh:any
    
}

const initialState: UserState = {
    userTable:{},
    userRefresh:false
};


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserTableData: (state:any, action:any) => {
      
        state.userTable = action.payload      
    },
    userRefresh: (state:any) => {
      state.userRefresh = !state.userRefresh
    }
  },
});

export const {getUserTableData, userRefresh } = userSlice.actions;


export default userSlice.reducer;