import { useEffect, useState } from "react";
import {
  hotRequirementsApi,
  RequirementOverviewApi,
} from "../../service/userDashboardService";
import "../../style/HotRequirements.css";
import { useDispatch } from "react-redux";
import { setHotRequirements } from "../../redux/userDash";
import { Space, Spin } from "antd";
import moment from "moment";
import { BiErrorAlt } from "react-icons/bi";
import { isMobile } from "react-device-detect";

const HotRequirements = ({
  setJobIdAndTitleData,
  setRequirementOverviewValue,
  setRequirementOverviewAllData,
  setRequirementLoading,
}: any) => {
  const [hotRequirementSingleData, setHotRequirementSingleData] = useState([]);
  const [hotRequirementsData, setHotRequirementsData] = useState([]);
  const [hotRequirementLoader, setHotRequirementLoader] = useState(false);
  const [referOnReqSearchAny, setReferOnReqSearchAny] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isMobile) {
      const getData: any = setTimeout(() => {
        setHotRequirementLoader(true);
        hotRequirementsApi({ searchAny: referOnReqSearchAny }).then(
          (res: any) => {
            setHotRequirementsData(res?.data);
            dispatch(setHotRequirements(res?.data));
            setHotRequirementLoader(false);
          }
        );
      }, 800);
      return () => clearTimeout(getData);
    }
  }, [referOnReqSearchAny]);

  const RequirementOverviewFun = (ele: any) => {
    setHotRequirementSingleData(ele);
    setRequirementLoading(true);
    setRequirementOverviewValue(true);
    RequirementOverviewApi(ele?.id).then((res: any) => {
      setRequirementOverviewAllData(res?.data);
      if (res.success) {
        setRequirementLoading(false);
      }
    });
  };

  return (
    <div className="hotRequirementMainDiv">
      <header className="hotRequirementHeader">
        Refer On Requirements
        <div className="hotReqSearchAnyMainDiv">
          <input
            className="hotReqInput"
            placeholder="Search By Requirements Details"
            type="text"
            onChange={(e: any) => setReferOnReqSearchAny(e.target.value)}
          />
        </div>
      </header>

      {!hotRequirementLoader ? (
        hotRequirementsData?.length > 0 ? (
          <>
            {hotRequirementsData?.map((ele: any) => {
              return (
                <div
                  className={
                    hotRequirementSingleData === ele
                      ? "hotRequirementcontaineDiv1"
                      : "hotRequirementcontaineDiv"
                  }
                  onClick={() => {
                    RequirementOverviewFun(ele);
                    setJobIdAndTitleData(ele);
                  }}
                >
                  <div className="containeFirstDiv">
                    <h6>
                      {ele?.jpcCode} - {ele?.title}
                    </h6>
                  </div>
                  <div className="containeSecoundDiv">
                    <p>
                      {ele?.projectType} - {ele?.jobType}
                    </p>
                  </div>
                  <div className="containethirdDiv">
                    <p>Duration - {ele?.duration}</p>
                  </div>
                  <div className="containeSecoundDiv">
                    <p>
                      Requirement created date -{" "}
                      <span style={{ color: "#0E4D92" }}>
                        {moment(ele?.createdAt).format("LL")}
                      </span>
                    </p>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <div className="norequirementsMainDiv">
              <div className="noRequirementIcon">
                <BiErrorAlt />
              </div>
              <div className="noRequirementText">No Requirements Available</div>
            </div>
          </>
        )
      ) : (
        <Space size="middle" className="hotRequirementLoader">
          <Spin size="large" />
        </Space>
      )}
    </div>
  );
};

export default HotRequirements;
