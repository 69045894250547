import Http from "./http"


export const userUpdateAPI = async (user:any)=>{
    return await Http.put(`user/update`,user,false)                  //* User Update API*//
}

export const changeEmailAPI = async (user:any)=>{
    return await Http.put(`user/changeEmail`,user,false)             //* Change Email API*//
}

export const changePasswordAPI = async (user:any)=>{
    return await Http.put(`user/changePassword`,user,false)          //* Change Password API *//
}

export const changeAvatarAPI = async (user:any)=>{
    return await Http.put(`user/avatar-update`,user,true)           //* Change Avatar API*//
}

export const emailChangeOtpAPI = async (data:any)=>{
    return await Http.post(`user/emailChangeMobileOtp`,data,true)         //* Email Change Otp API*//
}

export const bankingDetailsAPI = async (data:any)=>{
    return await Http.post(`user/addBankingDetails`,data,false)      //* Banking Details API*//
}

export const getUserAPI = async ()=>{
    return await Http.get(`user/getUser`,false)                      //* Get User API*//
}

export const getBankingDetailsAPI = async ()=>{
    return await Http.get(`user/getBankingDetails`,false)            //* Get Banking Details API*//
}
