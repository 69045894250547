import { Box, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import Http from "../../service/http";
import SignIn from "./SignIn";
import { message } from "antd";
import { Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const ResetPassword = ({ otp, email }: any) => {
  const [loaderIcon, setLoaderIcon]: any = useState(false);
  const [newPassword, setNewPassword]: any = useState("");
  const [confirmPassword, setConfirmPassword]: any = useState("");
  const [loginModel, setLoginModel]: any = useState(false);

  const setFinalPass = async () => {
    // eslint-disable-next-line eqeqeq
    if (newPassword === confirmPassword && confirmPassword != "") {
      setLoaderIcon(true);
      const body: any = {
        emailOrPhone: email,
        newPassword: newPassword,
        otp: otp,
      };
      const result: any = await Http.put("user/forgotPassword", body, false);

      if (result.success) {
        setTimeout(() => { setLoginModel(true); }, 2000)
        setInterval(() => { setLoaderIcon(false); }, 1000)
      }
      else {
        setInterval(() => {
          // setLoaderIcon(false);
        }, 1000)
      }
    }
    else {
      // eslint-disable-next-line eqeqeq
      if (newPassword != confirmPassword) {
        message.error("Password and confirm password not same")
      } else {
        message.error("Please enter valid password")
      }
    }
  };
  return (
    <>
      {/* Reset Password start*/}

      {loginModel && isMobile ? <Navigate to={'/sign'} /> : loginModel && !isMobile ? <SignIn /> :
        <Box
          component="form"
          sx={{ "& > :not(style)": { m: 1.5, width: "87%" } }}
          noValidate
          autoComplete="off"
        >
          <TextField
            className="animate__animated animate__fadeInDown"
            id="outlined-basic"
            type={"password"}
            label="New Password"
            variant="outlined"
            value={newPassword}
            onChange={(e: any) => setNewPassword(e.target.value)}
          />
          <TextField
            className="animate__animated animate__fadeInDown"
            id="outlined-basic"
            type={"password"}
            label="Confirm Password"
            variant="outlined"
            value={confirmPassword}
            onChange={(e: any) => setConfirmPassword(e.target.value)}
          />
          <button
            type="button"
            className="animate__animated animate__fadeInDown"
            style={{ background: loaderIcon ? "#265f9da6" : "#265f9d" }}
            onClick={setFinalPass}>
            {loaderIcon ?
              <CircularProgress
                style={{
                  color: "white",
                  height: "18px",
                  width: "18px",
                  position: "relative",
                  left: "0px",
                  top: "2px",
                }}
              /> : null}
            Reset
          </button>
        </Box>}
      {/* Reset Password end*/}
    </>
  );
};

export default ResetPassword;
