import "../style/tc.css";
import WsLogo from "../assets/WiseskullsBlue.svg";

const TC = () => {
  return (
    <div className="container-fluid media-terms-container">
      <div className="container-xxl  media-terms-box">
        <div className="row d-flex  px-4">
          <img
            src={WsLogo}
            style={{ height: "86px", width: "280px" }}
            alt="wiseskulls logo"
          />
        </div>
        <div className="row terms-list-box mt-5">
          <h1 style={{fontWeight:"700"}}>Terms and Conditions</h1>
          <ul className="px-5 terms-list">
            <li>
            Eligibility criteria: Only Individual IT Professionals are eligible to participate in the referral program. Vendors, agencies, and recruiters are not permitted to participate.
            </li>
            <li>
              Referral tracking: The company will provide regular updates to the
              referrers about the status of their referrals, even if they have
              not yet been hired.
            </li>
            <li>
              Termination policy: The company reserves the right to terminate or
              suspend the referral program at any time, without any prior
              notice.
            </li>
            <li>
              Confidentiality: The company will maintain the privacy and
              confidentiality of the referrers and their referrals.
            </li>
            <li>
              Who can be referred: In simpler terms, anyone working in the IT
              industry in the United States can be referred to us as either a
              contractor or full-time employee. We welcome all genuine
              referrals, even if we don't have a specific job opening at the
              moment. We keep track of all referrals and consider them for any
              suitable positions in the future. Additionally, you can also check
              our list of current job openings and refer someone for an
              immediate result.
            </li>
            <li>
              Referral rewards: For every successful hire referred by the
              candidate, the referer will receive a reward of $500. Additional
              bonus incentives may be provided based on the number of successful
              references provided. There is no cap on the number of referrals an
              employee can make. All rewards will be paid accordingly. If two or
              more employees refer the same candidate, only the first referrer
              will receive their referral rewards.
            </li>
            <li>
              Referral criteria: The only criteria for a reference is that it
              must be genuine, and the referrer must be available to discuss
              the positions when required.
            </li>
            <li>
              Hiring process: Referred candidates will be submitted to multiple
              requirements with different clients. Our internal team will update
              the status of the reference on the webpage once the candidate is
              hired.
            </li>
            <li>
            Point of Contact: Paurav Patel- Referral Program Manager will be
              available over the phone (+1 669-260-9707) and email
              (pauravn@wiseskulls.com) to solve any issues that may arise.
            </li>
            <li>
              Compliance: The reward amount will be credited to the referrers
              bank account and will be taxable. The referrer should be aware of
              this and consult with their tax advisor regarding tax
              implications. Referrers who violate the company's policies or any
              applicable laws and regulations will be terminated from the
              program, and the company reserves the right to take legal action
              against them.
            </li>
            <li>Payment Terms: Referrer will receive their reward for referring someone after 30 days from the date that person starts working.</li>
            <li>There is no cap on the number of referrals an employee can make. All rewards will be paid accordingly.</li>
          <li>If two or more employees refer the same candidate, only the first referrer will receive their referral rewards.</li>
          <li>Keep in mind that rewards may be subject to taxation. Please contact our referral program manager for more information.</li>
          <li>We may change our referral bonus program over time to add more interesting incentives. We also reserve the right to abolish certain rewards if they prove ineffective or inefficient. We’ll communicate any change clearly and timely. Employees who referred candidates before a reward was abolished will still receive the appropriate reward.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TC;
