import { Box, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import Http from "../../service/http";
import VerificationIcon from "./VerificationIcon";

const SentOtpCom = ({ email }: any) => {
  const [loaderIcon, setLoaderIcon]: any = useState(false);
  const [otp, setOtp]: any = useState("");
  const [verificationState, setVerificationState]: any = useState(false);
  // const formRef: any = useRef(null);

  const changeModel = async (e:any) => {
    e.preventDefault();

    if(otp !==""){
      setLoaderIcon(true);
      const body: any = {
        otp: otp,
      };
      const result: any = await Http.post(
        "user/forgotPasswordConfirmOtp",
        body,
        false
      );
      if (result.success) {
        setVerificationState(true);
        setInterval(() => {
          setLoaderIcon(false);
        }, 1000);
      } 
      else {
        setInterval(() => {
          setLoaderIcon(false);
        }, 1000);
      }
    }

   

   
  };

  return (
    <>
      {verificationState ? (
        <VerificationIcon otp={otp} email={email} />
      ) : (
        <>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1.5, width: "87%" } }}
            noValidate
            autoComplete="off"
          >
            <TextField
              className="animate__animated animate__fadeInDown"
              id="outlined-basic"
              label="Email or Phone Number"
              variant="outlined"
              value={email}
              disabled
            />
          </Box>
          <form onSubmit={(e:any) => changeModel(e)}>   
          <p className="otp-sent-title animate__animated animate__fadeInDown">OTP sent on your Registered mobile number</p>
          <div className="d-flex justify-content-between animate__animated animate__fadeInDown">
                 
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              required
              onChange={(e: any) => setOtp(e.target.value)}
              style={{
                width: "48%",
                borderRadius: "10px",
                outline: "none",
                border: "1px solid #CCCCCC",
                textAlign: "center",
              }}
            />
            <button
              type="submit"
              // onClick={changeModel}
              style={{width: "48%", background: loaderIcon ? "#265f9da6" :  "#265f9d" }}
            >
              {loaderIcon ? (
                <CircularProgress
                  style={{
                    color: "white",
                    height: "18px",
                    width: "18px",
                    position: "relative",
                    left: "0px",
                    top: "2px",
                  }}
                />
              ) : null}
              Confirm
            </button>            
          </div>
          </form>
        </>
      )}
    </>
  );
};

export default SentOtpCom;
