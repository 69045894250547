import React, { useEffect, useRef, useState } from "react";
import "../../style/referOnRequirement.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router";
import { newReferenceApi } from "../../service/userDashboardService";
import { Autocomplete } from "@mui/material";
import Http from "../../service/http";

const ReferOnRequirement = () => {
  const location = useLocation();
  const formRef: any = useRef(null);
  const [stateOfUsa, setStateOfUsa]: any = useState([]);
  const [cityOfStates, setCityOfStates]: any = useState([]);
  const navigate = useNavigate();
  const [refData, setRefData] = useState({
    fullName: "",
    requirementId: location?.state?.id,
    title: "",
    email: "",
    phone: "",
    linkedInProfile: "",
    state: "",
    city: "",
  });

  const clearFields = () => {
    setRefData({
      fullName: "",
      requirementId: "",
      title: "",
      email: "",
      phone: "",
      linkedInProfile: "",
      state: "",
      city: "",
    });
  };

  const HandleSubmit = (e: any) => {
    e.preventDefault();
    formRef.current.reportValidity();

    if (formRef.current.reportValidity()) {
      newReferenceApi(refData).then((res: any) => {
        if (res.success) {
          clearFields();
          navigate("/user-dashboard");
        }
      });
    } else {
    }
  };

  useEffect(() => {
    getUsaState();
  }, []);

  const getUsaState = async () => {
    const result: any = await Http.get("common/usStates", false);
    if (result.success) {
      setStateOfUsa(result?.data);
    }
  };

  const usaState: any = stateOfUsa?.map((e: any) => {
    return { label: e?.stateName, id: e?.stateId };
  });

  const usaCity: any = cityOfStates?.map((e: any) => {
    return { label: e };
  });

  const manageState = async (data: any) => {
    const result: any = await Http.get(`common/usCities/${data?.id}`, false);
    if (result?.success) {
      if (result?.data?.length !== 0) {
        setCityOfStates(result?.data);
        setRefData({
          ...refData,
          state: data?.label,
          city: "",
        });
      } else {
        setCityOfStates([data?.label]);
        setRefData({
          ...refData,
          state: data?.label,
          city: data?.label,
        });
      }
    }
  };

  return (
    <div className="refer-on-requirement-section d-md-none">
      {/* <div className='refer-page-header' >
                Refer On Requirement
            </div> */}
      <div className="refer-many-sec">
        <Box
          className="refer-submit-form"
          component="form"
          sx={{ "& > :not(style)": { m: 1, width: "300px" } }}
          noValidate
          autoComplete="off"
          ref={formRef}
        >
          <TextField
            className="animate__animated animate__fadeInDown"
            value={`${location?.state?.jpcCode}-${location?.state?.title}`}
            id="outlined-basic"
            variant="outlined"
            style={{ fontWeight: "700", color: "black" }}
            disabled
          />
          <TextField
            className="animate__animated animate__fadeInDown"
            value={refData.fullName}
            required
            id="outlined-basic"
            label="Full Name"
            variant="outlined"
            onChange={(e: any) =>
              setRefData({ ...refData, fullName: e.target.value })
            }
          />
          <TextField
            required
            className="animate__animated animate__fadeInDown"
            value={refData.title}
            id="outlined-basic"
            label="Title"
            variant="outlined"
            onChange={(e: any) =>
              setRefData({ ...refData, title: e.target.value })
            }
          />
          <TextField
            required
            className="animate__animated animate__fadeInDown"
            value={refData.email}
            id="outlined-basic"
            label="Personal Email"
            variant="outlined"
            onChange={(e: any) =>
              setRefData({ ...refData, email: e.target.value })
            }
          />
          <TextField
            className="animate__animated animate__fadeInDown"
            required
            value={refData.phone}
            id="outlined-basic"
            label="Phone Number"
            variant="outlined"
            onChange={(e: any) =>
              setRefData({ ...refData, phone: e.target.value })
            }
          />
          <TextField
            className="animate__animated animate__fadeInDown"
            required
            value={refData.linkedInProfile}
            id="outlined-basic"
            label="Linkedin ID"
            variant="outlined"
            onChange={(e: any) =>
              setRefData({ ...refData, linkedInProfile: e.target.value })
            }
          />
          <Autocomplete
            className="animate__animated animate__fadeInDown"
            disablePortal
            id="combo-box-demo"
            options={usaState}
            sx={{ width: 300 }}
            value={refData.state}
            onChange={(e: any, data: any) => manageState(data)}
            renderInput={(params) => (
              <TextField
                required
                style={{ width: "100%", margin: "0px" }}
                className="state-search-field"
                {...params}
                label="State"
              />
            )}
          />
          <Autocomplete
            className="animate__animated animate__fadeInDown"
            style={{ marginTop: "16px" }}
            disablePortal
            disabled={
              refData.city == refData.state &&
              refData.city.length > 0
                ? true
                : false
            }
            id="combo-box-demo"
            value={refData.city}
            options={
              usaCity?.length > 0
                ? usaCity
                : [{ label: "Please select state first." }]
            }
            getOptionDisabled={(option: any) =>
              option?.label === "Please select state first."
            }
            sx={{ width: 300 }}
            onChange={(e: any, data: any) =>
              setRefData({
                ...refData,
                city: data?.label,
              })
            }
            renderInput={(params: any) => (
              <TextField
                required
                style={{ width: "100%", margin: "0px", fontWeight:"500" }}
                className="state-search-field"
                {...params}
                label="City"
              />
            )}
          />
        </Box>
        <div className="refer-requirement-click-btn">
          <button
            className="animate__animated animate__fadeInDown"
            onClick={(e: any) => HandleSubmit(e)}
          >
            {" "}
            Submit{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReferOnRequirement;
