import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReferrerLeftSide from "../component/referrerComp/ReferrerLeftSide";
import ReferrerRightSide from "../component/referrerComp/ReferrerRightSide";
import { setAdminReferralsList } from "../redux/adminReferralSlice";
import { getReferralList } from "../service/referralService";
import "../style/referrerStyle/Referrer.css";
import { setPerPaginationrefresh } from "../redux/candidateSlice";
import { setAdminRequirmentsPagination } from "../redux/adminRequirmentsSlice";

const Referrer = () => {
  const [isRefererLoading, setIsRefererLoading] = useState(false);
  const [referrerDataLoading, setReferrerDataLoading] = useState(false);

  const [searchReferralData, setSearchReferralData] = useState({
    searchDetails: "",
    page: 1,
    perPage: 1000,
  });

  const dispatch = useDispatch();

  useEffect(() => {
  dispatch(setPerPaginationrefresh({page:1 ,perPage:10}))
  dispatch(setAdminRequirmentsPagination(1))
  if (searchReferralData?.searchDetails === "") {
      setReferrerDataLoading(true)
      getReferralList(searchReferralData).then((res: any) => {
        dispatch(setAdminReferralsList(res));
        setReferrerDataLoading(false)
      }).catch(() => {
        setReferrerDataLoading(false)
      })
    } else {
      setReferrerDataLoading(true)
      const getReferralSearchData = setTimeout(() => {
        getReferralList(searchReferralData).then((res: any) => {
          dispatch(setAdminReferralsList(res));
          setReferrerDataLoading(false)
        }).catch(() => {
          setReferrerDataLoading(false)
        })
      }, 1000);

      return () => clearTimeout(getReferralSearchData);
    }
  }, [getReferralList, searchReferralData?.searchDetails]);


  return (
    <div className="referrer-main-section">
      <div className="referrer-left-side-sec">
        <ReferrerLeftSide
          setSearchReferralData={setSearchReferralData}
          searchReferralData={searchReferralData}
          setIsRefererLoading={setIsRefererLoading}
          referrerDataLoading={referrerDataLoading}
        />
      </div>
      <div className="referrer-right-side-sec">
        <ReferrerRightSide isRefererLoading={isRefererLoading} />
      </div>
    </div>
  );
};

export default Referrer;
