import { useState, useEffect } from "react";
import "../../style/referrerStyle/ReferrerRightSide.css";
import {
  getReferralNoteApi,
  getReferrsActiveApi,
} from "../../service/referralService";
import { AiOutlineDown } from "react-icons/ai";
import { Switch, Space, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setAdminReferralsRefresh } from "../../redux/adminReferralSlice";
import { Spin } from "antd";
import { Link } from "react-router-dom";
import { BsLinkedin } from "react-icons/bs";
import DummyImage from "../../image/360_F.jpg";

const SingleProfileView = ({ reffererDetailsData }: any) => {
  const [activeBtn, setActiveBtn]: any = useState(
    !reffererDetailsData.isDeleted
  );
  //   console.log( "reffererDetailsData",  reffererDetailsData);

  const [isFirstLoadDone, setIsFirstLoadDone]: any = useState(false);
  const [notesBox, setNotesBox] = useState(false);
  const [referrerNote, setReferrerNote] = useState(
    reffererDetailsData?.note || ""
  );

  const dispatch = useDispatch();

  const handleDetailActive = async (item: any) => {
    // const  web:any = activeBtn ? 1 : 0;

    await getReferrsActiveApi({
      referrerId: item.id,
      isDeleted: activeBtn,
    }).then((response: any) => {
      if (response.success) {
        dispatch(setAdminReferralsRefresh());
      }
    });
  };

  useEffect(() => {
    setIsFirstLoadDone(true);
  }, []);

  useEffect(() => {
    if (!isFirstLoadDone) return;
    const delayDebounceFn = setTimeout(() => {
      if (referrerNote) {
        AddNoteValue(referrerNote);
      }
    }, 800);

    return () => clearTimeout(delayDebounceFn);
  }, [referrerNote]);

  const AddNoteValue = async (referrerNote: any) => {
    await getReferralNoteApi({
      referrerId: reffererDetailsData.id,
      note: referrerNote,
    }).then((response: any) => {});
  };

  return (
    <>
      {reffererDetailsData?.id && (
        <div className="referrer-right-main-section">
          <div
            className="referrerTablaDataFoam"
            style={{ color: activeBtn === true ? "#000000" : "#8E8E8E" }}
          >
            <div className="d-flex  justify-content-between">
              <div className="referrer-profile-image">
                <img
                  style={{ opacity: activeBtn === true ? "1" : ".5" }}
                  src={
                    !reffererDetailsData?.avatar
                      ? DummyImage
                      : reffererDetailsData?.avatar
                  }
                  alt=""
                />
              </div>
              <div className="pt-3">
                <Space direction="vertical" className="active-inactive-sec">
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    defaultChecked
                    checked={activeBtn}
                    onChange={() => {
                      handleDetailActive(reffererDetailsData);
                      setActiveBtn(!activeBtn);
                    }}
                  />
                </Space>
              </div>
            </div>

            <div className="referrer-client-name-section">
              <div className="client-job-id">
                <p> {reffererDetailsData?.id} </p>
                <span> {reffererDetailsData?.fullName} </span>
              </div>
              <div className="person-referrer-placements">
                <div className="number-referrer-sec">
                  <p> {reffererDetailsData.referrenceCount} </p>
                  <span>- Referrences </span>
                </div>
                <div className="placement-sec">
                  <p> {reffererDetailsData.hiringCount} </p>
                  <span>- Placements </span>
                </div>
              </div>
            </div>

            <div className="referrerInputSec">
              <div className="fullNameAndEmailIdSec">
                <p>Full Name</p>
                <span>{reffererDetailsData?.fullName}</span>
              </div>
              <div className="fullNameAndEmailIdSec">
                <p>Email ID</p>
                <span>{reffererDetailsData?.email}</span>
              </div>

              <div className="fullNameAndEmailIdSec">
                <p>Title</p>
                <span>{reffererDetailsData?.jobTitle}</span>
              </div>

              <div className="fullNameAndEmailIdSec">
                <p>Invited By</p>
                <span>
                  {reffererDetailsData?.invitedBy
                    ? `${reffererDetailsData?.invitedByName} (${reffererDetailsData?.invitedBy})`
                    : "--"}
                </span>
              </div>

              <div className="fullNameAndEmailIdSec">
                <p>Mobile No.</p>
                <span>
                  {reffererDetailsData?.phone
                    ? reffererDetailsData?.phone
                    : "--"}
                </span>
              </div>
              <div className="fullNameAndEmailIdSec">
                <p>Country</p>
                <span>
                  {reffererDetailsData?.country
                    ? reffererDetailsData?.country
                    : "--"}
                </span>
              </div>
              <div className="fullNameAndEmailIdSec">
                <p>State</p>
                <span>
                  {reffererDetailsData?.state
                    ? reffererDetailsData?.state
                    : "--"}
                </span>
              </div>
              <div className="fullNameAndEmailIdSec">
                <p>City</p>
                <span>
                  {reffererDetailsData?.city ? reffererDetailsData?.city : "--"}
                </span>
              </div>
            </div>

            <div className="linkedInSec">
              <p>Linked In ID</p>

              <Tooltip
                placement="top"
                title={reffererDetailsData?.linkedInProfile}
                color={"blue"}
                key={"white"}
              >
                <Link to={reffererDetailsData?.linkedInProfile} target="_blank">
                  <BsLinkedin
                    style={{
                      color: activeBtn === true ? "#0A66C2" : "#8E8E8E",
                    }}
                  />
                </Link>
              </Tooltip>
            </div>

            <div className="onlyOneBankSec">
              <p
                style={{
                  marginTop: "20px",
                  fontSize: "17px",
                  fontWeight: "600",
                }}
              >
                {" "}
                Bank Details
              </p>
            </div>

            <div className="referrerInputSec">
              <div className="fullNameAndEmailIdSec">
                <p>Account Name</p>
                <span>
                  {reffererDetailsData?.BankingDetail?.accountName
                    ? reffererDetailsData?.BankingDetail?.accountName
                    : "--"}{" "}
                </span>
              </div>
              <div className="fullNameAndEmailIdSec">
                <p>Account Number</p>
                <span>
                  {reffererDetailsData?.BankingDetail?.accountNumber
                    ? reffererDetailsData?.BankingDetail?.accountNumber
                    : "--"}
                </span>
              </div>
              <div className="fullNameAndEmailIdSec">
                <p>Bank Full Address</p>
                <span>
                  {reffererDetailsData?.BankingDetail?.bankFullAddress
                    ? reffererDetailsData?.BankingDetail?.bankFullAddress
                    : "--"}
                </span>
              </div>
              <div className="fullNameAndEmailIdSec">
                <p>Bank Name</p>
                <span>
                  {reffererDetailsData?.BankingDetail?.bankName
                    ? reffererDetailsData?.BankingDetail?.bankName
                    : "--"}
                </span>
              </div>
              <div className="fullNameAndEmailIdSec">
                <p>Full Address</p>
                <span>
                  {reffererDetailsData?.BankingDetail?.fullAddress
                    ? reffererDetailsData?.BankingDetail?.fullAddress
                    : "--"}
                </span>
              </div>
              <div className="fullNameAndEmailIdSec">
                <p>Routing Number</p>
                <span>
                  {reffererDetailsData?.BankingDetail?.routingNumber
                    ? reffererDetailsData?.BankingDetail?.routingNumber
                    : "--"}
                </span>
              </div>
            </div>
          </div>
            <div className="noteBoxInput animate__animated animate__slideInLeft">
              {/* <div
                className="hideIcon"
                onClick={() => {
                  setHide(!hide);
                  setNotesBox(hide ? !notesBox : notesBox);
                }}
              >
                <button
                  style={{
                    backgroundColor: activeBtn === true ? "#265f9d" : "#8E8E8E",
                  }}
                >
                  <AiOutlineDown
                    style={{
                      fontSize: "13px",
                      marginBottom: "8px",
                    }}
                  />
                </button>
              </div> */}
              <div
              className="referrerNotBtn "
              onClick={() => {
                setNotesBox(!notesBox);
              }}
            >
              <button
                className={""}
                style={{
                  backgroundColor: activeBtn == true ? "#265f9d" : "#8E8E8E",
                }}
              >
                Notes
              </button>
            </div>
              <div className="notes">
                <textarea
                  name=""
                  className={`${notesBox ? "note-text-on" : "note-text-area"}`}
                  placeholder={"Write anything about this candidate"}
                  value={referrerNote}
                  onChange={(e) => setReferrerNote(e.target.value)}
                ></textarea>
              </div>
            </div>
        </div>
      )}
    </>
  );
};

const ReferrerRightSide = ({ isRefererLoading }: any) => {
  const reffererDetailsData = useSelector(
    (state: any) => state?.adminReferrals?.referrerDetails
  );

  return (
    <>
      {isRefererLoading ? (
        <div className="referrer-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <SingleProfileView reffererDetailsData={reffererDetailsData} />
      )}
    </>
  );
};

export default ReferrerRightSide;
