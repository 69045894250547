import { useState, useRef, useEffect } from "react";
import { Button, Modal } from "antd";
import "../../style/AddRequirementBtn.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { adminAddRequirementApi } from "../../service/requirmentsService";
import { useDispatch } from "react-redux";
import { setAdminRequirmentsRefresh } from "../../redux/adminRequirmentsSlice";
import { FormControl, Autocomplete } from "@mui/material";
import Http from "../../service/http";
import { MdOutlineCancel } from "react-icons/md";

const AddRequirementBtn = () => {
  const [requiredModalOpen, setRequiredModalOpen] = useState(false);
  const [cityOfStates, setCityOfStates]: any = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [stateOfUsa, setStateOfUsa]: any = useState([]);


  const [addRequirementData, setAddRequirementData]: any = useState({
    jpcCode: "",
    duration: "",
    title: "",
    city: "",
    state: "",
    jobType: "",
    projectType: "",
    requiredSkills: "",
    detailedJd: "",
  });

  const formRef: any = useRef(null);

  const getUsaState = async () => {
    await Http.get(`common/usStates`, false).then((res: any) => {
      setStateOfUsa(res?.data);
    });
  };

  const showRequirementModal = () => {
    setRequiredModalOpen(true);
  };

  const dispatch = useDispatch();

  const handleAddRequirmentSubmit = async (e: any) => {
    setSubmitLoader(true);
    e.preventDefault();
    formRef.current.reportValidity();
    if (formRef.current.reportValidity()) {
      await adminAddRequirementApi(addRequirementData)
        .then((res: any) => {
          if (res.success) {
            dispatch(setAdminRequirmentsRefresh());
            setRequiredModalOpen(false);
            setSubmitLoader(false);
            resetAddRequirment();
          } else {
            setSubmitLoader(false);
          }
        })
        .catch((res: any) => {
          setSubmitLoader(false);
        });
    } else {
      setSubmitLoader(false);
    }
  };

  const resetAddRequirment = () => {
    setAddRequirementData({
      jpcCode: "",
      duration: "",
      title: "",
      city: "",
      state: "",
      jobType: "",
      projectType: "",
      requiredSkills: "",
      detailedJd: "",
    });
  };
  useEffect(() => {
    if (requiredModalOpen) {
      getUsaState();
    }

    resetAddRequirment();
    setCityOfStates([]);
  }, [requiredModalOpen]);

  return (
    <>
      <div className="add-required-click">
        <button onClick={showRequirementModal}> Add Requirement </button>
      </div>

      <Modal
        className="adding-required-modal"
        width={970}
        open={requiredModalOpen}
        // onOk={() => setRequiredModalOpen(false)}
        // onCancel={() => setRequiredModalOpen(false)}
        footer={null}
        closable={false}

      >
        <div
          className="add-requirement-all-section"
          style={{ height: "max-content" }}
        >
          <div className="all-requirement-header">
            <p> Add Requirement </p>
            <p className="new-reference-header-cancel">
              <MdOutlineCancel onClick={() => setRequiredModalOpen(false)} />
              </p>
          </div>
          <div className="required-code-duration">
            <Box
              className="required-text-field-section"
              style={{ margin: "30px 50px" }}
              component="form"
              noValidate
              autoComplete="off"
              ref={formRef}
            >
              <div className="first-required-section">
                <TextField
                  id="outlined-basic"
                  label="JPC Code"
                  variant="outlined"
                  required
                  value={addRequirementData?.jpcCode}
                  onChange={(e: any) =>
                    setAddRequirementData({
                      ...addRequirementData,
                      jpcCode: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  required
                  value={addRequirementData.title}
                  onChange={(e: any) =>
                    setAddRequirementData({
                      ...addRequirementData,
                      title: e.target.value,
                    })
                  }
                />
              </div>

              <div className="second-required-title-section">
                <FormControl fullWidth className="">
                  <Autocomplete
                  loading={true}
                  loadingText={"Loading..."}
                    onChange={async (e: any, value: any | null) => {
                      if (value !== null) {
                        await Http.get(
                          `common/usCities/${value?.stateId}`,
                          false
                        ).then(({ data = [] }: any) => {

                          if (data.length > 0) {
                           setCityOfStates(data);
                          setAddRequirementData({
                            ...addRequirementData,
                            state: value?.label,
                            city: "",
                          })
                         }else {
                            setCityOfStates(["No options"]);
                            setAddRequirementData({
                              ...addRequirementData,
                              state: value?.label,
                              city: value?.label,
                            })}
                        });
                       
                      } else {
                        setAddRequirementData({
                          ...addRequirementData,
                          state: "",
                          city: "",
                        });
                        setCityOfStates([]);
                      }
                    }}
                    value={addRequirementData?.state}
                    id="demo-simple-select"
                    options={stateOfUsa?.map((e: any) => {
                      return {
                        label: e?.stateName,
                        stateId: e?.stateId,
                      };
                    })}
                    renderInput={(params) => (
                      <TextField {...params} label="State" required />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth className="">
                  <Autocomplete
                    loading={true}
                    loadingText={"Loading..."}
                    sx={{ width: 500 }}
                    id="demo-simple-select"
                    disabled={addRequirementData.city == addRequirementData.state && addRequirementData?.city?.length > 0 ? true : false}

                    options={
                      cityOfStates?.length > 0
                        ? cityOfStates?.map((e: any) => {
                            return { label: e };
                          })
                        : [{ label: "Please select state first" }]
                    }
                    getOptionDisabled={(option) =>
                      option?.label === "Please select state first" ||  option?.label === "No options"
                    }
                    value={
                      addRequirementData?.city
                    }
                    onChange={(e: any, data: any) => {
                      if (data?.label !== "Please select state first") {
                        setAddRequirementData({
                          ...addRequirementData,
                          city: data?.label,
                        });
                      }
                    }}
                    renderInput={(params: any) => (
                      <>
                        <TextField {...params} label="City" required />
                      </>
                    )}
                  />
                </FormControl>
              </div>

              <div
                className="first-required-section"
                style={{ marginTop: "20px" }}
              >
                <TextField
                  id="outlined-basic"
                  label="Duration"
                  variant="outlined"
                  required
                  value={addRequirementData.duration}
                  onChange={(e: any) =>
                    setAddRequirementData({
                      ...addRequirementData,
                      duration: e.target.value,
                    })
                  }
                />

                <div className="requirement-job-type">
                  <div className="first-section">
                    <label htmlFor="">Job Type*</label>
                  </div>
                  <div className="remote-to-onsite">
                    <span className="first-rdo">
                      <input
                        required
                        type="radio"
                        id="remote"
                        name="JobType"
                        value="0"
                        checked={addRequirementData?.jobType?.includes("0")}
                        onChange={(e: any) =>
                          setAddRequirementData({
                            ...addRequirementData,
                            jobType: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="remote" style={{ fontSize: "18px",cursor:"pointer" }}>
                        Remote
                      </label>
                    </span>
                    <span className="first-rdo">
                      <input
                        required
                        type="radio"
                        id="Hybrid"
                        name="JobType"
                        value="1"
                        checked={addRequirementData?.jobType?.includes("1")}
                        onChange={(e: any) =>
                          setAddRequirementData({
                            ...addRequirementData,
                            jobType: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="Hybrid" style={{ fontSize: "18px",cursor:"pointer" }}>
                        Hybrid
                      </label>
                    </span>
                    <span className="first-rdo">
                      <input
                        required
                        type="radio"
                        id="Onsite"
                        name="JobType"
                        value="2"
                        checked={addRequirementData?.jobType?.includes("2")}
                        onChange={(e: any) =>
                          setAddRequirementData({
                            ...addRequirementData,
                            jobType: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="Onsite" style={{ fontSize: "18px",cursor:"pointer" }}>
                        Onsite
                      </label>
                    </span>
                  </div>
                </div>

                <div className="project-type">
                  <div className="second-section">
                    <label htmlFor=""> Project Type*</label>
                  </div>
                  <div className="contract-full-time">
                    <span className="last-rdo">
                      <input
                        required
                        type="radio"
                        id="contract"
                        name="projecType"
                        value="0"
                        checked={addRequirementData?.projectType?.includes("0")}
                        onChange={(e: any) =>
                          setAddRequirementData({
                            ...addRequirementData,
                            projectType: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="contract" style={{ fontSize: "18px" ,cursor:"pointer"}}>
                        Contract
                      </label>
                    </span>
                    <span className="last-rdo">
                      <input
                        required
                        type="radio"
                        id="FullTime"
                        name="projecType"
                        value="1"
                        checked={addRequirementData?.projectType?.includes("1")}
                        onChange={(e: any) =>
                          setAddRequirementData({
                            ...addRequirementData,
                            projectType: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="FullTime" style={{ fontSize: "18px",cursor:"pointer" }}>
                        Full Time
                      </label>
                    </span>
                  </div>
                </div>
              </div>

              <div className="third-four-required-section">
                <textarea
                  required
                  name=""
                  id=""
                  placeholder="Required Skills*"
                  value={addRequirementData.requiredSkills}
                  onChange={(e: any) =>
                    setAddRequirementData({
                      ...addRequirementData,
                      requiredSkills: e.target.value,
                    })
                  }
                ></textarea>
              </div>

              <div className="third-four-detailed-section">
                <textarea
                  name=""
                  id=""
                  placeholder="Detailed JD*"
                  required
                  value={addRequirementData.detailedJd}
                  onChange={(e: any) =>
                    setAddRequirementData({
                      ...addRequirementData,
                      detailedJd: e.target.value,
                    })
                  }
                ></textarea>
              </div>

              <div className="required-submit-btn">
                <Button
                id="submit-btn"
                  loading={submitLoader}
                  onClick={handleAddRequirmentSubmit}
                >
                  {" "}
                  Submit{" "}
                </Button>
              </div>
            </Box>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddRequirementBtn;
